import React, { createContext, useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import { Face, Search } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export const ContactLookupContext = createContext({
  state: null,
  setState: () => {},
});

export const ContactLookupProvider = ({ children }) => {
  let [state, setState] = useState();

  return (
    <ContactLookupContext.Provider value={{ state, setState }}>
      {children}
    </ContactLookupContext.Provider>
  );
};

const ContactLookupButton = ({ channel, identiefier, name, accountId }) => {
  const { setState } = React.useContext(ContactLookupContext);
  let router = useHistory();
  let onClick = () => {
    if (accountId == null) setState({ channel, identiefier, name });
    router.push(`accounts/${accountId || ""}`);
  };

  return (
    <IconButton
      aria-label="show more"
      aria-haspopup="true"
      color="inherit"
      right
      onClick={onClick}
    >
      {accountId ? <Face /> : <Search />}
    </IconButton>
  );
};
export const LookupInfoButton = () => {
  const { state, setState } = React.useContext(ContactLookupContext);
  if (!state) return null;

  return (
    <Button
      aria-label="show more"
      aria-haspopup="true"
      color="inherit"
      variant="outlined"
      onClick={() => setState(null)}
    >
      {state.name}
    </Button>
  );
};
export default ContactLookupButton;
