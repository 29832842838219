import React, { useState, useEffect, useRef } from "react";
import { List } from "react-admin";
import GoogleMapReact from "google-map-react";
import icon from "@material-ui/icons/Gesture";
import { guardListAccess } from "../../util/Guard";


let markerUrl = "https://maps.google.com/mapfiles/ms/icons/";

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 10,
};
let markers = [];
let otherRoutes = [];
let gMap = null;
let currMax = 0;
let currMin = 0;


const loadData = ({ map, rows, ...props }) => {
  let google = window.google;
  gMap = map;
  currMax = 0;
  while (markers.length) markers.pop().setMap(null);

  for (var idx = 0; idx < rows.length; idx++) {
    let currStop = rows[idx];

    currMax++;
    let lat = parseFloat(currStop.lat);
    let lng = parseFloat(currStop.lng);
    let marker = new google.maps.Marker({
      position: { lat, lng },
      map: map,
      icon: {
        url:
          markerUrl + "blue-dot.png"
      },
    });

    markers.push(marker);

  }
  return markers
};

let SimpleMap = ({ loading, data, ids, filterValues, ...props }) => {

  let ref = useRef();
  let rows = [];

  for (var i = 0; i < ids.length; i++) {
    let row = data[ids[i]];
    rows.push(row);
  }
  if (loading) return "";

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <div ref={ref} style={{
        width: '100%',
        height: '50px',
        padding: '0px 55px',
        boxSizing: 'border-box',
      }}>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        data={data}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        // heatmapLibrary={true}
        yesIWantToUseGoogleMapApiInternals

        onGoogleApiLoaded={({ map, maps }) => {
          loadData({ ...props, map, maps, rows });

        }}
      // heatmap={heatMapData}
      ></GoogleMapReact>

    </div>
  );
};

export const RouteEditor = (props) => {
  return (
    <>
      <List
        pagination={<></>}
        {...props}

      >
        <SimpleMap />
      </List>
    </>
  );
};
const resource = ({ canRead, is }) => {
  return {
    name: "queryMaps",
    list: guardListAccess(RouteEditor),
    icon,
    options: {
      label: "Query Map",
      group: "Logistic",
      showMenu: is("admin"),
    },
  };
};
export default resource;
