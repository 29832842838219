import React, { useState, useRef, useEffect } from "react";
import returnables from "./Returnables.json"
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  Card,
  Avatar,
  CardContent,
  DialogActions,
  CardMedia,
  TextField,
} from "@material-ui/core";

import Carousel from "react-material-ui-carousel"
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useGetOne, useInput } from "react-admin"
import { blue, pink } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      marginTop: 5,
      width: '100%'
    },
    card: {
      margin: 10
    },
    cardMedia: {
      height: 200,
      backgroundSize: 'contain'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

    divider: {
      flex: 'auto'
    },
    avatar: {
      backgroundColor: pink[500],
      margin: '5px auto'
    },
    avatarWrap: {
      position: 'relative',
      display: 'inline-block',
      width: 25,
      left: -15,
      top: -2
    },
    noIosZoom: {
      touchAction: 'manipulation'
    }
  }
});


const PalletBtn = ({ onClick, load, value = 1 }) => {
  const classes = useStyles();
  return <Button className={classes.button} variant="outlined" color="secondary" {...{ onClick }}
    startIcon={load ? < ArrowBack /> : null}
    endIcon={load ? null : < ArrowForward />}
  >
    {value}
  </Button >
}
const TypeSelector = ({ onChange, value = 1 }) => {
  let [type, setType] = useState(value);
  let onClick = () => {
    let newType = type == 1 ? 2 : 1;
    setType(newType);
    onChange(newType);
  }
  return <Button onClick={onClick} >{type == 1 ? 'RC' : 'Pallet'}</Button>
}

const TrackLoadItem = ({ record, recordChanged, onDelete }) => {
  let [item, setItem] = useState(record);
  let change = (props) => {
    recordChanged(props);
    setItem(props);
  }

  return <tr>
    <td><TextField type="number" value={item.count}
      onChange={(e) => { change({ ...item, count: Math.max(0, e.target.value) }) }}
      style={{ width: 50 }} /></td>
    <td><TypeSelector onChange={(id) => { change({ ...item, id }) }} value={item.id} /></td>
    <td><TextField value={item.misc} style={{ width: 250 }}
      onChange={(e) => { change({ ...item, misc: e.target.value }) }}
    /></td>
    <td>
      <IconButton
        onClick={onDelete}
      >
        <RemoveIcon />
      </IconButton>

    </td>
  </tr>
}

function SimpleDialog({ onChange, open, onClose, value: storedValue, ...props }) {
  const classes = useStyles();
  let [values, setValues] = useState(storedValue || []);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Vracht
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <Typography>
          <table>
            {values.map((record, idx) => < TrackLoadItem key={record.key} {...{
              record,
              onDelete: () => {
                values.splice(idx, 1);
                setValues([...values]);
                onChange(values)
              },
              recordChanged: (record) => {
                values.splice(idx, 1, record);
                setValues([...values]);
                onChange(values)
              }
            }} />)}
          </table>
        </Typography>
      </DialogContent>

      <DialogActions>
        <IconButton
          onClick={() => {
            let newValues = [...values, { count: 1, id: 1, misc: '', key: values.length }];
            setValues(newValues)
            onChange(newValues)
          }}
        >
          <AddIcon />
        </IconButton>

      </DialogActions>

    </Dialog >
  );
}
const getTotal = (value) => {

  let total = value.reduce((total, e) => {
    if (!e) return total;
    let item = returnables.find(ret => ret.id == e.id);
    return total + e.count * item.space
  }, 0)
  return Math.floor(total);
}

export const TruckLoad = ({ load, onChange, ...props }) => {
  const { input } = useInput(props);
  const [open, setOpen] = useState(false);
  let inputEl = useRef();
  let change = (value) => {
    inputEl.current.value = JSON.stringify(value);
    input.onChange(inputEl.current.value);
    if (onChange) onChange(value);
  }
  const onClose = () => { setOpen(false) }
  let value = input.value ? JSON.parse(input.value) : [];
  value = value.map((item, idx) => { return { ...item, key: idx } })
  return (
    <>
      {open && <SimpleDialog {...{ ...input, value, onChange: change, onClose, open }} />}
      <div><input type="hidden" ref={inputEl} {...input} />
        <PalletBtn value={getTotal(value)} load={load} onClick={() => { setOpen(true) }} /></div>
    </>
  );
};


export default TruckLoad;