import React, { useState } from "react";

import { useForm } from "react-final-form";
import DeleteButton from "../../../components/DeleteButton";
import AddressInput from "../Address/AddressSelector"
import Box from "@material-ui/core/Box";

import {
  Toolbar,
  Button,
  TextInput,
  useGetOne,
  useUpdate,
  SimpleForm,
  SaveButton,
  useNotify,
  useDelete,
  useCreate,
  required
} from "react-admin";


const requiredValidate = [required()];

const PostCreateToolbar = ({ deleteOne, ...props }) => {
  return (
    <Toolbar {...props}>
      <Box display="flex" flexGrow={1}>
        {deleteOne && <DeleteButton onClick={deleteOne} />}
      </Box>
      <SaveButton />
    </Toolbar>
  );
};

const AccountForm = ({ record, handleSave, handleDelete }) => {
  return <SimpleForm
    record={record}
    resource="accounts"
    basePath="/accounts"
    save={handleSave}
    toolbar={<PostCreateToolbar deleteOne={handleDelete} />}
    handleSubmitWithRedirect={handleSave}
  >
    <TextInput fullWidth source="name" validate={requiredValidate} />
    <TextInput fullWidth source="firstName" />
    <Box display="flex" fullWidth justifyContent="space-between" >
      <TextInput style={{ width: '100px' }} source="middleName" label=".." />
      <TextInput style={{ width: '100%' }} source="lastName" />
    </Box>
    {record && record.id ? <AddressInput filter={{ accountId: record.id }} source="defaultAddress" /> : <>
      <TextInput fullWidth source="address" validate={requiredValidate} />
      <Box display="flex" fullWidth justifyContent="space-between" validate={requiredValidate} >
        <TextInput style={{ width: '100px' }} source="zipcode" validate={requiredValidate} />
        <TextInput style={{ width: '100%' }} source="city" validate={requiredValidate} />
      </Box>
    </>}

  </SimpleForm>
}

const UpdateForm = ({ record: { id }, ...props }) => {
  const [update, { loading }] = useUpdate("accounts", id);
  const { data: record } = useGetOne("accounts", id);
  const [deleteOne] = useDelete("accounts", id);

  const handleSave = (data) => {
    if (!loading)
      update(
        { payload: { data } },
        { ...props }
      );
  }

  const handleDelete = () => {
    deleteOne();
    props.onSuccess({});
  };
  return <AccountForm {...{ record, handleSave, handleDelete }} />
}

const CreateForm = ({ defaults, ...props }) => {
  const [create] = useCreate("accounts");
  const handleSave = (data) => {
    create(
      { payload: { data: { ...defaults, ...data } } },
      { ...props }
    );
  }
  return <AccountForm {...{ handleSave }} />
}

const UpdateOrEdit = ({ record, defaults, onSave }) => {
  const notify = useNotify();
  const onSuccess = ({ data }) => {
    onSave(data);
  }
  const onFailure = ({ error }) => notify(error.message, "error");
  return record.id ? <UpdateForm {...{ record, onSuccess, onFailure }} /> :
    <CreateForm {...{ defaults, onSuccess, onFailure }} />
}

export default UpdateOrEdit;
