import * as React from "react";

import Dashboard from "./views/Dashboard/Dashboard";
import { Admin, Resource, ListGuesser } from "react-admin";
import LoginPage from "./util/LoginPage";
import Layout from "./layout/Layout";
import Menu from "./layout/Menu";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import dataProvider from "./util/DataProvider";
import createAuthProvider from "./util/AuthProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import { ShowAuth } from "./views/AuthById";

import "./App.css";

import { lightTheme } from "./layout/themes";
import { valentine } from "./layout/valentines";

const visibleResources =
{
  Sales: [
    "Account/index",
    "Order/Orders",
    "Orderline/Orderline",
    "Subscription/Subscription",
    "Dashboard/Trends",
    "Dashboard/Engagement",
    "Crowdfund",
  ],
  Logistics: [
    "RouteEdit/RouteEditor",
    "QueryMap",
    "Barcode",
    "Area",
    "Delivery/DeliveryList",
    "Stops/Routes",
    "Poslogs",
    "Van"
  ],
  Employees: [
    "User",
    "Role",
    "Task",
    "TimeSheet",
    "TimeSheetOverview",
  ],
  Shop: [
    "TopDown/TopDown",
    "TopDown/LiveTopDown",
    "Product/Product",
    "Category",
    "Box/BoxTotal",
    "Box/BoxContents",
    "Product/Props",
  ],
  Misc: [
    "DropOff",
    "Program",
    "Company",
    "SystemTask",
    "FunProgram",
  ]
};
const invisbleResources = [
  "userTasks",
  "grants",
  "userRoles",
  "producers",
  "states",
  "tickets",
  "weeks",
  "addresses",
  "media",
  "calvins",
  "propSets",
  "returnables",
  "stops",
  "messages",
]


const AuthProvider = createAuthProvider();

const i18nProvider = polyglotI18nProvider((locale) => {
  // Always fallback on english
  return englishMessages;
}, "en");

let theme = new moment().format("MMDD") == "0214" ? valentine : lightTheme
const App = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      authProvider={AuthProvider}
      loginPage={LoginPage}
      layout={Layout}
      menu={Menu}
      className="App"
      customRoutes={customRoutes}
      i18nProvider={i18nProvider}
    >
      {({ canRead, canUpdate, is }) => {
        return [
          Object.keys(visibleResources).reduce((resources, group) => {
            return [...resources, visibleResources[group].map(e => {
              let resdef = require(`./views/${group}/${e}`).default
              let resourceInfo = resdef({ canRead, canUpdate, is });
              resourceInfo.options.group = group;
              return <Resource {...{ ...resourceInfo }} />
            })]
          }, []),
          <Resource
            options={{ hide: true }}
            name="authByIds"
            list={ListGuesser}
            edit={ShowAuth}
          />,
          invisbleResources.map((name) => (
            <Resource
              name={name}
              options={{
                showMenu: false,
              }}
            />
          )),
        ];
      }}
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
