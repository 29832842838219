import React from "react";
import { Filter } from "react-admin";
import { BoxSelector } from "../../../components/BoxSelector";
import { AutoRefInput } from "../../../components/AutoRefInput";

const BoxContentFilter = ({ filterValues, ...props }) => {
  return (<>
    <Filter {...{ ...props, filterValues }}>
      <AutoRefInput
        alwaysOn={true}
        optionText="weekName"
        optionValue="id"
        reference="weeks"
        source="week"
        filter={{ front: 20 }}
      />
      <BoxSelector alwaysOn={true} source="productId" />
    </Filter>

  </>
  );
};

export default BoxContentFilter;
