import * as React from "react";
import OrderIcon from "@material-ui/icons/Euro";
import ReviewIcon from "@material-ui/icons/Forum";
import ThreeSixty from "@material-ui/icons/ThreeSixty";
import StoreIcon from "@material-ui/icons/Store";
import moment from "moment";
import FullNameField from "../Account/FullNameField";
import { NumberField, TextField, DateField, useGetList } from "react-admin";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/core/styles";

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Aside = ({ record, basePath }) => {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
    </div>
  );
};

const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
});
const prettyDate = (date) => {
  let diffMins = new moment().diff(date, "minutes");
  let diffDays = new moment().diff(date, "days");
  let m = new moment(date);
  if (diffMins < 2) return "Just now";
  if (diffMins < 60) return `${diffMins}  minutes ago.`;
  else if (diffDays === 0) return `${m.format("H:mm")}h`;
  else if (diffDays === 1) return `Yesterday ${m.format("H:mm")}`;
  else if (diffDays < 7) return m.format("dddd H:mm");

  return new moment(date).format("ddd DD MMM H:mm");
};
const EventList = ({ record: { Orderlines = [], ...record }, basePath }) => {
  const classes = useEventStyles();

  const { data: orders, ids: orderIds } = useGetList(
    "orders",
    { page: 1, perPage: 100 },
    { field: "orderDate", order: "DESC" },
    { accountId: record && record.id }
  );
  const { data: reviews, ids: reviewIds } = useGetList(
    "messages",
    { page: 1, perPage: 100 },
    { field: "date", order: "DESC" },
    { accountId: record && record.id }
  );
  const events = mixOrdersAndReviews(orders, orderIds, reviews, reviewIds);

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Order date</Typography>
                    <DateField record={record} source="orderDate" />
                  </Box>
                </Box>

                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <OrderIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Total</Typography>
                    <NumberField
                      source="totalAmount"
                      options={{
                        style: "currency",
                        currency: "EUR",
                      }}
                      record={record}
                      basePath={basePath}
                    />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Delivery Date</Typography>
                    <DateField record={record} source="deliveryDate" />
                  </Box>
                </Box>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <ThreeSixty fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Products</Typography>
                    <Typography> {Orderlines.length}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" mb="1em">
              <Box mr="1em">
                <StoreIcon fontSize="small" color="disabled" />
              </Box>
              <Box flexGrow={1}>
                <TextField
                  source="name"
                  record={record.DropOff}
                  basePath={basePath}
                />
              </Box>
            </Box>
            <Box display="flex">
              <Link to={`/accounts/${record.accountId}`} component={RouterLink}>
                <FullNameField record={record.Account} />
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
        {events.map((event, idx) => (
          <Step
            key={`${event.type}-${event.data.id}`}
            expanded
            active={idx === 1}
            completed
          >
            <StepLabel
              StepIconComponent={() => {
                const Component =
                  event.type === "order" ? OrderIcon : ReviewIcon;
                return (
                  <Component
                    fontSize="small"
                    color="disabled"
                    style={{ paddingLeft: 3 }}
                  />
                );
              }}
            >
              {prettyDate(event.date)}
            </StepLabel>
            <StepContent>
              {event.type === "order" ? (
                <Order
                  record={event.data}
                  key={`event_${event.data.id}`}
                  basePath={basePath}
                />
              ) : (
                  <Mesage
                    record={event.data}
                    key={`message_${event.data.id}`}
                    basePath={basePath}
                  />
                )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const mixOrdersAndReviews = (orders, orderIds, reviews, reviewIds) => {
  if (!orderIds.length && !reviewIds.length) return [];
  const eventsFromOrders =
    orderIds && orders
      ? orderIds.map((id) => ({
        type: "order",
        date: orders[id].orderDate,
        data: orders[id],
      }))
      : [];
  const eventsFromReviews =
    reviewIds && reviews
      ? reviewIds.map((id) => ({
        type: "review",
        date: reviews[id].createdAt,
        data: reviews[id],
      }))
      : [];

  const events = eventsFromOrders.concat(eventsFromReviews);

  events.sort(
    (e1, e2) => new Date(e2.date).getTime() - new Date(e1.date).getTime()
  );
  return events;
};

const Order = ({ record, basePath }) => {
  return record ? (
    <>
      <Typography variant="body2" gutterBottom>
        <Link to={`/orders/${record.id}`} component={RouterLink}>
          crate:{" "}
          <TextField source="crates" record={record} basePath={basePath} />
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <NumberField
          source="totalAmount"
          options={{
            style: "currency",
            currency: "EUR",
          }}
          record={record}
          basePath={basePath}
        />
        &nbsp;-&nbsp;
        <TextField source="DropOff.name" record={record} basePath={basePath} />
      </Typography>
    </>
  ) : null;
};

const useReviewStyles = makeStyles({
  clamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
});

const Mesage = ({ record, basePath }) => {
  const classes = useReviewStyles();

  return record ? (
    <>
      <Typography
        variant="body2"
        gutterBottom
        dangerouslySetInnerHTML={{ __html: record.text }}
      ></Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.clamp}
      >
        {record.comment}
      </Typography>
    </>
  ) : null;
};

export default Aside;
