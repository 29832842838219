import React from "react";

import icon from "@material-ui/icons/AssignmentTurnedIn";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  Create,
} from "react-admin";

const create = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
const edit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const list = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};
const Task = ({ is, ...props }) => {
  return {
    name: "tasks",
    list,
    create,
    edit,
    icon,
    options: {
      showMenu: is(["hr-manager", "admin"]),
      group: "Employee",
      label: "Tasks",
    },
    ...props,
  };
};

export default Task;