import React, { useRef } from "react";
import { Button } from "@material-ui/core";

import apiFetch from "../../../util/HttpClient";

let AutoLogin = ({ id }) => {
  const inputForm = useRef(null);
  const inputPwd = useRef(null);
  const inputValidUntil = useRef(null);
  const inputLog = useRef(null);

  const loginWoo = async (accountId) => {
    let wooLink = await apiFetch(`Account/loginWoo?accountId=${id}`).then(
      (e) => e.json
    );
    // const params = new URLSearchParams();
    // Object.keys(wooLink).forEach((e) => params.append(e, wooLink[e]));
    // params.append("logincustomer", 1);
    // fetch("https://destreekboer.tk/wp-login.php", {
    //   method: "POST",
    //   body: params,
    // }).then((res) => console.log);
    // return;

    inputPwd.current.value = wooLink.pwd;
    inputValidUntil.current.value = wooLink.validUntil;
    inputLog.current.value = wooLink.log;
    inputForm.current.submit();
  };

  return (
    <form
      ref={inputForm}
      action="https://destreekboer.nl/wp-login.php"
      method="POST"
      target="_blank"
    >
      <input name="logincustomer" type="hidden" value="1" />
      <input ref={inputPwd} name="pwd" type="hidden" />
      <input ref={inputValidUntil} name="validUntil" type="hidden" />
      <input ref={inputLog} name="log" type="hidden" />

      <Button onClick={loginWoo} size="small" color="secondary">
        Logon..
      </Button>
    </form>
  );
};
export default AutoLogin;
