import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
const styles = ({ spacing }) =>
  createStyles({
    fullView: {
      width: "100%",
      minHeight: "calc(100vh - 80px)",
      backgroundImage: "url(https://picsum.photos/1024/768)",
      backgroundSize: "cover",
    },
  });

const FullView = ({ classes, ...props }) => {
  return <div className={classes.fullView}>{props.children}</div>;
};

export default withStyles(styles)(FullView);

