import React, { useState } from 'react';
import { makeStyles } from "@material-ui/styles"
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { GiBanana, GiSlicedBread, GiTensionSnowflake } from 'react-icons/gi';
import { useInput } from "react-admin";

let useStyles = makeStyles(() => {
  return {
    toggleBtn: {
      '&.MuiToggleButton-root': {
        padding: '3px',
        fontSize: '1.8em'
      },
      '&.Mui-selected': {
        color: '#0080ff'
      }
    }
  }
})

const valToArr = (value) => {
  let zones = [1, 2, 4]
  let result = []
  for (var nr of zones)
    if (nr & value) result.push(nr);
  return result;
}

export default function ToggleButtonsMultiple({ ...props }) {

  const {
    input: { onChange, value = 0 },
  } = useInput(props);
  const [zones, setZones] = useState(valToArr(value));
  let classes = useStyles()
  const handleFormat = (event, value) => {
    setZones(value);
    let newVal = value.reduce((acc, item) => {
      return acc | item;
    }, 0)
    onChange(newVal);
  };

  return (
    <ToggleButtonGroup value={zones} onChange={handleFormat} aria-label="text formatting">
      <ToggleButton
        className={classes.toggleBtn}
        value={1}
        aria-label="bold">
        <GiBanana />
      </ToggleButton>
      <ToggleButton
        className={classes.toggleBtn}
        value={2}
        aria-label="italic">
        <GiSlicedBread />
      </ToggleButton>
      <ToggleButton
        className={classes.toggleBtn}
        value={4}
        aria-label="underlined">
        <GiTensionSnowflake />
      </ToggleButton>

    </ToggleButtonGroup >
  );
}