import React, { useState } from "react";
import PhoneField from "../../../components/PhoneField";
import IdentifierInput from "../../../components/IdentifierInput";
import AutoRefInput from "../../../components/AutoRefInput";
import { AddressInput } from "../Address/AddressSelector";

import { DateTimeInput } from "../../../components/DateTime";
import {
  Edit,
  TextInput,
  Toolbar,
  useTranslate,
  FormWithRedirect,
  BooleanInput,
  required,
  email,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

import Aside from "./Aside";
import FullNameField from "./FullNameField";
import MessageButton from "../../Tickets/MessageButton";

const VisitorEdit = (props) => {

  let [version, setVersion] = useState(0);
  let update = () => {
    setVersion(version + 1);
  };

  return (
    <Edit
      title={<VisitorTitle />}
      aside={<Aside {...{ version, update }} />}
      component="div"
      {...props}
    >
      <VisitorForm {...{ update }} />
    </Edit>
  );
};

const VisitorTitle = ({ record }) =>
  record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = ({ update, ...props }) => {
  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.customers.fieldGroups.identity")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="firstName"
                        resource="accounts"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="lastName"
                        resource="accounts"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="middleName"
                        resource="accounts"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <TextInput
                    type="email"
                    source="email"
                    resource="customers"
                    defaultAddress
                    fullWidth
                  />
                  <AddressInput
                    source="defaultAddress"
                    fullWidth
                    showCreate={false}
                    filter={{ accountId: props.record.id }}
                  />
                  <IdentifierInput source="identifiers" />
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1}>
                      <TextInput
                        source="misc"
                        label="Delivery notes"
                        options={{ multiline: true, rows: 2, columns: 25 }}
                        fullWidth
                      />

                      <TextInput
                        source="miscOffice"
                        label="Office notes"
                        options={{ multiline: true, rows: 2, columns: 25 }}
                        fullWidth
                      />
                      <TextInput
                        source="name"
                        label="Company"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: "1em" }}
                  mt={{ xs: "1em", lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Support
                  </Typography>
                  <div>
                    <AutoRefInput
                      label="Motivation"
                      reference="states"
                      filter={{ type: "Motivation" }}
                      fullWidth
                      source="motivation"
                    />
                    <AutoRefInput
                      label="Awareness by"
                      reference="states"
                      filter={{ type: "startReaso" }}
                      fullWidth
                      source="startReason"
                    />
                    <AutoRefInput
                      label="Stop reason"
                      reference="states"
                      filter={{ type: "stopReason" }}
                      fullWidth
                      source="stopReason"
                    />
                    <DateTimeInput
                      label="Reminder date"
                      fullWidth
                      clearable
                      source="reminder"
                    />
                    <br />
                    <br />
                    <PhoneField source="phone" />
                    <MessageButton
                      label="Log phonecall"
                      accountId={props.record.id}
                      {...{ update }}
                    />
                    {/* <ThreeStateCheckbox label="SMS reminder" source="smsReminder" /> */}
                    <BooleanInput label="SMS reminder" source="smsReminder" />
                    <BooleanInput label="Alow testing" source="alowTesting" />
                    <BooleanInput label="You are next stop-SMS" source="youAreNextStop" />
                    <BooleanInput label="You just got delivered-SMS" source="youGotDelivery" />

                  </div>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="accounts"
            />
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default VisitorEdit;
