import config from "../config";
import { fetchUtils } from "react-admin";
import fetch from "node-fetch";

let httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  if (!url.startsWith("http")) url = `${config.API}/${url}`;
  return fetchUtils.fetchJson(url, options);
};
export default httpClient;

export const fetchJSON = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    httpClient(url, options).then(({ body }) => {
      resolve(JSON.parse(body));
    });
  });
};

export const download = (url, options = {}, name = "someFile.pdf") => {
  if (!options.headers) {
    options.headers = {
      Accept: "application/json",
      "Content-type": "application/pdf",
    };
  }

  const token = localStorage.getItem("token");
  options.headers["Authorization"] = `Bearer ${token}`;
  options.responseType = "blob";
  if (!url.startsWith("http")) url = `${config.API}/${url}`;
  return fetch(url, options).then(blob(name));
};

const blob = (name) => (data) => {
  data.blob().then((blob) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    // var blob = new Blob(data, { type: "octet/stream" });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  });
};
