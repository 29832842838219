import React, { useEffect, useState } from "react";
import { List, Filter } from "react-admin";
import GoogleMapReact from "google-map-react";
import httpClient from "../../util/HttpClient";
import islands from "../islands.json";
import Map from "@material-ui/icons/Map";

import DaySelector, { dayToColor } from "../../components/DaySelector";
import AutoRefInput from "../../components/AutoRefInput";
const RouteFilter = (props) => {
  return (
    <Filter {...props}>
      <AutoRefInput
        alwaysOn={true}
        source="dropOffId"
        filter={{ days: props.filterValues.days || 127, isDelivery: 1 }}
        reference="dropOffs"
      />

      <DaySelector
        source="days"
        alwaysOn={true}
        style={{ paddingBottom: 12 }}
      />
    </Filter>
  );
};

const mapProps = {
  center: {
    lat: 53.18928103499679,
    lng: 6.373702111911842,
  },
  zoom: 10,
};
const setShapeColor = (shape) => {
  shape.setOptions({
    fillColor: shape.enabled ? shape.enabledColor : shape.baseColor,
    fillOpacity: shape.enabled ? 1 : 0.4,
  });
};
const HandleClick = (dropOffId, shape) => {
  let url = `zipPerRoutes`;

  let update = (method, add) => {
    let postUrl = url + (method === "POST" ? "" : `/${shape.id}`);
    httpClient(postUrl, {
      method,
      body: JSON.stringify({
        zip4: shape.zip4,
        dropOffId,
      }),
    }).then(({ json }) => {
      shape.enabled = !shape.enabled;
      shape.id = json.id;

      setShapeColor(shape);
    });
  };

  if (shape.enabled) {
    update("DELETE", false); //green > red
  } else {
    update("POST", true); //blue > green
  }
};

const loadZips = ({ map, data, filterValues, zipcodes, dropOffs }) => {
  let google = window.google;
  // let infoWindow = new google.maps.InfoWindow();
  map.shapeList = map.shapeList || [];
  while (map.shapeList.length) {
    map.shapeList.pop().setMap(null);
  }

  let enabledColor = "#FFFFFF";
  if (filterValues.dropOffId) {
    let route = dropOffs.filter((r) => r.id === filterValues.dropOffId)[0];
    enabledColor = dayToColor(route.days);
  }

  function showArea(event) {
    if (filterValues.dropOffId) {
      HandleClick(filterValues.dropOffId, this);
    }
  }

  for (var i = 0; i < zipcodes.length; i++) {
    var paths = [];
    let row = zipcodes[i];
    if (parseInt(row.a) < 7700) continue;
    if (islands.includes(row.a)) continue;
    for (var l = 0; l < row.p.length; l += 2) {
      paths.push(new google.maps.LatLng(row.p[l], row.p[l + 1]));
    }

    var shape = new google.maps.Polygon({
      path: paths,
      strokeColor: "#FFFFFF",
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    shape.knownRoute = data[row.a];
    shape.baseColor = "#FFFFFF";
    if (shape.knownRoute) {
      shape.baseColor = dayToColor(shape.knownRoute.days);
      shape.enabled = filterValues.dropOffId === shape.knownRoute.dropOffId;
      shape.id = shape.knownRoute.id;
    }
    shape.setMap(map);
    shape.count = 0;
    shape.zip4 = row.a;
    shape.enabledColor = enabledColor;

    setShapeColor(shape);

    google.maps.event.addListener(shape, "click", showArea);
    map.shapeList.push(shape);
  }
};

let SimpleMap = ({ loading, data, filterValues, ids }) => {
  let [mapData, setMapdata] = useState({ loaded: false });

  useEffect(() => {
    if (!mapData.loaded || !data || loading) return;

    let filteredData = ids
      .map((id) => data[id])
      .reduce((ac, e) => {
        ac[e.zip4] = e;
        return ac;
      }, {});

    loadZips({ ...mapData, data: filteredData, filterValues });
    console.log("rendered");
  }, [data, ids, mapData, loading, filterValues]);

  return (
    <div style={{ height: "650px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        data={data}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        // heatmapLibrary={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(props) => {
          httpClient(`zipPaths.json`, {
            compress: false,
          }).then(({ json: zipcodes }) => {
            httpClient(`dropOffs?isDelivery=1&days=127`).then(
              ({ json: dropOffs }) => {
                setMapdata({
                  ...mapData,
                  ...props,
                  zipcodes,
                  dropOffs,
                  loaded: true,
                });
              }
            );
          });
        }}
      ></GoogleMapReact>
    </div>
  );
};

export const AreaEditor = (props) => {
  return (
    <>
      <List {...props} pagination={<></>} filters={<RouteFilter />}>
        <SimpleMap />
      </List>
    </>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "areas",
    list: AreaEditor,
    icon: Map,
    options: {
      label: "Areas",
      group: "Logistic",
      showMenu: canRead("areas"),
    },
  };
};
export default resource;
