import * as React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import GridList from "./Gridlist";


import {
  ListContextProvider,
  Datagrid,
  FunctionField,

  useListController,
  TopToolbar, CreateButton
} from "react-admin";

const ListActions = (props) => (

  <TopToolbar>

    <CreateButton basePath="/media" />

  </TopToolbar>
);

const ListBase = ({ children, context, ...props }) => (
  <ListContextProvider value={context} {...props}>
    {children}
  </ListContextProvider>
);

const MediaEditor = ({ record, source = "id", ...props }) => {
  let parentId = record[source];

  const listContext = useListController({
    data: [],
    ids: [],
    filter: { parentId },
    basePath: '/media',
    resource: 'media',
  });

  return (
    <Card >
      <CardContent>

        <Grid container spacing={2}></Grid>

        <ListBase perPage={20}
          sort={{ field: 'reference', order: 'ASC' }}
          {...props}
          context={listContext}>

          <GridList   {...{ ...props, parentId }} />

        </ListBase>

      </CardContent >
    </Card >
  );
};

export default MediaEditor;
