import React, { useState } from "react";
import { useUpdate } from "react-admin";
import { TextField as UiTextField } from "@material-ui/core";

const VolumeField = ({ record, ...props }) => {
  let [value, setValue] = useState(record.volume);

  const [update] = useUpdate("products", record.productId);

  let onChange = (e) => {
    let val = e.target.value;

    setValue(val);
    update({
      payload: {
        data: {
          id: record.productId,
          productId: record.productId,
          variant: record.variant,
          volume: val,
        },
      },
    });
  };
  return (
    <UiTextField type="number" value={value} onChange={onChange} {...props} />
  );
};

export default VolumeField;
