import React, { useState } from "react";
import { Drawer, IconButton } from "@material-ui/core";

import GoogleMapReact from "google-map-react";
import Edit from "@material-ui/icons/Edit";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useForm } from "react-final-form";
import DeleteButton from "../../../components/DeleteButton";

import Box from "@material-ui/core/Box";

import {
  Toolbar,
  Button,
  TextInput,
  useGetOne,
  useUpdate,
  SimpleForm,
  SaveButton,
  useNotify,
  useDelete,
  useCreate
} from "react-admin";

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 10,
};

const markerUrl = "https://maps.google.com/mapfiles/ms/icons/";
const LocationField = ({ record }) => {
  return (
    <div style={{ height: "400px", width: "400px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, ...props }) => {
          new window.google.maps.Marker({
            position: { lat: record.lat, lng: record.lng },
            map: map,
            icon: {
              url: markerUrl + "blue-dot.png",
            },
          });
          map.setCenter({ lat: record.lat, lng: record.lng });
        }}
      ></GoogleMapReact>
    </div>
  );
};

const PostCreateToolbar = ({ deleteOne, ...props }) => {
  return (
    <Toolbar {...props}>
      <Box display="flex" flexGrow={1}>
        {deleteOne && <DeleteButton onClick={deleteOne} />}
      </Box>
      <SaveButton />
    </Toolbar>
  );
};


const AddressForm = ({ record, handleSave, handleDelete }) => {
  return <SimpleForm
    record={record}
    resource="addresses"
    basePath="/addresses"
    save={handleSave}
    toolbar={<PostCreateToolbar deleteOne={handleDelete} />}
    handleSubmitWithRedirect={handleSave}
  >
    <TextInput fullWidth source="address" />
    <Box display="flex" fullWidth justifyContent="space-between" >
      <TextInput style={{ width: '100px' }} source="zipcode" />
      <TextInput style={{ width: '100%' }} source="city" />
    </Box>
    <TextInput
      source="misc"
      label="Delivery info"
      options={{ multiline: true, rows: 2, columns: 25 }}
      fullWidth
    />
    <TextInput fullWidth source="keyInfo" />
    {(record && record.lat) && <LocationField />}
  </SimpleForm>
}

const UpdateForm = ({ record: { id }, ...props }) => {
  const [update, { loading }] = useUpdate("addresses", id);
  const { data: record } = useGetOne("addresses", id);
  const [deleteOne] = useDelete("addresses", id);

  const handleSave = (data) => {
    if (!loading)
      update(
        { payload: { data } },
        { ...props }
      );
  }

  const handleDelete = () => {
    deleteOne();
    props.onSuccess({});
  };

  return <AddressForm {...{ record, handleSave, handleDelete }} />
}

const CreateForm = ({ defaults, ...props }) => {
  const [create] = useCreate("addresses");
  const handleSave = (data) => {
    create(
      { payload: { data: { ...defaults, ...data } } },
      { ...props }
    );
  }
  return <AddressForm {...{ handleSave }} />
}

const UpdateOrEdit = ({ record, defaults, onSave }) => {
  const notify = useNotify();
  const onSuccess = ({ data }) => {
    onSave(data);
  }
  const onFailure = ({ error }) => notify(error.message, "error");
  return record.id ? <UpdateForm {...{ record, onSuccess, onFailure }} /> :
    <CreateForm {...{ defaults, onSuccess, onFailure }} />
}

export default UpdateOrEdit;
