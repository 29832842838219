import React, { useState, useEffect } from "react";
import { SelectInput } from "react-admin";

import httpClient from "../util/HttpClient";

export const BoxSelector = (props) => {
  let [products, setProducts] = useState();
  useEffect(() => {
    if (!products) {
      setProducts([]);
      httpClient(`products?deliverable=1`).then(({ json }) =>
        setProducts(json)
      );
    }
  }, [products]);

  return (
    <SelectInput
      choices={products}
      label="Product"
      optionText="name"
      optionValue="id"
      {...props}
    />
  );
};
