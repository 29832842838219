import React, { useEffect } from "react";
import config from "../config";
import GoogleLogin from "react-google-login";
import { withStyles, createStyles } from "@material-ui/core/styles";
const styles = ({ spacing }) =>
  createStyles({
    btn: {
      width: "100%",
    },
  });

const cleanup = () => {
  // Remove the ?code&state from the URL
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin
  );
  window.location.href = window.location.origin;
};

const loadScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client"; // whatever url you want here
    script.charset = "utf-8";
    script.async = true;
    script.onload = function () {
      resolve();
    };
    document.head.appendChild(script);
  });
};
const onGoogleResponse = async (e) => {
  // Transform the code to a token via the API
  const response = await fetch(`${config.API}/auth/google`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(e),
  });
  const token = await response.json();

  localStorage.setItem("token", token);
  cleanup();
};
const LoginForm = ({ classes, ...props }) => {
  useEffect((e) => {
    loadScript().then((e) => {
      window.google.accounts.id.initialize({
        client_id: config.oAuthClientId,
        callback: (e) => onGoogleResponse(e),
      });
      window.google.accounts.id.prompt();
    });
  });
  return (
    <GoogleLogin
      clientId={config.oAuthClientId}
      buttonText="Login"
      onSuccess={onGoogleResponse}
      onFailure={"responseGoogle"}
      cookiePolicy={"single_host_origin"}
      className={classes.btn}
    />
  );
};

export default withStyles(styles)(LoginForm);
