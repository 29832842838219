import SideSelectorInput from "../../../components/SideSelectorInput"
import FaceIcon from "@material-ui/icons/Face";
import AccountForm from "./AccountSmallForm"

const renderFunc = (record) => {
  if (!record) return 'select'
  return record.name ? `${record.name}` : `${record.firstName}, ${record.lastName} ${record.middleName || ''}`;
}

export const AccountInput = (props) => {
  return <SideSelectorInput {...{
    ...props,
    renderFunc,
    resource: "accounts",
    startIcon: <FaceIcon />,
    Editor: AccountForm
  }} />
};
export default AccountInput;
