import { IconButton, } from "@material-ui/core";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";


const ExportLink = ({ record, source, createOrder }) => {
  if (source.indexOf(".") > 0) {
    record = record[source.split('.')[0]];
    source = source.split('.')[1];
  }

  return (
    <a
      target="export"
      href={`https://export.destreekboer.nl/site/login?redirect_to=/order/update/${record[source]}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >{record[source]}
    </a>
  )

};
export default ExportLink;