import { IconButton, } from "@material-ui/core";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";


const CreateOrderButton = ({ createOrder, id }) => {
  return (<IconButton aria-label="delete" onClick={(e) => {
    createOrder(id)
    e.preventDefault();
    e.stopPropagation();
  }} size="small">
    <PlaylistAdd fontSize="inherit" />
  </IconButton>)
}

const AddOrderBtn = ({ record, source, createOrder }) => {
  if (source.indexOf(".") > 0) {
    record = record[source.split('.')[0]];
    source = source.split('.')[1];
  }

  return <CreateOrderButton {...{ createOrder, id: record.id }} />

};
export default AddOrderBtn;