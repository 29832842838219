import * as React from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

export const AutoRefInput = ({
  filter,
  reference,
  optionValue = "id",
  optionText = "name",
  allowEmpty = true,
  resettable = true,
  clearAlwaysVisible = true,
  className,
  options,
  ...rest
}) => {
  return (
    <ReferenceInput {...{ filter, reference, ...rest, ...options }}>
      <AutocompleteInput options={{ inputProps: { 'data-lpignore': "true", } }}
        {...{
          allowEmpty,
          className,
          resettable,
          clearAlwaysVisible,

          optionValue,
          optionText,
        }}
      />
    </ReferenceInput>
  );
};

export default AutoRefInput;
