import React, { useState, useEffect } from "react";
import {

  List,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import StopListItem from "./StopListItem";

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});



const StopList = ({ items, selected, updateOrder, changeOrder, reorderMode, onDelete, ...props }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    changeOrder(result.source.index, result.destination.index);

  };

  return (
    <>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List style={{
                overflowY: 'scroll', maxHeight: 'calc(100vh - 300px)',
                ...getListStyle(snapshot.isDraggingOver)
              }}>
                {items.map((item, index) => {
                  return (
                    <StopListItem key={item.id}
                      isDragDisabled={!reorderMode}
                      {...{ item, onDelete, selected: selected === index, index, ...props }}
                    />
                  );
                })}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>

    </>

  );
};

export default StopList;
