import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FancyTime from "../../components/FancyTime";

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Box,
} from "@material-ui/core";
import { Mail, WhatsApp, Call } from "@material-ui/icons";
import SenderAvatar from "./SenderAvatar";
import SenderName from "./SenderName";

const useStyles = makeStyles((theme) => ({
  ticket: {
    margin: "1em 0",
    cursor: "pointer",
    borderRadius: 10,
    borderBottom: "2px solid #ebedf5",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      fontSize: "1em",
    },
    "& .MuiCardHeader-root": {
      padding: 2,
    },
    "& .MuiTypography-h5": {
      fontSize: 14,
      fontWeight: 600,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: 176,
    },
    "& .MuiCardContent-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      padding: 2,
      width: 176,
      fontSize: "0.8em",
    },
    "& .MuiCardActions-root": {
      color: "#abaebe",
      fontSize: "0.7em",
      padding: 2,
      justifyContent: "space-between",
    },
  },
  selected: {
    backgroundColor: "#ebedf5",
  },
}));

const Ticket = ({ Messages, onClick, active, channel, ...ticket }) => {
  let message = Messages[0];
  let classes = useStyles();
  if (!message) return null;
  message.Ticket = ticket;
  let selected = active ? classes.selected : null;

  return (
    <Card
      key={ticket.id}
      elevation={0}
      className={[classes.ticket, selected].join(" ")}
      onClick={onClick}
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <SenderAvatar {...message} />
        </Box>
        <Box flexGrow={4}>
          <CardHeader title={<SenderName {...message} />}></CardHeader>
          <CardContent
            dangerouslySetInnerHTML={{
              __html: message.text,
            }}
          ></CardContent>
          <CardActions>
            <FancyTime value={message.createdAt} />
            <div>
              {channel === "email" && <Mail fontSize="small" />}
              {channel === "app" && <WhatsApp fontSize="small" />}
              {channel === "phone" && <Call fontSize="small" />}
            </div>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
};
export default Ticket;
