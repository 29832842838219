import React, { PureComponent } from "react";

import moment from "moment";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload, tickFormatter = (e) => e } = this.props;
    let val = tickFormatter(payload.value);
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {val}
        </text>
      </g>
    );
  }
}

export default CustomizedAxisTick;
