import React, { useState } from "react";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { useCreate, useNotify, useGetList } from "react-admin";
const useStyles = makeStyles({});

const AddLoveDialog = ({ orderId, open, setOpen }) => {
  const classes = useStyles();
  const [create] = useCreate("orderlines");
  const notify = useNotify();

  const { data, ids = [] } = useGetList(
    "products",
    { page: 1, perPage: 10 },
    { field: "name", order: "ASC" },
    { isLove: 1 }
  );

  const handleListItemClick = (productId) => {
    create(
      { payload: { data: { orderId, productId } } },
      {
        onSuccess: ({ data }) => {
          setOpen(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent>
        <Typography>What kind of love?</Typography>
        <List>
          {ids.map((id) => (
            <ListItem button onClick={() => handleListItemClick(id)} key={id}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Favorite />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={data[id].name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const WithLove = ({ record, source }) => {
  let [love, setLove] = useState(false);

  let onClick = (e) => {
    setLove(true);
    e.stopPropagation();
  };
  let hasLove = false;
  return (
    <IconButton onClick={onClick}>
      <AddLoveDialog open={love} setOpen={setLove} orderId={record[source]} />
      {hasLove ? <Favorite /> : <FavoriteBorder />}
    </IconButton>
  );
};

export default WithLove;
