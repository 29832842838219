import React from "react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useInput } from "ra-core";
import { useState } from "react";
import moment from "moment";

export const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const colors = [
  "#E0AC00",
  "#EE4266",
  "#ffd23f",
  "#3BCEAC",
  "#0EAD69",
  "#BF48EA",
  "#EE4266",
];
export const dayToColor = (day, alpha = 1) => {
  if (typeof day == "string") day = Math.pow(2, weekdays.indexOf(day));
  return weekdays.reduce((acc, cur, idx) => {
    return (Math.pow(2, idx) & day) > 0 ? colors[idx] : acc;
  }, colors[0]);
};

export const daysRenderer = (record) => {
  if (!record) return "";
  return weekdays
    .filter((day, idx) => (Math.pow(2, idx) & record.days) > 0)
    .join(",");
};

export const dateToInt = (date) => {
  let dayOfWeek = new moment(date).day();
  return Math.pow(2, dayOfWeek);
}

const intToDays = (intVal) => {
  let val = weekdays.filter((e, idx) => intVal & Math.pow(2, idx));
  return val;
};

const daysToInt = (daysVal, allWhenNone = false) => {
  let val = daysVal.reduce((f, e) => {
    return f + Math.pow(2, weekdays.indexOf(e));
  }, 0);
  if (val == 0 && allWhenNone) val = 127;
  return val;
};

const DayButton = ({ selected, value, ...props }) => {
  let style = selected
    ? {
      backgroundColor: dayToColor(value, 0.5),
    }
    : null;
  return <ToggleButton style={style} {...{ selected, value, ...props }} />;
};
export default function DaySelector({ record, allWhenNone = false, ...props }) {
  const {
    input: { onChange, value = 127 },
  } = useInput(props);
  const [days, setDays] = useState(intToDays(value));

  const handleDayChange = (event, daysVal) => {
    setDays(daysVal);

    onChange(daysToInt(daysVal, allWhenNone));
  };

  return (
    <ToggleButtonGroup value={days} onChange={handleDayChange}>
      {weekdays.map((day, idx) => (
        <DayButton value={day} size="small" key={idx}>
          {day}
        </DayButton>
      ))}
    </ToggleButtonGroup>
  );
}
