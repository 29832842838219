import * as React from 'react';
import api from "../../../util/HttpClient";

import { List, ListActions, Button, FilterButton, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import IconEvent from '@material-ui/icons/Event';

export default (props) => {
  return <TopToolbar>

    {/* Add your custom actions */}
    <Button
      onClick={() => {
        api('subscriptions/export')
      }}
      label="Fill sheet"
    >
      <IconEvent />
    </Button>
  </TopToolbar>
}

