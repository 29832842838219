import React, { useState, useEffect, useRef } from "react";
import { List, DateInput } from "react-admin";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import icon from "@material-ui/icons/TrackChanges";
import { guardListAccess } from "../../util/Guard";
import ago from "s-ago";

import { Filter } from "react-admin";
import MapSlider from "../../components/MapSlider";

import AutoRefInput from "../../components/AutoRefInput";

let runningAVG = (arr, start, end, param) => {
  let min = Math.max(start, 0);
  let max = Math.min(end, arr.length - 1);
  return arr.slice(min, max).reduce((acc, item, idx) => {
    if (idx === 0) return item[param];
    if (item[param] === 0) return acc;
    else return (acc * idx + item[param]) / (idx + 1);
  }, 0)
}

const PoslogFilter = ({
  permissions: { authenticated, canRead } = {},
  onChange,
  ...props
}) => {
  return (
    <Filter {...props} >
      <DateInput alwaysOn={true} label="date" source="date" />
      <AutoRefInput
        alwaysOn={true}
        reference="vans"
        optionValue="id"
        optionText="name"
        source="trackerId"

      />
    </Filter>
  );
};

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 8,
};

let gMap = null;
let currMax = 0;
let prevVal = 0;

const loadData = ({ rows, setMarkers }) => {
  let google = window.google;
  if (!gMap) return;
  const trafficLayer = new google.maps.TrafficLayer();

  trafficLayer.setMap(gMap);
  currMax = 0;
  let markers = []
  for (var point of rows) {
    let lat = parseFloat(point.lat);
    let lng = parseFloat(point.lon);
    markers.push(new google.maps.Marker({
      position: { lat, lng },
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: '#00F',
        fillOpacity: 0.6,
        strokeColor: '#00A',
        strokeOpacity: 0.9,
        strokeWeight: 1,
        scale: 3
      }
    }));
  }
  setMarkers(markers);
  afterSliderChange(markers, markers.length)
};
let getTime = (log) => {
  return new moment(log.createdAt).format('HH:mm');
}
let afterSliderChange = (markers, value) => {
  let toUpdate = Math.min(prevVal - 50, value);
  if (!markers.length) return;
  toUpdate = Math.max(0, toUpdate);
  let toUpdateMax = Math.max(prevVal, value - 1, 0);
  toUpdateMax = Math.min(toUpdateMax, markers.length - 1)
  while (toUpdate <= toUpdateMax) {
    let marker = markers[toUpdate++];
    if (!marker)
      console.log(toUpdate, toUpdateMax)
    else {
      let visible = toUpdate > value - 50 && toUpdate < value;
      marker.setMap(visible ? gMap : null);
    }
  }
  prevVal = value;
}

let SimpleMap = ({ loading, data, ids, filterValues, ...props }) => {
  let [sliderValue, setSliderValue] = useState(0);
  let [max, setMax] = useState(0);
  let [markers, setMarkers] = useState([]);
  let ref = useRef();
  useEffect(() => {
    if (!loading) {
      loadData({ rows, setMarkers })
      setMax(ids.length)
      setSliderValue(ids.length)
    }
  }, [ids, loading])

  if (loading) return "";
  let rows = ids.map(o => data[o]);
  let startPos = new moment();
  let endPos = new moment();
  let speed = 0;
  let batt = 0;
  let odo = 0;
  let sValue = Math.min(sliderValue, ids.length);
  if (rows.length) {
    startPos = rows[Math.max(0, sValue - 50)];
    endPos = rows[Math.max(0, sValue - 1)];
    speed = endPos.speed;
    batt = endPos.batt;
    batt = parseInt(runningAVG(rows, sValue - 100, sValue - 1, 'batt'));
    odo = endPos.odo - startPos.odo;
  }
  odo = parseInt(odo / 1000);
  let agoS = ago(new moment(endPos.createdAt).toDate());

  let title = `${getTime(startPos)}-${getTime(endPos)} (${agoS} - ${speed}km/h - 📏${odo}km)`
  return (

    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <div ref={ref} style={{
        width: '100%',
        height: '50px',
        padding: '0px 55px',
        boxSizing: 'border-box',
      }}>
        <MapSlider
          max={max}
          min={0}
          title={title}
          defaultValue={sliderValue}
          value={sliderValue}
          onChange={(props, newValue) => {
            setSliderValue(newValue);
            afterSliderChange(markers, newValue)
          }}
        /></div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        data={data}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        // heatmapLibrary={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          gMap = map;
          loadData({ rows, setMarkers });
          map.controls[maps.ControlPosition.BOTTOM_CENTER].push(ref.current);
        }}
      // heatmap={heatMapData}
      ></GoogleMapReact>

    </div>
  );
};

export const Poslogviewer = (props) => {
  return (
    <>
      <List
        title="My custom title"
        pagination={<></>}
        {...props}
        sort={{ field: 'createdAt', order: 'ASC' }}
        filters={<PoslogFilter />}
      >
        <SimpleMap />
      </List>
    </>
  );
};

const resource = ({ canRead }) => {
  return {
    name: "poslogs",
    list: guardListAccess(Poslogviewer),
    icon,
    options: {
      label: "Trackers",
      group: "Logistic",
      showMenu: canRead("poslogs"),
    },
  };
};
export default resource;
