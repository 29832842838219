import React from "react";
import CopyAll from "@material-ui/icons/FileCopy";
import icon from "@material-ui/icons/DepartureBoard";
import moment from "moment";
import 'moment/locale/nl'
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  Create,
  FunctionField,
  useCreate,
  useRefresh,
  FilterLiveSearch,
  Filter,
  ReferenceField,
  SelectInput,
  Pagination
} from "react-admin";
import { RouteEditor } from "./RouteEditor"
import { daysRenderer } from '../../../components/DaySelector';
import { Button } from "@material-ui/core"
import AutoRefInput from "../../../components/AutoRefInput";
import DaySelector from "../../../components/DaySelector";

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

const Filters = (props) => {
  return (
    <Filter {...props}>
      <DaySelector allWhenNone label="Days" source="days" alwaysOn={true} />
      <SelectInput source="show" alwaysOn={true} choices={[
        { id: 'past', name: 'Past' },
        { id: 'templates', name: 'Templates' },
        { id: 'rides', name: 'Rides' },
      ]} />
      <AutoRefInput
        reference="users"
        source="driverId"
        optionValue="id"
        optionText="firstName"
      />
      <TextInput type="date" source="date" />
      <AutoRefInput
        source="week"
        label={"Week"}
        optionValue="id"
        reference="weeks" />
    </Filter>

  );
};


const create = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />

    </SimpleForm>
  </Create>
);
const edit = (props) => (
  <Edit {...props}>
    <RouteEditor />
  </Edit>
);
const CreateRouteBut = ({ record }) => {
  const [create] = useCreate("routes");
  const refresh = useRefresh()
  const addChildRoute = () => {
    create(
      {
        payload: {
          data: {
            ...record,
            parentId: record.id,
            id: undefined,
          }
        }
      },
      {
        onSuccess: async ({ data }) => {
          console.log(data);
          refresh();
        },
        onFailure: ({ error }) => {
          // notify(error.message, "error");
        },
      })
  }
  if (record.date)
    return null;
  return <Button size="small" onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    addChildRoute();
    return false;
  }} ><CopyAll /></Button>
}

const list = (props) => {
  return (
    <List
      filters={<Filters />}
      pagination={<PostPagination />}
      perPage={50}
      {...props} sort={{ field: 'date', order: 'ASC' }} >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <FunctionField source="date" label="Date" render={(record) => {
          if (record.date) {
            return new moment(record.date)
              .locale("nl")
              .format("dddd D MMM");
          }
          else return daysRenderer(record);
        }} />
        <ReferenceField label="Driver" source="driverId" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField label="Vehicle" source="vanId" reference="vans">
          <TextField source="name" />
        </ReferenceField>
        <CreateRouteBut />
      </Datagrid>
    </List>
  );
};
const Task = ({ is, ...props }) => {
  return {
    name: "routes",
    list,
    create,
    edit,
    icon,
    options: {
      showMenu: is(["route-planner", "admin"]),
      group: "Logistic",
      label: "Routes",
    },
    ...props,
  };
};

export default Task;