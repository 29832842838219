import React from "react";
import icon from "@material-ui/icons/Widgets";
import { Filter } from "react-admin";
import { AutoRefInput } from "../../../components/AutoRefInput";
import { NumberField, Datagrid, List, TextField } from "react-admin";

const TotalFilter = (props) => {
  return (
    <Filter {...props}>
      <AutoRefInput reference="weeks" source="weekId" />
    </Filter>
  );
};

const list = (props) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<TotalFilter />}>
      <Datagrid>
        <TextField source="name" />
        <NumberField source="deliveryCount" />
        <NumberField source="pickupCount" />
        <NumberField source="total" />
      </Datagrid>
    </List>
  );
};
const def = ({ canRead, ...props }) => {
  return {
    name: "boxTotals",
    list,
    icon,
    options: {
      group: "Product",
      showMenu: canRead("boxTotals"),
      label: "Box totals",
    },
    ...props,
  };
};
export default def;