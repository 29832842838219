import People from "@material-ui/icons/People";
import React from "react";

import { useMediaQuery } from "@material-ui/core";

import CardWithIcon from "./CardWithIcon";
import moment from "moment";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <div style={{ height: "1em" }} />;

const Account = ({ data }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  let { lastWeeksNumbers, thisWeeksNumbers, newAccounts } = data;

  let thisWeekAcc = thisWeeksNumbers[0].accounts;
  let lastWeekAcc = lastWeeksNumbers[0].accounts;

  let diffAcc = (thisWeekAcc - lastWeekAcc) / lastWeekAcc;

  let titlePrev = `Last week @${new moment().format("HH:mm")}`;
  let titleCurr = "This week ";
  let subTitleColor = thisWeekAcc > lastWeekAcc ? "#090" : "#f00";
  let thisWeekValue = `${thisWeekAcc} (${newAccounts} new)`;
  let subTitleAcc = `(${
    thisWeekAcc > lastWeekAcc ? "+" : ""
  } ${new Intl.NumberFormat("nl-NL", {
    style: "percent",
  }).format(diffAcc)})`;

  return isXSmall ? (
    <div style={styles.leftCol}>
      <div style={styles.flex}>
        <CardWithIcon
          to={`/accounts?filter={"lastOrder":"lastWeek"}`}
          icon={People}
          title={titlePrev}
          value={lastWeekAcc}
        />
      </div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <CardWithIcon
          to={`/accounts?filter={"lastOrder":"thisWeek"}`}
          icon={People}
          title={titleCurr}
          subTitle={subTitleAcc}
          subTitleColor={subTitleColor}
          value={thisWeekValue}
        />
      </div>
    </div>
  ) : (
    <div style={styles.flex}>
      <CardWithIcon
        to={`/accounts?filter={"lastOrder":"lastWeek"}`}
        icon={People}
        title={titlePrev}
        value={lastWeekAcc}
      />
      <Spacer />
      <CardWithIcon
        to={`/accounts?filter={"lastOrder":"thisWeek"}`}
        icon={People}
        title={titleCurr}
        subTitle={subTitleAcc}
        subTitleColor={subTitleColor}
        value={thisWeekValue}
      />
    </div>
  );
};

export default Account;
