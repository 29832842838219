import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import icon from "@material-ui/icons/Forum";
import Aside from "./MessageListAside";
import EmailGrid from "./EmailGrid";
import AppGrid from "./AppGrid";
import PhoneGrid from "./PhoneGrid";

import { guardListAccess } from "../../util/Guard";

import { List, WithPermissions } from "react-admin";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "initial",
  },
}));
const Converation = ({ ids, data, loading, ...props }) => {
  if (loading) return null;
  let channel = data[ids[0]].Ticket.channel;
  return (
    <>
      {channel === "email" && <EmailGrid {...{ ids, data, ...props }} />}
      {channel === "app" && <AppGrid {...{ ids, data, ...props }} />}
      {channel === "phone" && <PhoneGrid {...{ ids, data, ...props }} />}
    </>
  );
};

export const UserList = ({ permissions: { authenticated, is }, ...props }) => {
  let classes = useStyles();
  let [title, setTitle] = useState("Messages");
  return (
    <List
      aside={<Aside />}
      perPage={25}
      bulkActionButtons={false}
      actions={null}
      pagination={null}
      title={title}
      {...props}
      classes={classes}
    >
      <Converation setTitle={setTitle} />
    </List>
  );
};

const UserListWithPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <UserList permissions={permissions} {...props} />;
    }}
  />
);

export default ({ canRead, ...props }) => {
  return {
    name: "messages",
    list: guardListAccess(UserListWithPerms),

    icon,
    options: {
      group: "Misc",
      label: "Messages",
      showMenu: canRead("messages"),
    },
    ...props,
  };
};
