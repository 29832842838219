import React, { createContext, useState } from "react";
import moment from "moment";
import ago from "s-ago";
import { Button } from "@material-ui/core";

export const TimeNotationContext = createContext({
  notation: "ago",
  setNotation: () => { },
});

export const TimeNotationProvider = ({ children }) => {
  let [notation, setNotation] = useState("ago");

  return (
    <TimeNotationContext.Provider value={{ notation, setNotation }}>
      {children}
    </TimeNotationContext.Provider>
  );
};
const FancyTime = ({
  value,
  record,
  source,
  showTime = true,
  timeFormat = "D-M-YYYY",
  ...props
}) => {
  const { notation, setNotation } = React.useContext(TimeNotationContext);
  let agoTime = notation === "ago";

  let date = value ? value : record ? record[source] : null;
  if (!date) return "-";

  const onClick = (e) => {
    setNotation(agoTime ? "time" : "ago");
    e.stopPropagation();
    e.preventDefault();
  };

  let m = new moment(date);

  let text = showTime ? m.format("D-M-YYYY H:mm") : m.format(timeFormat);
  if (agoTime) text = ago(m.toDate());

  text = text.toLowerCase();

  return (
    <Button
      style={{
        textTransform: "none",
        paddingLeft: 0,
        textAlign: "left",
        justifyContent: "left",
      }}
      onClick={onClick}
      color="secondary"
      {...props}
    >
      {text}
    </Button>
  );
};
export default FancyTime;
