import React, { useState, useEffect } from "react";
import httpClient from "../../../util/HttpClient";
import { DateTimeInput } from "../../../components/DateTime";
import icon from "@material-ui/icons/ShoppingBasket";
import OrderlineFilter from "./OrderlineFilter";
import FullNameField from "../Account/FullNameField";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  NumberInput,
  Create,
  ReferenceField,
} from "react-admin";

import { guardListAccess } from "../../../util/Guard";

const CreateForm = (props) => {
  let [defaults, setDefaults] = useState();
  useEffect(() => {
    if (!defaults)
      httpClient(`rides/defaults/get`).then(({ json }) => setDefaults(json));
  }, [defaults]);
  return defaults ? (
    <Create {...props}>
      <Form {...{ defaults }} />
    </Create>
  ) : null;
};

const MyGrid = (props) => {
  return (
    <Datagrid {...props}>
      <ReferenceField
        label="Account"
        source="Order.accountId"
        reference="accounts"
      >
        <FullNameField />
      </ReferenceField>
      <ReferenceField label="Product" source="productId" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="Order.crates" />
      <TextField source="variant" />
      <TextField source="quantity" />
      <TextField source="total" />
      <TextField source="purchasePrice" />
    </Datagrid>
  );
};

const Form = ({ defaults, ...props }) => {
  return (
    <SimpleForm {...props}>
      <DateTimeInput source="date" />
      <NumberInput
        source="milesStart"
        label="KM start"
        defaultValue={defaults ? defaults.milesStart : 0}
      />
      <NumberInput source="milesEnd" label="KM end" />
      <TextInput source="misc" label="purpose" />
      <TextInput
        source="vanId"
        label="license plate"
        defaultValue={defaults ? defaults.vanId : 0}
      />
    </SimpleForm>
  );
};
const EditForm = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

const OrderlineList = ({ permissions: { canRead }, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={props.hideFilters ? null : <OrderlineFilter weekInput={true} />}
    >
      <MyGrid />
    </List>
  );
};

export default ({ canRead, ...props }) => {
  return {
    name: "orderlines",
    list: guardListAccess(OrderlineList),
    create: CreateForm,
    edit: EditForm,
    icon,
    options: {
      showMenu: canRead("orderlines"),
      label: "Orderlines",
    },
    ...props,
  };
};
