import React, { useState, useEffect } from "react";
import httpClient from "../../util/HttpClient";
import { daysRenderer } from "../../components/DaySelector";
import icon from "@material-ui/icons/Autorenew";
import DaySelector from "../../components/DaySelector";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  FunctionField,
  NumberInput,
  Create,
} from "react-admin";

import { guardListAccess } from "../../util/Guard";
import FancyTime from "../../components/FancyTime";

const CreateForm = (props) => {
  let [defaults, setDefaults] = useState();
  useEffect(() => {
    if (!defaults)
      httpClient(`rides/defaults/get`).then(({ json }) => setDefaults(json));
  }, [defaults]);
  return defaults ? (
    <Create {...props}>
      <Form {...{ defaults }} />
    </Create>
  ) : null;
};

const MyGrid = (props) => {
  return (
    <Datagrid {...props} rowClick="edit">
      <FancyTime source="startDate" />
      <FunctionField source="days" render={daysRenderer} />
      <TextField
        source="minutes"
        render={(e) => {
          return e / 120;
        }}
      />
      <TextField source="quantity" />
    </Datagrid>
  );
};

const Form = ({ defaults, ...props }) => {
  return (
    <SimpleForm {...props}>
      <TextInput type="date" source="startDate" />
      <NumberInput
        source="duration"
        label="Duration minutes"
        defaultValue={60}
      />
      <DaySelector source="days" />
      <TextInput source="misc" />
      <TextInput type="time" source="shopClosingTime" />
      <NumberInput source="shopClosingDays" />
    </SimpleForm>
  );
};
const EditForm = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

const ProgramList = ({ permissions: { canRead }, ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <MyGrid />
    </List>
  );
};

const def = ({ canRead, ...props }) => {
  return {
    name: "programs",
    list: guardListAccess(ProgramList),
    create: CreateForm,
    edit: EditForm,
    icon,
    options: {
      showMenu: canRead("programs"),
      label: "programs",
    },
    ...props,
  };
};
export default def;