import React from "react";
import GpsFixed from "@material-ui/icons/GpsFixed";
import AddressForm from "./AddressForm";

import SideSelectorInput from "../../../components/SideSelectorInput"

const renderFunc = (record) => {
  if (!record) return 'select'
  return <>{record.city}, {record.address}</>
}


export const AddressInput = (props) => {
  return <SideSelectorInput {...{
    ...props,
    renderFunc,
    resource:
      'addresses',
    startIcon: <GpsFixed />,
    Editor: AddressForm
  }} />
};

export default AddressInput;
