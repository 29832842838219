import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SenderName from "./SenderName";
import SenderAvatar from "./SenderAvatar";
import ContactLookupButton from "../../util/ContactLookupContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  CardMedia,
  Link,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

import AppInput from "./AppInput";
import FancyTime from "../../components/FancyTime";

const useStyles = makeStyles((theme) => {
  return {
    grow: { flex: 1 },
    paper: {
      backgroundColor: "#fafafa",
    },
    media: {
      height: 300,
    },
    message: {
      margin: "1em",
      maxWidth: "80%",
      backgroundColor: "#fff",
      position: "relative",
      minWidth: "60%",
      float: "left",
      borderRadius: 10,
      "& .MuiCardHeader-root": {},
      "& .MuiCardHeader-title": {
        display: "flex",
      },
    },
    right: {
      float: "right",
      backgroundColor: "#dcf8c6",
    },
    spacer: {
      clear: "both",
    },
    AppBar: {
      marginBottom: "2em",
      backgroundColor: theme.palette.secondary.dark,
    },
    background: {
      position: "absolute",
      top: 0,
      width: " 100%",
      height: "100%",
      border: 0,
      opacity: 0.06,
      backgroundColor: "#e5ddd5",
      backgroundSize: "412.5px 749.25px",
      backgroundImage: `url("https://web.whatsapp.com/img/bg-chat-tile-light_686b98c9fdffef3f63127759e2057750.png")`,
    },
    root: {
      backgroundColor: "#e5ddd5",
      width: " 100%",
      height: "100%",
    },
    list: {
      width: " 100%",
      height: "100vh",
    },
    time: {
      position: "absolute",
      bottom: 0,
      right: 3,
    },
  };
});
const baseUrl = "https://s3.eu-central-1.amazonaws.com/trengo/media/";
let AppGrid = ({ data, ids, setTitle }) => {
  let classes = useStyles();

  let msg0 = data[ids[0]];
  return (
    <div className={classes.root}>
      <div className={classes.background}></div>

      <AppBar position="sticky" className={classes.AppBar}>
        <Toolbar>
          <Typography variant="h6">
            <SenderName {...msg0} />
          </Typography>
          <div className={classes.grow} />
          <ContactLookupButton {...msg0.Ticket} />
        </Toolbar>
      </AppBar>
      <div className={classes.list}>
        {ids.map((id) => {
          let message = data[id];
          let attachments = [];
          let outbound = message.direction < 0;
          if (message.attachments && message.attachments.length > 10)
            attachments = JSON.parse(message.attachments);

          return (
            <div>
              <div className={classes.spacer}></div>
              <Card
                elevation={0}
                className={[
                  classes.message,
                  outbound ? classes.right : classes.left,
                ].join(" ")}
              >
                {outbound && (
                  <CardHeader
                    title={
                      <>
                        <SenderAvatar {...message} />
                        <SenderName {...message} />
                      </>
                    }
                  ></CardHeader>
                )}

                {message.type === "IMAGE" ? (
                  <CardMedia
                    className={classes.media}
                    image={`${baseUrl}${attachments[0].fileName}`}
                    title="Paella dish"
                  >
                    <SenderAvatar {...message} />
                  </CardMedia>
                ) : (
                  <CardContent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.text.replaceAll(/\n/g, "<br/>"),
                      }}
                    ></div>
                    <FancyTime
                      className={classes.time}
                      value={message.createdAt}
                    />
                  </CardContent>
                )}

                {message.type !== "IMAGE" && attachments.length ? (
                  <CardActions>
                    {attachments.map((attachment) => (
                      <>
                        <Typography component="h6">Bijlagen</Typography>
                        <Link
                          href={`${baseUrl}${attachment.fileName}`}
                          target="_blank"
                        >
                          <Button startIcon={<GetApp />} size="small">
                            {attachment.client_name}
                          </Button>
                        </Link>
                      </>
                    ))}
                  </CardActions>
                ) : null}
              </Card>
            </div>
          );
        })}
      </div>
      <AppInput />
    </div>
  );
};
export default AppGrid;
