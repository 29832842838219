import Slider from "@material-ui/core/Slider";
const MapSlider = ({ title, ...props }) => {
  return <div style={{
    width: '100%',
    padding: '0px 40px',
    boxSizing: 'border-box',
    height: '50px',
    backgroundColor: '#fff',
    display: 'block'
  }}>
    <Slider
      {...props}
    />
    <div style={{
      width: '100%',
      boxSizing: 'border-box',
    }}>{title}</div>
  </div>
}

export default MapSlider;