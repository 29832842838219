// in src/comments.js
import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import FullNameField from "../Account/FullNameField";
import { DateField, EditButton, NumberField } from "react-admin";

import SegmentsField from "../Account/SegmentsField";

const useStyles = makeStyles((theme) => ({
  root: { margin: "1em" },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  cardTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContent: {
    ...theme.typography.body1,
    display: "flex",
    flexDirection: "column",
  },
}));

const MobileGrid = ({ ids, data, basePath }) => {
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <FullNameField record={data[id].Account} />

                <EditButton
                  resource="accounts"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <div>
              Order date: &nbsp;
              <DateField record={data[id]} source="orderDate" />
            </div>
            <div>
              Cratenumber: &nbsp;
              <NumberField record={data[id]} source="crates" />
            </div>
            <div>
              Total: &nbsp;{" "}
              <NumberField
                source="totalAmount"
                options={{
                  style: "currency",
                  currency: "EUR",
                }}
                record={data[id]}
              />
            </div>
          </CardContent>
          {data[id].groups && data[id].groups.length > 0 && (
            <CardContent className={classes.cardContent}>
              <SegmentsField record={data[id]} />
            </CardContent>
          )}
        </Card>
      ))}
    </div>
  );
};

export default MobileGrid;
