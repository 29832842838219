import React, { useState } from "react";
import { DateTimeInput, TimeInput } from "../../components/DateTime";
import moment from "moment";

import icon from "@material-ui/icons/Timer";
import AutoRefInput from "../../components/AutoRefInput";
import {
  Edit,
  SimpleForm,
  Filter,
  Datagrid,
  List,
  Create,
  TextInput,
  ReferenceField,
  NumberField,
  NumberInput,
  TextField,
  WithPermissions,
  SearchInput,
} from "react-admin";
import FancyTime from "../../components/FancyTime";

const mod = function (n, m) {
  var remain = n % m;
  return Math.floor(remain >= 0 ? remain : remain + m);
};

let EditForm = ({ record, ...props }) => {
  let completeRec = {
    ...record,
    end: new moment(record.date).add(record.hours + record.break, "hours"),
  };
  let [timeSheet, setTimeSheet] = useState(completeRec);

  let onStartChange = (val) => {
    let date = new moment(val);
    date.minutes(parseInt((date.minutes() + 7) / 15) * 15);
    console.log();
    let end = new moment(date).add(timeSheet.hours, "hours");
    setTimeSheet({ ...timeSheet, date, end });
  };

  let onEndChange = (val) => {
    let end = new moment(val);

    let hours = end.diff(timeSheet.date, "minutes") / 60.0;
    hours = mod(hours, 24);
    hours -= timeSheet.break;
    setTimeSheet({ ...timeSheet, hours, end });
  };

  let onHourChange = (event, prop) => {
    let hours = parseFloat(event.target.value);
    let end = new moment(timeSheet.date).add(hours + timeSheet.break, "hours");
    setTimeSheet({ ...timeSheet, hours, end });
  };

  let onBreakChange = (event, prop) => {
    let breakt = parseFloat(event.target.value);
    let end = new moment(timeSheet.date).add(timeSheet.hours + breakt, "hours");
    setTimeSheet({ ...timeSheet, break: breakt, end });
  };

  return (
    <SimpleForm record={timeSheet} {...props}>
      <DateTimeInput
        label="Datum"
        minutesStep={15}
        source="date"
        onChange={onStartChange}
      />
      <AutoRefInput reference="tasks" source="taskId" />

      <NumberInput source="hours" onChange={onHourChange} step={0.25} />

      <NumberInput source="break" onChange={onBreakChange} step={0.25} />

      <TextInput source="misc" />
      <TimeInput source="end" minutesStep={15} onChange={onEndChange} />
      <NumberInput source="miles" step={0.5} />
    </SimpleForm>
  );
};
const create = (props) => (
  <Create {...props}>
    <EditForm />
  </Create>
);
const edit = (props) => {
  return (
    <Edit {...props}>
      <EditForm />
    </Edit>
  );
};

const UserFilter = ({ permissions: { authenticated, is } = {}, ...props }) => {
  return (
    <Filter {...props}>
      {authenticated && is(["admin", "manager"]) ? (
        <SearchInput source="q" alwaysOn />
      ) : null}
    </Filter>
  );
};

const FilterWithPermissions = ({ location, match, ...props }) => (
  <WithPermissions
    render={({ permissions }) => {
      return <UserFilter permissions={permissions} {...props} />;
    }}
  />
);
const Userlist = ({ permissions, ...props }) => {
  let allowUser = permissions.is(["admin", "hr-manager"]);

  return (
    <List
      bulkActionButtons={false}
      {...props}
      sort={{ field: "date", order: "DESC" }}
      filters={<FilterWithPermissions />}
    >
      <Datagrid rowClick="edit">
        <FancyTime source="date" />
        <NumberField locales="nl" source="hours" />

        <ReferenceField
          label="Task"
          source="taskId"
          reference="tasks"
          sortBy="name"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>

        {allowUser && (
          <ReferenceField label="User" source="userId" reference="users">
            <TextField source="firstName" />
          </ReferenceField>
        )}

        <FancyTime source="createdAt" label="Add date" />
      </Datagrid>
    </List>
  );
};

const ListWithPermissions = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return typeof permissions == "object" ? (
        <Userlist {...{ ...props, permissions }} />
      ) : null;
    }}
  />
);
const TimeSheet = ({ canRead, ...props }) => {
  return {
    name: "timeSheets",
    list: ListWithPermissions,
    create,
    edit,
    icon,
    options: {
      showMenu: canRead("timeSheets"),
      label: "Time sheets",
      group: "Employee",
    },
    ...props,
  };
};

export default TimeSheet