import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Checkbox,
  IconButton
} from "@material-ui/core";
import clsx from 'clsx';
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from '@material-ui/core/styles';
import { AddressInput } from "../../Sales/Address/AddressSelector"
import { AccountInput } from "../../Sales/Account/AccountSelector"
import { TruckLoad } from "../../Logistics/Returnable/TruckLoad"
import { useUpdate, TextInput } from "react-admin";
import LiveForm from "../../../components/LiveForm"
import TimeInput from "../../../components/TimeInput";
import ZoneSelector from "../../../components/ZoneSelector";
import CheckboxInput from "../../../components/CheckboxInput";

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)"
  }),
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 10,
    alignItems: 'flex-end',
    '& .MuiFormHelperText-root': { display: 'none' },
    justifyContent: 'flex-start',
    '&>div': { flexBasis: '30%', width: '30%' }
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  stopList: {
    height: 50,
    transition: 'height .6s ease;',
    overflow: 'hidden',
    '&.selected': { height: 195 },
    '&.confirmed': {
      '& .MuiButton-label': {
        color: '#0080ff'
      }
    },

  },
  selected: {}
}));

const StopForm = ({ record, onItemChanged, selected, onDelete }) => {

  const classes = useStyles();
  let beforeSave = (record, props) => {
    delete props.Account;
    delete props.Address;
    delete props.marker;
  }
  let afterSave = (record, props) => {
    if (props.accountId || props.confirmed)
      onItemChanged(record);
  }

  return <LiveForm {...{ record, beforeSave, afterSave }} resourceName="stops"  >
    <div className={classes.spaceBetween}>
      <AccountInput disabled={!selected} source="accountId" showSelector={record.showSelector} />
      {selected && <IconButton
        onClick={() => { onDelete(selected); }}
      ><DeleteIcon /> </IconButton>}
    </div>

    {selected && <>
      <AddressInput filter={{ accountId: record.accountId }} source="addressId" />
      <div className={classes.container}>
        <TimeInput
          label="before"
          source="before"
          inputProps={{
            step: 15 * 60, // 15 min
          }}
        />
        <TimeInput
          label="after"
          source="after"
          inputProps={{
            step: 15 * 60, // 15 min
          }}
        />
        <div style={{ flex: 1 }}></div>
        <CheckboxInput source="confirmed" />
      </div>
      <div className={classes.container}>
        <TruckLoad source="unload" /> <TruckLoad load source="load" /> <ZoneSelector source="zones" />
      </div>

    </>}
  </LiveForm >

}

const StopListItem = ({
  item: orgItem,
  index,
  selected,
  setSelected,
  isDragDisabled,
  onDelete
}) => {

  const [item, setItem] = useState(orgItem)
  const classes = useStyles();

  let onItemChanged = async (record) => {
    if (record) {
      setItem(record);
    }
  }

  return (
    <Draggable
      key={item.id}
      draggableId={`${item.id}`}
      isDragDisabled={isDragDisabled}
      index={index}
    >
      {(provided, snapshot) => (
        <ListItem
          selected={selected}
          ContainerComponent="li"
          className={clsx(classes.stopList, {
            'selected': selected,
            'confirmed': item.confirmed
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => setSelected(index)}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItemText
            primary={<StopForm record={item} {...{ selected, onDelete, onItemChanged }} />}
          />

        </ListItem>
      )
      }
    </Draggable >
  );
};
export default StopListItem;
