// in src/Menu.js
import React, { createElement, useState } from "react";
import { useSelector } from "react-redux";
import {
  useMediaQuery,
  ListItem,
  ListItemText,
  List,
  Collapse,
} from "@material-ui/core";

import { ExpandMore, Home } from "@material-ui/icons";

import { MenuItemLink, getResources } from "react-admin";
import { withRouter } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    active: {
      backgroundColor: "#eaeaea",
    },
  });

const MenuPlain = ({ onMenuClick, resources, open }) => {
  return (
    <div>
      {resources.map(({ icon, name, options: { label } = {} }) => {
        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={label || name}
            leftIcon={icon ? createElement(icon) : null}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
    </div>
  );
};

const MenuGroup = ({
  items,
  text,
  onMenuClick,
  open,
  active,
  setActive,
  classes,
}) => {
  let expand = active === text;
  let className = expand ? classes.active : null;
  return (
    <div {...{ className }}>
      <ListItem button onClick={() => setActive(text)}>
        <ListItemText primary={open ? text : ""} />
        {!expand ? createElement(items[0].icon) : <ExpandMore />}
      </ListItem>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ icon, name, options: { label } = {} }) => {
            return (
              <MenuItemLink
                key={name}
                to={`/${name}`}
                primaryText={label || name}
                leftIcon={icon ? createElement(icon) : null}
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};
const StyledMenuGroup = withStyles(styles)(MenuGroup);
const MenuGrouped = ({ resources, ...props }) => {
  let groRed = resources.reduce((acc, r, idx) => {
    acc[r.options.group] = idx
    return acc;
  }, {})

  let groups = Object.keys(groRed).map((groupName) => {
    return { text: groupName, items: [], idx: groRed[groupName] }
  }).sort((a, b) => a.idx > b.idx);

  let [active, setActive] = useState(groups[0].text);

  resources.forEach((resource) => {
    let found = false;
    groups.forEach((group) => {
      if (group.text === resource.options.group) {
        found = true;
        group.items.push(resource);
      }
    });
    if (!found) groups[groups.length - 1].items.push(resource);
  });
  return (
    <>
      {groups.map((group) =>
        group.items.length ? (
          <StyledMenuGroup
            key={group.text}
            {...group}
            {...{ active, setActive, ...props }}
          />
        ) : null
      )}
    </>
  );
};

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources)
    .filter((e) => !e.options.hide)
    .filter((e) => e.options.showMenu !== false);

  return (
    <>
      <MenuItemLink
        key={"Home"}
        to={``}
        primaryText={"Home"}
        leftIcon={createElement(Home)}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources.length > 7 ? (
        <MenuGrouped {...{ onMenuClick, resources, open }} />
      ) : (
          <MenuPlain {...{ onMenuClick, resources, open }} />
        )}
      {isXSmall && logout}
    </>
  );
};

export default withRouter(Menu);
