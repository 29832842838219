import React, { useState, useEffect, useCallback } from "react";
import { useVersion, useDataProvider, useGetList } from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import FullView from "../../components/FullView";
import Calvin from "./Calvin";
import Turnover from "./Turnover";
import Account from "./Account";
import Egagement from "./Egagement";
import Birthdays from "./Birthdays";
import Margin from "./Margin";
import Meals from "./Meals";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const VerticalSpacer = () => <div style={{ height: "1em" }} />;

const Dashboard = ({ permissions }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let { data, loading } = useGetList("calvins");
  let state = loading ? {} : data['1']
  const {
    calvin,
    wildlife,
    lastWeeksNumbers,
    engagement,
    lastWeeksMargin,
    birthdays,
    menuList,
  } = state;

  return (
    <FullView>
      <div style={{ padding: 5 }}>
        {isSmall ? ( //MOBILE!
          <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
              <Calvin url={calvin} />
              <VerticalSpacer />
              {lastWeeksNumbers ? <Turnover data={state} /> : null}
              <VerticalSpacer />
              {lastWeeksMargin ? <Margin data={lastWeeksMargin} /> : null}
              <VerticalSpacer />
              {lastWeeksNumbers ? <Account data={state} /> : null}
              <VerticalSpacer />
              {lastWeeksNumbers ? <Egagement data={engagement} /> : null}
              <VerticalSpacer />
              {birthdays ? <Birthdays data={birthdays} /> : null}

              <VerticalSpacer />
              <Calvin url={wildlife} />
              <VerticalSpacer />
              {/* <Meals data={menuList} /> */}
            </div>
          </div>
        ) : (
            <>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <div style={styles.singleCol}>
                    {lastWeeksNumbers ? <Account data={state} /> : null}
                    <VerticalSpacer />
                    <Egagement data={engagement} />
                    <VerticalSpacer />
                    {/* <Meals data={menuList} /> */}
                    <VerticalSpacer />
                    <Calvin url={wildlife} />

                  </div>
                </div>
                <div style={styles.rightCol}>
                  <div style={styles.singleCol}>
                    <Calvin url={calvin} />
                    <VerticalSpacer />
                    {lastWeeksNumbers ? <Turnover data={state} /> : null}
                    <VerticalSpacer />
                    {lastWeeksMargin ? <Margin data={lastWeeksMargin} /> : null}
                    <VerticalSpacer />
                    {birthdays ? <Birthdays data={birthdays} /> : null}

                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </FullView>
  );
};

export default Dashboard;
