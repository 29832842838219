import React, { useState } from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  Cell,
  Sector,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const defaultShapeText = ({ value, percent }) => {
  return {
    text: value,
    subText: `(Rate ${(percent * 100).toFixed(0)}%)`,
  };
};
const useStyles = makeStyles((theme) => ({
  pie: {
    "& .recharts-pie-sector": {
      cursor: "pointer",
    },
  },
}));

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    shapeTextParser,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,

    ...rest
  } = props;
  let { text, subText } = shapeTextParser(rest);

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
      >{`${text}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {subText}
      </text>
    </g>
  );
};

const FancyPie = ({
  data,
  title,
  subTitle,
  label,
  shapeTextParser = defaultShapeText,
  pallet = COLORS,
  onClick,
}) => {
  let styles = useStyles();
  let [activeIndex, setActiveIndex] = useState();
  if (!data) return null;
  const labelRender = (entry) => {
    return activeIndex === entry.index ? "" : `${entry.name}`;
  };

  return (
    <Card>
      <CardHeader title={title} subheader={subTitle} />
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <PieChart className={styles.pie}>
              <Pie
                activeIndex={activeIndex}
                activeShape={(props) =>
                  renderActiveShape({ ...props, shapeTextParser })
                }
                data={data}
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                onClick={onClick}
                isAnimationActive={false}
                label={labelRender}
                onMouseEnter={(d, index) => setActiveIndex(index)}
                onMouseLeave={(d, index) => setActiveIndex(-1)}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={pallet[index % pallet.length]}
                    onClick={() => {}}
                  />
                ))}
                {activeIndex >= 0 ? null : (
                  <Label value={label} position="center" />
                )}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default FancyPie;
