import * as React from "react";
import { useHistory } from "react-router-dom";
import FancyPie from "./FancyPie";

const Margin = ({ data }) => {
  let router = useHistory();
  if (!data) return null;
  let total = 0;
  let totalPerc = data.reduce((acc, part) => {
    return acc + part.turnoverPercent;
  }, 0);

  let pieData = data.map((m) => {
    total += m.margin * m.turnoverPercent * (1 / totalPerc);
    return {
      name: m.zoneName,
      value: parseInt(m.turnoverPercent * 1000) / 10.0,
      margin: m.margin,
    };
  });

  const defaultShapeText = ({ value, margin }) => {
    return {
      text: `${value.toFixed(1)}% turnover`,
      subText: `margin ${margin.toFixed(1)}%`,
    };
  };

  return (
    <FancyPie
      title={"Margin previous week"}
      pallet={["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"]}
      data={pieData}
      onClick={(props) => {
        let filter = JSON.stringify({
          zoneName: props.payload.name,
          groupBy: ["Product"],
          week: "lastWeek",
        });
        router.push(
          `/topDowns?order=DESC&sort=revenue&filter=${encodeURI(filter)}`
        );
      }}
      shapeTextParser={defaultShapeText}
      label={`Margin ${total.toFixed(1)}%`}
      subTitle={`The power of a fair share`}
    />
  );
};

export default Margin;
