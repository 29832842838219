import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./layout/Configuration";
import NewUser from "./views/Employees/UserEditForm";
import { usePermissions } from "react-admin";
import ImportProds from "./views/Shop/Import";
import Sankey from "./views/Dashboard/Sankey";

const Config = () => {
  let { permissions } = usePermissions();
  return permissions ? (
    <Configuration userId={permissions.user.id} permissions={permissions} />
  ) : null;
};
let def = [
  <Route exact path="/configuration" render={() => <Config />} />,
  <Route exact path="/newUser" render={() => <NewUser />} />,
  <Route exact path="/products_import" render={() => <ImportProds />} />,
  <Route exact path="/sankey" render={() => <Sankey />} />,
];


export default def;