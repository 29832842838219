import config from "../config";
import React, { createContext } from "react";

var W3CWebSocket = require("websocket").w3cwebsocket;

let wsUrl = config.API.replace("http", "ws");
const eventListeners = [];
let client;

let reconnect = () => {
  if (client && client.readyState !== W3CWebSocket.CLOSED) {
    client.close();
  }

  client = new W3CWebSocket(wsUrl, "echo-protocol");

  client.onerror = function () {
    console.log("Connection Error");
    client.close();
  };

  client.onopen = function () {
    console.log("client Connected");
    sendEvent({ type: "login" });
  };

  client.onclose = function () {
    console.log("client Closed");
  };

  client.onmessage = function (e) {
    if (typeof e.data === "string") {
      let event = JSON.parse(e.data);

      let valid = eventListeners
        .filter(({ type }) => { return type == event.type })
      valid.forEach(({ callback }) => callback(event.data));
    };
  };
}

setTimeout(() => ping(), 5000);

const ping = () => {
  const token = localStorage.getItem("token");
  if (!token) return;

  if (!client || client.readyState === W3CWebSocket.CLOSED) reconnect();
  else {
    client.send("ping");
  }

  setTimeout(() => ping(), 5000);
};

const sendEvent = ({ type, data }) => {
  const token = localStorage.getItem("token");
  if (client.readyState === client.OPEN) {
    let event = {
      type,
      data,
      token,
    };
    client.send(JSON.stringify(event));
  }
};

export const wsListen = (type, callback) => {
  eventListeners.push({ type, callback });
};

export const WSContext = createContext({
  client: null,
  listen: (type, callBack) => { },
  send: (props) => { },
});

export const WSContextProvider = ({ children }) => {
  return (
    <WSContext.Provider value={{ client, listen: wsListen, send: sendEvent }}>
      {children}
    </WSContext.Provider>
  );
};

export default client;
