import React from "react";
import icon from "@material-ui/icons/People";
import { JsonInput } from "react-admin-json-view";
import {
  guardListAccess,
  guardEditAccess,
} from "../../util/Guard";

import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  Filter,
  WithPermissions,
  FilterLiveSearch,
} from "react-admin";


const Filters = (props) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch label="User" alwaysOn={true} />
    </Filter>
  );
};

//custom textField that doesnst render the word "null"
const TextField = ({ record, source }) => {
  return <>{record[source]}</>;
};


export const CompanyEdit = ({
  permissions: { authenticated, is },
  ...props
}) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <JsonInput
          jsonString={true}
          source="props"
          label="props json"
          options={{ multiline: true, rows: 10, columns: 25 }}
          fullWidth
        />
        <TextInput source="pincode" />
      </SimpleForm>
    </Edit>
  );
};

const CompanyEdithWitPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <CompanyEdit permissions={permissions} {...props} />;
    }}
  />
);

export const CompanyList = ({
  permissions: { authenticated, is },
  ...props
}) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<Filters />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

const CompanyListWithPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <CompanyList permissions={permissions} {...props} />;
    }}
  />
);

const def = ({ canRead, is, ...props }) => {
  return {
    name: "companies",
    list: guardListAccess(CompanyListWithPerms),
    edit: guardEditAccess(CompanyEdithWitPerms),
    // create: guardCreateAccess(UserCreate),
    icon,
    options: {
      group: "Misc",
      label: "Companies",
      showMenu: is("admin"),
    },
    ...props,
  };
};

export default def;