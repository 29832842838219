import React, { cloneElement } from "react";
import querystring from "query-string";

import { TopToolbar, sanitizeListRestProps, Button } from "react-admin";
import { MenuItem, Menu } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { download } from "../../../util/HttpClient";

export const AccountActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let onClick = (type) => {
    let filter = { ...filterValues, ...permanentFilter };
    download(`accounts/export?type=${type}&${querystring.encode(filter)}`);
    setAnchorEl(null);
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
        <GetAppIcon />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={(e) => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={(e) => onClick("AccountList/AccountList-main")}>
          Nursering
        </MenuItem>
      </Menu>
    </TopToolbar>
  );
};
export default AccountActions;
