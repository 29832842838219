import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import icon from "@material-ui/icons/People";
import TabPanel from "../../components/TabPanel";
import EditableGrid from "../../components/EditableGrid";
import moment from "moment";

import {
  guardListAccess,
  guardCreateAccess,
  guardEditAccess,
} from "../../util/Guard";
import httpClient from "../../util/HttpClient";

import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  Filter,
  Create,
  WithPermissions,
  ReferenceField,

  FunctionField,
  useGetList,
  FilterLiveSearch,
} from "react-admin";

import { Button } from "@material-ui/core/";
import { UserEditForm } from "./UserEditForm";

const Filters = (props) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch label="User" alwaysOn={true} />
    </Filter>
  );
};

//custom textField that doesnst render the word "null"
const TextField = ({ record, source }) => {
  return <>{record[source]}</>;
};

const updateRole = (record) => {
  return httpClient(`userRoles/1`, {
    method: "PUT",
    body: JSON.stringify(record),
  });
};
const updateTask = (record) => {
  return httpClient(`userTasks/1`, {
    method: "PUT",
    body: JSON.stringify(record),
  });
};

const lonOnAs = (id) => {
  return httpClient(`users/${id}/resetToken`).then((e) => {
    localStorage.setItem("prevUser", localStorage.getItem("token"));
    document.location.href = e.json;
  });
};
const GrantField = ({ record, setRecord, source, restCall, ...rest }) => {
  let change = (props) => {
    let newRecord = { ...record, [source]: !record[source] };
    setRecord(newRecord);
    restCall(newRecord);
  };

  return <Checkbox checked={record[source]} isInput={true} onChange={change} />;
};
const Permissions = ({ id, record, ...props }) => {
  const { data, ids } = useGetList(
    "userRoles",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { userId: id }
  );
  const { data: tasks, ids: taskIds } = useGetList(
    "userTasks",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { userId: id, companyId: record.companyId }
  );

  return (
    <TabPanel>
      <EditableGrid
        label="Role"
        data={data}
        currentSort="id"
        selectedIds={[]}
        ids={ids}
      >
        <TextField source="name" />
        <GrantField
          label="hasRole"
          source="hasRole"
          isInput={true}
          restCall={updateRole}
        />
      </EditableGrid>

      <EditableGrid
        label="Taks"
        data={tasks}
        currentSort="id"
        selectedIds={[]}
        ids={taskIds}
      >
        <TextField source="name" />
        <GrantField
          source="hasTask"
          label="hasTask"
          isInput={true}
          restCall={updateTask}
        />
      </EditableGrid>
    </TabPanel>
  );
};

export const UserEdit = ({ permissions: { user, authenticated, is }, ...props }) => {

  let id = is(['admin', 'hr-manager']) ? props.id : user.id;

  return (
    <>
      <Button
        value="Logon as.."
        onClick={() => {
          lonOnAs(id);
        }}
      >
        Logon as..
      </Button>
      <Edit  {...{ ...props, id }}>
        <UserEditForm />
      </Edit>
      <Edit  {...{ ...props, id }}>
        <Permissions {...props} />
      </Edit>
    </>
  );
};

const UserEdithWitPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <UserEdit permissions={permissions} {...props} />;
    }}
  />
);
export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="middleName" />
      <TextInput source="email" />
      <TextInput source="phone" />
    </SimpleForm>
  </Create>
);
export const UserList = ({ permissions: { authenticated, is }, ...props }) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<Filters />}>
      <Datagrid rowClick="edit">
        <TextField source="firstName" />
        <FunctionField render={record => record.birthday ? `${moment().diff(record.birthday, 'years')}` : ''} />
        <TextField source="lastName" />
        <TextField source="middleName" />
        <TextField source="phone" />

        {authenticated && is(["admin"]) && (
          <ReferenceField
            label="Company"
            source="companyId"
            reference="companies"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  );
};

const UserListWithPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <UserList permissions={permissions} {...props} />;
    }}
  />
);

const User = ({ is, ...props }) => {
  return {
    name: "users",
    list: guardListAccess(UserListWithPerms),
    edit: guardEditAccess(UserEdithWitPerms),
    create: guardCreateAccess(UserCreate),
    icon,
    options: {
      group: "Employee",
      label: "Users",
      showMenu: is(["manager", "hr-manager", "admin"]),
    },
    ...props,
  };
};

export default User
