import React, { useState, useEffect } from "react";
import httpClient from "../../util/HttpClient";

import icon from "@material-ui/icons/FavoriteBorder";
import FunProgramInput from "../../components/FunProgramInput";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  Create,
} from "react-admin";

import { guardListAccess } from "../../util/Guard";

const CreateForm = (props) => {
  let [defaults, setDefaults] = useState();
  useEffect(() => {
    if (!defaults)
      httpClient(`rides/defaults/get`).then(({ json }) => setDefaults(json));
  }, [defaults]);
  return defaults ? (
    <Create {...props}>
      <Form {...{ defaults }} />
    </Create>
  ) : null;
};

const MyGrid = (props) => {
  return (
    <Datagrid {...props} rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  );
};

const Form = ({ defaults, ...props }) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <FunProgramInput source="program" />
    </SimpleForm>
  );
};
const EditForm = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

const ProgramList = ({ permissions: { canRead }, ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <MyGrid />
    </List>
  );
};

export default ({ canRead, ...props }) => {
  return {
    name: "funPrograms",
    list: guardListAccess(ProgramList),
    create: CreateForm,
    edit: EditForm,
    icon,
    options: {
      showMenu: canRead("funPrograms"),
      label: "Fun programs",
    },
    ...props,
  };
};
