import React from "react";

import { guardListAccess } from "../../../util/Guard";
import { List, Filter } from "react-admin";
import Xax from "../../../components/XAxis";

import icon from "@material-ui/icons/TrendingUp";
import ClearableReferenceInput from "../../../components/ClearableReferenceInput";

import {
  LineChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Line,
  Legend,
} from "recharts";

const Filters = (props) => {
  return (
    <Filter {...props}>
      <ClearableReferenceInput
        label="Product"
        source="productId"
        optionValue="id"
        filter={
          props.filterValues.producerId
            ? { producerId: props.filterValues.producerId }
            : null
        }
        reference="products"
        alwaysOn={true}
        allowEmpty
      ></ClearableReferenceInput>
      <ClearableReferenceInput
        label="Producer"
        source="producerId"
        optionValue="id"
        reference="producers"
        alwaysOn={true}
        allowEmpty
      ></ClearableReferenceInput>
      <ClearableReferenceInput
        label="Pickuppoint"
        source="dropOffId"
        optionValue="id"
        reference="dropOffs"
        alwaysOn={true}
        allowEmpty
      ></ClearableReferenceInput>
    </Filter>
  );
};
const formatXAxis = (tickItem) => {
  tickItem = tickItem.toString();
  return `${tickItem.substring(0, 4)} \\ ${tickItem.substring(4)} `;
};

let Chart = ({ loading, data, ids, filterValues }) => {
  if (loading) return null;
  let tData = Object.keys(data).map((key) => data[key]);
  tData.pop();
  tData.pop();

  return (
    <LineChart
      width={1200}
      height={500}
      data={tData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        height={60}
        dataKey="id"
        tick={<Xax tickFormatter={formatXAxis} />}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="turnoverEx" stroke="#8884d8" />
      <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
    </LineChart>
  );
};

let Trendy = (props) => {
  return (
    <List filters={<Filters />} pagination={<></>} {...props}>
      <Chart />
    </List>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "trends",
    list: guardListAccess(Trendy),
    icon,
    options: {
      label: "Trendy",
      group: "Misc",
      showMenu: canRead("trends"),
    },
  };
};
export default resource;
