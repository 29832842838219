import React from "react";
import { BoxSelector } from "../../../components/BoxSelector";
import { AutoRefInput } from "../../../components/AutoRefInput";
import icon from "@material-ui/icons/AccountTree";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

import moment from "moment";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  NumberInput,
  Create,
  FunctionField,
  BooleanInput,
  NumberField,
  BooleanField,
  useRecordContext
} from "react-admin";
import {
  guardListAccess,
  guardCreateAccess,
  guardEditAccess,
} from "../../../util/Guard";
import BoxContentFilter from "./BoxContentFilter";
import BoxContentsActions from "./BoxContentsActions";




export const BoxContentsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BoxSelector source="productId" />
      <AutoRefInput reference="weeks" source="weekId" optionValue="yearWeek" />

      <TextInput source="name" />
      <TextInput source="quantity" />
      <TextInput source="price" />
      <TextInput source="unit" />
      <BooleanInput source="confirmed" />
      <TextInput source="supplier" />
    </SimpleForm>
  </Create>
);

export const BoxContentsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box fullWidth display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="name" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <NumberInput locales="nl" source="quantity" fullWidth />


        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="unit" fullWidth />

        </Box>
      </Box>
      <TextInput
        source="memo"
        label="Memo"
        options={{ multiline: true, rows: 2, columns: 25 }}
        fullWidth
      />
      <TextInput
        source="pickInstruction"
        label="Pick instruction"
        options={{ multiline: true, rows: 2, columns: 25 }}
        fullWidth
      />

      <TextInput source="supplier" />
      <NumberInput locales="nl" source="price" label="Unit price" />
      <BooleanInput source="confirmed" />

    </SimpleForm>
  </Edit>
);

let toDecimal = (q) => {
  return q.toLocaleString("nl", { maximumSignificantDigits: 3 });
};
let SumGrid = ({ data, ids, ...props }) => {
  let newData = { ...data };
  let newIds = [...ids, "totalRow"];

  if (ids.length > 1) {
    let totalRow = {
      id: "totalRow",
      name: "TOTAAL",
      total: 0,
    };
    ids.map((id) => {
      data[id].total = data[id].price * data[id].quantity;
      return (totalRow.total += data[id].price * data[id].quantity);
    });

    newData.totalRow = totalRow;
  }

  return (
    <Datagrid data={newData} ids={newIds} {...props}>
      <TextField source="name" />
      <NumberField locales="nl" source="quantity" />
      <TextField source="unit" />
      <BooleanField source="confirmed" />
      <TextField source="supplier" />
      <NumberField options={{ style: 'currency', currency: 'EUR' }} locales="nl" source="price" label="Unit price" />

      <NumberField options={{ style: 'currency', currency: 'EUR' }} locales="nl" source="total" />
      <FunctionField
        label="Amount sold"
        render={(record) => {
          return record.soldSoFar
            ? `${toDecimal(record.soldSoFar * record.quantity)} ${record.unit} `
            : "";
        }}
      />
    </Datagrid>
  );
};
export const BoxContentsList = (props) => {
  return (
    <List
      {...props}
      filters={<BoxContentFilter />}
      filterDefaultValues={{
        week: new moment().add(7, "day").format("YYYYww"),
      }}
      actions={<BoxContentsActions />}
      pagination={<></>}
    >
      <SumGrid rowClick="edit" />
    </List>
  );
};

const def = ({ canRead, ...props }) => {
  return {
    name: "boxContents",
    list: guardListAccess(BoxContentsList),
    edit: guardEditAccess(BoxContentsEdit),
    create: guardCreateAccess(BoxContentsCreate),
    icon,
    options: {
      showMenu: canRead("boxContents"),
      group: "Product",
      label: "Box contents",
    },
    ...props,
  };
};
export default def;