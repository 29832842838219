import React from "react";
import FileCopy from "@material-ui/icons/FileCopy";
import SideSelectorInput from "../../../components/SideSelectorInput"

const renderFunc = (record) => {
  if (!record) return 'copy'
  return <>{record.name}</>
}


export const BoxSelector = (props) => {
  return <SideSelectorInput {...{
    ...props,
    renderFunc,
    filter: { deliverable: 1 },
    resource: 'products',
    btnText: 'copy',
    startIcon: <FileCopy />
  }} />
};

export default BoxSelector;
