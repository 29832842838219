import React from "react";
import { Route, Link, withRouter } from "react-router-dom";

const NavLink = ({ to, location, children }) => (
  <Route
    path={to}
    children={({ match }) => (
      <Link replace={to === location.pathname} to={to}>
        {children}
      </Link>
    )}
  />
);

export default withRouter(NavLink);
