import React from "react";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import { Button } from "react-admin";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    field: {
      // These styles will ensure our drawer don't fully cover our
      // application when teaser or title are very long
      "& span": {
        display: "inline-block",
        maxWidth: "20em",
      },
    },
  }),
  { name: "RaDeleteWithUndoButton" }
);

const DeleteButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      label={"Delete"}
      className={classnames("ra-delete-button", classes.deleteButton)}
      key="button"
      {...props}
    >
      <ActionDelete />
    </Button>
  );
};
export default DeleteButton;
