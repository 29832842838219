import React, { useState, useEffect } from "react";
import httpClient from "../../util/HttpClient";
import { DateTimeInput } from "../../components/DateTime";
import icon from "@material-ui/icons/DriveEta";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  DateField,
  NumberInput,
  ReferenceField,
  NumberField,
  Create,
} from "react-admin";

import { guardListAccess } from "../../util/Guard";

const CreateForm = (props) => {
  let [defaults, setDefaults] = useState();
  useEffect(() => {
    if (!defaults)
      httpClient(`rides/defaults/get`).then(({ json }) => setDefaults(json));
  }, [defaults]);
  return defaults ? (
    <Create {...props}>
      <Form {...{ defaults }} />
    </Create>
  ) : null;
};

const Form = ({ defaults, ...props }) => {
  return (
    <SimpleForm {...props}>
      <DateTimeInput source="date" />
      <NumberInput
        source="milesStart"
        label="KM start"
        defaultValue={defaults ? defaults.milesStart : 0}
      />
      <NumberInput source="milesEnd" label="KM end" />
      <TextInput source="misc" label="purpose" />
      <TextInput
        source="vanId"
        label="license plate"
        defaultValue={defaults ? defaults.vanId : 0}
      />
    </SimpleForm>
  );
};
const EditForm = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

const RideList = ({ permissions: { canRead }, ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <DateField source="date" />

        <TextField source="misc" />
        <NumberField source="milesEnd" />
        {canRead("users") ? (
          <ReferenceField label="User" source="userId" reference="users">
            <TextField source="firstName" />
          </ReferenceField>
        ) : null}
      </Datagrid>
    </List>
  );
};
const def = ({ canRead, ...props }) => {
  return {
    name: "rides",
    list: guardListAccess(RideList),
    create: CreateForm,
    edit: EditForm,
    icon,
    options: {
      showMenu: canRead("rides"),
      label: "Rides",
    },
    ...props,
  };
};

export default def;