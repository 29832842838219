import React from "react";
import moment from "moment";
import icon from "@material-ui/icons/Schedule";

import EditableGrid from "../../components/EditableGrid";

import { List, FunctionField, TextField } from "react-admin";

const list = (props) => {
  return (
    <List
      bulkActionButtons={false}
      pagination={<></>}
      {...props}
      filterDefaultValues={{ planDay: new Date() }}
    >
      <EditableGrid rowClick="edit">
        <FunctionField
          label="Day"
          render={(record) =>
            `${new moment(record.dtstart).format(
              "ddd D MMM HH:mm"
            )}-${new moment(record.dtend).format("HH:mm")}`
          }
        />

        <TextField source="position" />
      </EditableGrid>
    </List>
  );
};
const Planning = ({ canRead, ...props }) => {
  return {
    name: "plannings",
    list,
    icon,
    options: {
      showMenu: canRead("plannings"),
      group: "Employee",
      label: "Planning",
    },
    ...props,
  };
};
export default Planning