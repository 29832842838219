import React, { useState } from "react";
import RichTextInput from "ra-input-rich-text";
import {
  required,
  Button,
  SaveButton,
  DeleteButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useInput,
  useUpdate,
  ResourceContextProvider,
} from "react-admin";

import {
  Cancel as IconCancel,
  AddIcCall,
  CallMade,
  CallReceived,
  Edit as EditIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles({
  message: {
    "& .ra-rich-text-input": {
      height: "200px",
    },
  },
});

const MessageType = (props) => {
  const { input } = useInput(props);

  return (
    <Select className="MuiFormControl-fullWidth" {...input}>
      <MenuItem value="1">
        <ListItemIcon>
          <CallMade />
        </ListItemIcon>
        <ListItemText primary="Outbound" />
      </MenuItem>
      <MenuItem value="-1">
        <ListItemIcon>
          <CallReceived />
        </ListItemIcon>
        <ListItemText primary="Inbound" />
      </MenuItem>
    </Select>
  );
};

const MessageButton = ({
  accountId,
  label,
  record = {},
  update: updateAside,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const [create, { loading: createLoading }] = useCreate("messages");
  const [update, { loading: editLoading }] = useUpdate("messages", record.id);
  let action = create;
  let loading = createLoading;

  if (record.id) {
    action = update;
    loading = editLoading;
  }
  const notify = useNotify();

  const classes = useStyles();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    action(
      { payload: { data: { accountId, ...record, ...values } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          updateAside();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label={label}>
        {record.id ? <EditIcon /> : <AddIcCall />}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create message"
      >
        <DialogTitle>Create phone log</DialogTitle>

        <FormWithRedirect
          resource="messages"
          save={handleSubmit}
          record={record}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent className={classes.message}>
                <MessageType
                  source="direction"
                  fullWidth
                  validate={required()}
                />

                <RichTextInput source="text" validate={required()} fullWidth />
              </DialogContent>
              <DialogActions>
                <ResourceContextProvider value="messages">
                  {new moment().diff(record.createdAt, "days") === 0 ? (
                    <DeleteButton
                      record={record}
                      resource="messages"
                      redirect={false}
                    />
                  ) : null}
                </ResourceContextProvider>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default MessageButton;
