import React, { useRef } from "react";
import icon from "@material-ui/icons/People";
import IconContentAdd from "@material-ui/icons/DirectionsRun";
import api from "../../util/HttpClient";
import { JsonInput } from "react-admin-json-view";
import { Box } from "@material-ui/core";
import qs from "query-string";

import {
  guardListAccess,
  guardCreateAccess,
  guardEditAccess,
} from "../../util/Guard";
import DaySelector, { daysRenderer } from "../../components/DaySelector";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Datagrid,
  DateTimeInput,
  NumberInput,
  TextField,
  SelectInput,
  List,
  FunctionField,
  Button
} from "react-admin";
import FancyTime from "../../components/FancyTime";

export const TaskForm = ({ record, ...props }) => {
  let ref = useRef();
  let runManual = () => {
    let queryString = qs.stringify({
      runManual: record.id,
      props: JSON.stringify(ref.current.props.src)
    });

    api(`resource/budb/HeartBeat?${queryString}`)
  }
  return <SimpleForm {...{ record, ...props }}>
    <TextInput source="name" />
    <DaySelector source="days" />
    <Box display={{ md: "block", lg: "flex" }}>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <DateTimeInput source="nextRun" />
      </Box>
      <Button onClick={runManual} label="Manual"><IconContentAdd /></Button>
    </Box>

    <Box display={{ md: "block", lg: "flex" }}>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="interval" />
      </Box>
      <SelectInput source="frequency" choices={[
        { id: 'minutes', name: 'minutes' },
        { id: 'hours', name: 'hours' },
        { id: 'days', name: 'days' },
        { id: 'weeks', name: 'weeks' },
        { id: 'months', name: 'months' },
      ]} />
    </Box>
    <JsonInput ref={ref}
      reactJsonOptions={
        { ref }
      }
      jsonString={true} source="defaultProps" />
    <TextInput
      source="result"

      options={{ multiline: true, rows: 10, columns: 25 }}
      fullWidth
    />

  </SimpleForm>
}

export const SystemTaskEdit = (props) => {
  return (
    <Edit {...props}>
      <TaskForm />
    </Edit>
  );
};
export const SystemTaskCreate = (props) => {
  return (
    <Create {...props}>
      <TaskForm />
    </Create>
  );
};

export const SystemTaskList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} >
      <Datagrid rowClick="edit">
        <TextField source="name" />

        <FancyTime source="nextRun" />
        <FunctionField source="days" render={daysRenderer} />
      </Datagrid>
    </List >
  );
};


const def = ({ canRead, is, ...props }) => {
  return {
    name: "systemTasks",
    list: guardListAccess(SystemTaskList),
    edit: guardEditAccess(SystemTaskEdit),
    create: guardCreateAccess(SystemTaskCreate),
    icon,
    options: {
      group: "Misc",
      label: "SystemTasks",
      showMenu: is("admin"),
    },
    ...props,
  };
};
export default def;