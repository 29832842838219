import * as React from "react";
import { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import AvatarField from "./AvatarField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const FullNameField = ({ record, size }) => {
  const classes = useStyles();
  return record ? (
    <span className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {record.lastName ? `${record.firstName}, ${record.lastName} ${record.middleName ? record.middleName : ''}` :
        `${record.name}`
      }
    </span>
  ) : null;
};

export default memo(FullNameField);
