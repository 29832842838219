import icon from "@material-ui/icons/Face";
import { guardListAccess, guardEditAccess } from "../../../util/Guard";
import AccountList from "./AccountList";
import AccountEdit from "./AccountEdit";
import {

  SimpleForm,
  TextInput,
  Create,
} from "react-admin";

const create = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />

    </SimpleForm>
  </Create>
);

export default ({ canRead, is, ...props }) => {
  return {
    name: "accounts",
    list: guardListAccess(AccountList),
    edit: guardEditAccess(AccountEdit),
    create,
    icon,
    options: {
      group: "Account",
      label: "Accounts",
      showMenu: is(["manager", "contact-support", "route-planner", "admin"]),
    },
    ...props,
  };
};
