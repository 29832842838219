import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import httpClient from "../../../util/HttpClient";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import CallIcon from "@material-ui/icons/Call";
import SaveIcon from '@material-ui/icons/Save';
import {
  FilledInput,
  Typography,
  IconButton,
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from '@material-ui/icons/Send';
import InputIcon from "@material-ui/icons/Chat";
import { red, green } from "@material-ui/core/colors";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const useStyles = makeStyles({
  avatarGreen: {
    backgroundColor: green[100],
    color: green[600],
  },
  avatarRed: {
    backgroundColor: red[100],
    color: red[600],
  },
  input: {
    margin: 10,
  },
  phone: {
    width: "100%",
  },
});

function SMSTab({ item: orgItem, onClose }) {
  const classes = useStyles();
  let [dialing, setDialing] = useState(false);
  let [text, setText] = useState('');
  let [item, setItem] = useState(orgItem);
  let setValue = (name, val) => {
    orgItem[name] = val;
    setItem({ ...orgItem });
  };

  useEffect(() => {
    setItem(orgItem);
    setDialing(false);
  }, [orgItem])

  useEffect(() => {
    if (!orgItem) return;
    setText(`Beste ${orgItem?.firstName},
We hebben zojuist bij jou bezorgd.
Geniet van je boodschappen!
De Streekboer`);

  }, [orgItem])
  if (!item) return <></>

  let sms = () => {
    return httpClient(`SendSMS/dial`, {
      method: "PUT",
      body: JSON.stringify({ orderId: item.id, text }),
    }).then(() => {
      setDialing(false);
      onClose();
    })
  }

  return (<FormGroup>
    <TextField
      value={text}
      label="Text"
      className={classes.input}
      onChange={(e) => setText(e.target.value)}
      multiline
      variant="outlined"
      rows={4}
    />
    <Button label="Verzenden"
      endIcon={< SendIcon />}
      disabled={dialing}
      onClick={sms}
      color="secondary" >Verzenden</Button>
    <p>Geef aan welke SMS-jes de klant standaard wil ontvangen</p>
    <FormControlLabel
      control={
        <Switch
          checked={item.youAreNextStop > 0 ? true : false}
          onChange={() => setValue("youAreNextStop", !item.youAreNextStop)}
          label={"Order"}
        />
      }
      label="Jij bent volgende -SMS"
    />
    <FormControlLabel
      control={
        <Switch
          checked={item.youGotDelivery > 0 ? true : false}
          onChange={() => setValue("youGotDelivery", !item.youGotDelivery)}
          label={"Order"}
        />
      }
      label="We hebben net bezorgd -SMS"
    /> <Button label="Opslaan"
      endIcon={< SaveIcon />}
      onClick={onClose}
      color="secondary" >Opslaan</Button>

  </FormGroup>

  );
}


function CommentsEditor({ item: orgItem, onClose }) {
  const classes = useStyles();
  let [dialing, setDialing] = useState(false);
  let [item, setItem] = useState(orgItem);

  useEffect(() => {
    setItem(orgItem);
    setDialing(false);
  }, [orgItem])

  let setValue = (name, val) => {
    orgItem[name] = val;
    setItem({ ...orgItem });
  };

  if (!item) return <></>

  return (<FormGroup>
    <TextField
      value={item.acMisc}
      label="Bezorgtip"
      className={classes.input}
      onChange={(e) => setValue("acMisc", e.target.value)}
      multiline
      variant="outlined"
      rows={4}
    />
    <TextField
      value={item.orMisc}
      label="Opmerking"
      className={classes.input}
      onChange={(e) => setValue("orMisc", e.target.value)}
      multiline
      variant="outlined"
      rows={4}
    />
    <Button label="Opslaan"
      endIcon={< SaveIcon />}
      onClick={onClose}
      color="secondary" >Opslaan</Button>
  </FormGroup>

  );
}


function Dialer({ from, item, setFrom }) {
  const classes = useStyles();
  let [dialing, setDialing] = useState(false);

  const call = (props) => {
    setDialing(true);
    httpClient(`click2Dials/dial`, {
      method: "PUT",
      body: JSON.stringify(props),
    }).then((res) => {
      setTimeout(() => setDialing(false), 2500);
    });
  };
  if (!item) return <></>

  return (<> <FormGroup>

  </FormGroup>
    {
      item.phone ? (
        <div className={classes.input}>
          <Typography>
            Nummer van <b>je eigen</b> toestel
            </Typography>
          <FilledInput
            id="filled-adornment-password"
            value={from}
            variant="outlined"
            className={classes.phone}
            onChange={(e) => setFrom(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <Avatar
                  className={
                    dialing ? classes.avatarRed : classes.avatarGreen
                  }
                >
                  <IconButton
                    onClick={() => call({ from, orderId: item.id })}
                  >
                    <CallIcon />
                  </IconButton>
                </Avatar>
              </InputAdornment>
            }
          />
          <Typography>
            <b>Let op:</b> toestel opnemen! Daarna word je verbonden met de
              klant.
            </Typography>
        </div>
      ) : null
    }</>
  );
}

function MiscDialog({ item, onClose, setItem, ...props }) {
  let [activeTab, setActiveTab] = useState(0);
  return <Dialog
    onClose={onClose}
    aria-labelledby="simple-dialog-title"
    open={item ? true : false}
  >
    <DialogTitle id="simple-dialog-title">Klantinfo</DialogTitle>
    <DialogContent>
      <Tabs value={activeTab} onChange={(e, tab) => { setActiveTab(tab) }} aria-label="simple tabs example">
        <Tab label="Opmerkingen" id={0} />
        <Tab label="SMS" id={1} />
        <Tab label="Bellen" id={2} />
      </Tabs>
      {activeTab == 0 && <CommentsEditor {...{ item, onClose, ...props }} />}
      {activeTab == 1 && <SMSTab {...{ item, onClose, setItem, ...props }} />}
      {activeTab == 2 && <Dialer {...{ item, onClose, ...props }} />}
    </DialogContent>
  </Dialog>
}

export const useMiscEditor = (itemChanged) => {
  let [item, setItem] = useState();
  let [from, setFrom] = useState();

  const handleClickOpen = (item) => {
    httpClient("Click2Dials").then(({ json }) => {
      setFrom(json);
      setItem(item);
    });
  };

  const onClose = () => {
    httpClient(`deliverylists/${item.id}`, {
      method: "PUT",
      body: JSON.stringify(item),
    }).then(() => {
      setItem(null);
      itemChanged();
    });
  };

  let dialog = (
    <MiscDialog
      {...{ onClose, item, setItem, setFrom, from }}
    />
  );
  let getButton = (item) => (
    <div>
      <IconButton onClick={() => handleClickOpen(item)}>
        <InputIcon />
      </IconButton>
    </div>
  );

  return [dialog, getButton];
};
