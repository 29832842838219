import moment from "moment";
import GoogleMapReact from "google-map-react";
import MapSlider from "../../../components/MapSlider";
import React, { useState, useEffect, useRef } from "react";

let markerUrl = "https://maps.google.com/mapfiles/ms/icons/";

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 10,
};

const loadAddresses = ({ maps, map, items, onMarkerClick, reorderMode, ...props }) => {

  for (var idx = 0; idx < items.length; idx++) {
    let stop = items[idx]
    if (!stop.Address) continue;

    let lat = parseFloat(stop.Address.lat);
    let lng = parseFloat(stop.Address.lng);

    if (!stop.marker) {
      stop.marker = new maps.Marker({
        position: { lat, lng },
        map,
        data: {
          idx
        },
        icon: { url: markerUrl + "blue-dot.png" }
      });
    } else {
      stop.marker.setPosition(new maps.LatLng(lat, lng))
      maps.event.clearInstanceListeners(stop.marker);
    }

    stop.marker.addListener("click", () => {
      onMarkerClick(stop.marker.data.idx);
    });
  }
}

const updateSelected = ({ items, selected, reorderMode }) => {

  for (var idx = 0; idx < items.length; idx++) {
    let stop = items[idx];
    let marker = stop.marker;
    if (!marker) continue;

    if (idx === selected)
      marker.setIcon({ url: markerUrl + "green-dot.png" });
    else if (reorderMode && idx > selected || !reorderMode)
      if (stop && stop.time)
        marker.setIcon({ url: markerUrl + "orange-dot.png" });
      else
        marker.setIcon({ url: markerUrl + "blue-dot.png" });
    else
      marker.setIcon({ url: markerUrl + "red-dot.png" });


  }
}
let getTime = (log) => {
  return new moment(log).format('HH:mm');
}

const StopMap = ({ setSelected, selected, reorderMode, changeOrder, items, ...props }) => {
  let ref = useRef();
  let [mapInfo, setMapInfo] = useState(null)
  let onMarkerClick = (idx) => {
    if (reorderMode) {
      changeOrder(idx, selected, 1);
    }
    else setSelected(idx);
  }
  let title = "";
  if (items.length) {
    let item = items[selected];

    if (item && item.time)
      title = `${getTime(item.time)} - ${parseInt((item.duration + 30) / 60)} mins - ${parseInt(item.odo / 1000)} km`
  }
  useEffect(() => {
    if (items && mapInfo) {
      loadAddresses({ onMarkerClick, items, ...mapInfo, ...props })
      updateSelected({ selected, items, reorderMode, ...props })
    }
  }, [items, mapInfo, selected, reorderMode])

  return (
    <div style={{ height: "calc(100vh - 100px)", width: "100%" }}>
      <div ref={ref} style={{
        width: '100%',
        height: '0px',
        padding: '0px 55px',
        boxSizing: 'border-box',
      }}>
        <MapSlider
          max={items.length - 1}
          min={0}
          title={title}
          defaultValue={0}
          value={selected}
          onChange={(props, newValue) => {
            setSelected(newValue);
          }}
        />

      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        yesIWantToUseGoogleMapApiInternals

        onGoogleApiLoaded={({ map, maps, ...mapProps }) => {
          map.controls[maps.ControlPosition.BOTTOM_CENTER].push(ref.current);
          ref.current.style.height = '50px';
          setMapInfo({ map, maps, ...mapProps });
        }}
      ></GoogleMapReact>

    </div>
  );
}

export default StopMap;