import React, { useContext, useState, useEffect } from "react";
import { WSContext } from "../../../util/WSContext";
import Snackbar from "@material-ui/core/Snackbar";

import { useHistory } from "react-router-dom";
import moment from "moment"
import { Button } from "@material-ui/core";
import { Save } from "@material-ui/icons";

const SaveNotify = () => {
  const { listen } = useContext(WSContext);

  const [data, setData] = useState(null);
  const [target, setTarget] = useState(null);
  const [message, setMessage] = useState();
  const [ref, setRef] = useState();



  useEffect(() => {
    if (!target) return;
    clearInterval(ref);
    let timer = setInterval(() => {
      let diff = target.diff(new moment(), 'seconds');
      if (diff < 0) {

        clearInterval(ref);
      } else
        setMessage(`Route wordt opgeslagen over ${diff} secs`)
    }, 1000);
    setRef(timer);

  }, [target])

  useEffect(() => {
    listen("routeSaving", (data) => {
      setData(data);
      if (data.timer) {
        let target = new moment().add(data.timer, 'seconds')
        setTarget(target);
      }
      if (data.message) {
        setMessage(data.message)
      }
    });
  }, []);


  let onClick = () => {

  };

  if (message)
    return (
      <Snackbar
        open={true}
        autoHideDuration={25 * 1000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Button
          onClick={onClick}
          variant="contained"
          color="secondary"
          startIcon={<Save />}
        >
          {message}
        </Button>
      </Snackbar>
    );
  else return "";
};

export default SaveNotify;
