import React from "react";
import { useState } from "react";
import { Button, DialogActions, Dialog, DialogContent, DialogTitle, Checkbox } from "@material-ui/core";
import httpClient from "../../../util/HttpClient";
import calcDistance from "../../../util/Distance";

const noPrefStyle = { flex: 2, order: 1 };
const prefstyle = { flex: 4, order: 2, backgroundColor: '#6ab26391' }

function MiscDialog({ open, dialogText, dialogTitle, noByDefault = false, dialogReady }) {

  return (
    <Dialog
      onClose={() => dialogReady(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogText}

      </DialogContent>
      <DialogActions>
        <Button style={noByDefault ? prefstyle : noPrefStyle} onClick={() => dialogReady(false)} >Nee</Button>
        <Button style={noByDefault ? noPrefStyle : prefstyle} onClick={() => dialogReady(true)} autoFocus>
          Ja
          </Button>

      </DialogActions>
    </Dialog >
  );
}

const saveLatLon = ({ item }) => {
  return httpClient(`deliverylists/${item.id}`, {
    method: "PUT",
    body: JSON.stringify({
      lat: item.lat,
      lng: item.lng,
    }),
  });
}

const checkDistance = ({ item, dialogStart }) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      let distance = calcDistance(item.lat, item.lng, coords.latitude, coords.longitude);
      item.lat = coords.latitude;
      item.lng = coords.longitude;
      let callback = (savelocation) => {
        if (savelocation) saveLatLon({ item }).then(resolve)
        else resolve();
      }
      if (distance > 50) {
        dialogStart({
          noByDefault: true,
          dialogTitle: 'Sta je wel lekker?',
          dialogText:
            `De plek waar je nu bent wijkt (${Math.floor(distance)})m af van de plaats die opgeslagen is bij deze klant.
          Wil je deze nieuwe plaats opslaan als de juiste plaats?`, callback
        })
      } else resolve();
    },
      resolve //continue when denied
      , {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
  });
}

const sendSMS = ({ item, text }) => {
  return httpClient(`SendSMS/dial`, {
    method: "PUT",
    body: JSON.stringify({ orderId: item.id, text }),
  })
}

const checkSendCurrSms = ({ item, dialogStart }) => {
  return new Promise((resolve, reject) => {
    let callback = (doSend) => {
      if (doSend)
        return sendSMS({ item, text: 'Je bestelling bij De Streekboer is zojuist bezorgd!' }).then(resolve)
      else resolve()
    }
    dialogStart({
      dialogTitle: 'We hebben bezorgd-SMS sturen?',
      dialogText:
        `${item.firstName} wil graag een SMS bij aflevering. Deze SMS nu versturen?`, callback
    })
  })
}

const checkSendNextSms = ({ next, dialogStart }) => {
  return new Promise((resolve, reject) => {
    let callback = (doSend) => {
      if (doSend)
        return sendSMS({ item: next, text: 'De Streekboer bezorger is bijna op jouw adres!' }).then(resolve)
      else resolve()
    }
    dialogStart({
      dialogTitle: 'We komen eraan-SMS sturen?',
      dialogText:
        `De volgende klant -${next.firstName}- wil graag een SMS wanneer je er naar toe rijdt. 
        Deze SMS nu versturen?`, callback
    })
  })
}

const runChecks = ({ item, items, dialogStart }) => {
  let actions = [checkDistance]
  let next;
  for (var i = 0; i < items.length; i++) {
    let curr = items[i]
    if (curr.id == item.id) {
      next = (i + 1 == items.length) ? null : items[i + 1];
      if (curr.youGotDelivery)
        actions.push(checkSendCurrSms);
      if (next?.youAreNextStop)
        actions.push(checkSendNextSms);
    }
  }
  let doActions = () => {
    if (!actions.length) return;
    let action = actions.shift();
    action({ item, next, dialogStart })
      .then(doActions)
      .catch(doActions);
  }
  doActions();
}

const DeliveredSelect = ({ item, items, itemChanged, dialogStart }) => {
  const setDelivered = () => {

    item.deliveryTime = item.deliveryTime ? null : new Date();
    if (item.deliveryTime) {
      runChecks({ item, items, dialogStart });
    }

    httpClient(`deliverylists/${item.id}`, {
      method: "PUT",
      body: JSON.stringify({
        deliveryTime: item.deliveryTime,
      }),
    }).then(itemChanged);
  };

  return (
    <Checkbox
      checked={item.deliveryTime ? true : false}
      onChange={(val) => setDelivered(val.target.checked)}
    />
  );
};

export const useLocationDiffDialog = (items, itemChanged) => {

  let [dialogProps, setDialogProps] = useState({
    open: false
  });


  let dialogStart = (props) => {
    setDialogProps({
      ...props,
      open: true
    })

  }

  let dialogReady = (result) => {
    setDialogProps({
      ...dialogProps,
      open: false
    })
    dialogProps.callback(result)
  }

  let dialog = (
    <MiscDialog {...{ ...dialogProps, dialogReady }} />
  );
  let getButton = (item) => (
    <DeliveredSelect {...{ item, items, itemChanged, dialogStart }} />
  );

  return [dialog, getButton];
};
