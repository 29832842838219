import React from "react";
import { Login } from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import LoginForm from "./LoginForm";

const styles = ({ spacing }) =>
  createStyles({
    page: {
      backgroundImage: "url(https://picsum.photos/1024/768)",
      backgroundSize: "cover",
    },
  });

const LoginPage = ({ classes, ...props }) => (
  <Login className={classes.page}>
    <LoginForm {...props} />
  </Login>
);

export default withStyles(styles)(LoginPage);
