import * as React from "react";

import {
  DateInput,
  Edit,
  Toolbar,
  FormWithRedirect,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

import AccountSelector from "../Account/AccountSelector";
import AddressSelector from "../Address/AddressSelector";

const VisitorEdit = (props) => {
  return (
    <Edit title={<VisitorTitle />}
      //  aside={<Aside />} 
      component="div" {...props}>
      <VisitorForm />
    </Edit>
  );
};

const VisitorTitle = ({ record }) =>
  record ? <>#{record.externalId}</> : null;

const VisitorForm = (props) => {

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    Info
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <AccountSelector source="accountId" /><br />
                      <AddressSelector source="addressId" filter={{ accountId: props.record.accountId }} />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <ReferenceInput reference="dropOffs" source="dropOffId">
                        <AutocompleteInput
                          optionValue="id"
                          optionText="name"
                          resettable={true}
                          clearAlwaysVisible={true}
                        />
                      </ReferenceInput>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <DateInput
                        source="orderDate"
                        resource="customers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="accounts"
            />
          </form>
        </Card>
      )}
    />
  );
};

export default VisitorEdit;
