
import RichTextInput from "ra-input-rich-text";
import MediaEditor from "../../../components/MediaEditor";
import CategoriesInput from "../../../components/CategoriesInput";
import icon from "@material-ui/icons/Spa";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Create,
  FileInput,
  ImageField,
  NumberInput,
} from "react-admin";
import ProductListAside from "./ProductListAside";


const create = (props) => (
  <Create {...props}>
    <SimpleForm>
      <FileInput
        source="files"
        label="Related files"
        placeholder={<p>Drop your file here</p>}
      >
        <ImageField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
const edit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput fullWidth source="name" />
      <TextInput fullWidth source="teaser" />
      <TextInput fullWidth source="slug" />
      <RichTextInput
        source="description"
        options={{ multiline: true, rows: 2, columns: 25 }}
      />
      <CategoriesInput source="Categories" />
      {/* <FileInput
        source="files"
        label="Related files"
        placeholder={<p>Drop your file here</p>}
      >
         <ImageField source="src" title="title" /> 
       
      </FileInput> */}
      <BooleanInput label="Visible" source="visible" />
      <NumberInput source="price" step={0.01} />
      <MediaEditor source="id" />
    </SimpleForm>

  </Edit >
);

const list = (props) => {
  return (
    <List {...props} bulkActionButtons={false} aside={<ProductListAside />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="farmer" />
      </Datagrid>
    </List>
  );
};

const def = ({ canRead, ...props }) => {
  return {
    name: "products",
    list,
    create,
    edit,
    icon,
    options: {
      showMenu: canRead("products"),
      group: "Product",
      label: "Products",
    },
    ...props,
  };
};
export default def;