import React, { useState, useCallback } from "react";

import { DatagridBody, Datagrid } from "react-admin";
import { TableCell, TableRow } from "@material-ui/core/";
import { linkToRecord, FormWithRedirect } from "ra-core";
import { useHistory } from "react-router-dom";
const defaultClasses = {};

const FormRow = (props) => {
  return <FormWithRedirect {...props}
    render={formProps => <MyDatagridRow {...formProps} />}
  />
}

const MyDatagridRow = ({
  record,
  resource,
  id,
  classes = defaultClasses,
  className,
  children,
  basePath,
  rowClick,
}) => {
  const history = useHistory();
  const handleClick = useCallback(
    async (event) => {
      if (!rowClick) return;
      event.persist();

      const effect =
        typeof rowClick === "function"
          ? await rowClick(id, basePath, record)
          : rowClick;
      switch (effect) {
        case "edit":
          history.push(linkToRecord(basePath, id));
          return;
        case "show":
          history.push(linkToRecord(basePath, id, "show"));
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [basePath, history, id, record, rowClick]
  );

  let [updateRec, setUpdateRecord] = useState(record);
  let onChange = (props) => {
    console.log(props);
    //  setUpdateRecord(props);
  }
  return (

    <TableRow key={id} className={className}>

      {React.Children.map(children, (field) => {
        return (
          <TableCell
            key={`${id}-${field.props.source}`}
            onClick={field.props.isInput ? null : handleClick}
          >
            {React.cloneElement(field, {
              record: updateRec,
              basePath,
              resource,
              setRecord: setUpdateRecord,
              onChange
            })}
          </TableCell>
        );
      })}

    </TableRow>
  );
};
export const EditableGrid = ({ ...props }) => {
  return (
    <Datagrid
      body={<DatagridBody row={<FormRow />} />}
      {...props}
      rowClick="edit"
    />
  );
};

export default EditableGrid;
