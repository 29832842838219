import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import { Done, ControlPoint } from "@material-ui/icons";
import { useInput, ReferenceField, TextField } from "react-admin";
import AutoRefInput from "./AutoRefInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function IdentifierInput(props) {
  const classes = useStyles();
  let [newVal, setNewVal] = useState(null);
  let inputEl = useRef();
  const { input } = useInput(props);

  let parsedValue = [];
  try {
    parsedValue = JSON.parse(
      input.value && input.value.length > 1 ? input.value : "[]"
    );
  } catch (e) {
    console.log(e);
  }

  if (!Array.isArray(parsedValue)) parsedValue = [];

  const handleDelete = (d, idx) => {
    parsedValue.splice(idx, idx + 1);

    inputEl.current.value = JSON.stringify(parsedValue);

    input.onChange({ ...d, target: inputEl.current });
  };

  let onBlur = (d, toAdd) => {
    setNewVal(null);
    parsedValue.push({ week: 1, productId: toAdd });
    inputEl.current.value = JSON.stringify(parsedValue);
    console.log(parsedValue);
    input.onChange({ ...d, target: inputEl.current });
  };

  return (
    <div className={classes.root}>
      <input type="hidden" ref={inputEl} {...input} />
      {parsedValue.map((e, idx) => (
        <Chip
          key={idx}
          icon={<FaceIcon />}
          label={
            <ReferenceField
              record={e}
              basePath="/products"
              reference="products"
              source="productId"
              link={null}
            >
              <TextField source="name" />
            </ReferenceField>
          }
          onDelete={(d) => handleDelete(d, idx)}
          color="secondary"
        />
      ))}

      {newVal != null ? (
        <Chip
          icon={<FaceIcon />}
          label={
            <AutoRefInput
              reference="products"
              optionValue="id"
              source="id"
              record={{}}
              value={newVal}
              optionText="name"
              onChange={(e) => {
                setNewVal(e);
              }}
              onBlur={(e) => onBlur(e, newVal)}
            />
          }
          onDelete={() => {}}
          deleteIcon={<Done />}
          color="secondary"
        />
      ) : (
        <Chip
          icon={<ControlPoint />}
          clickable
          label="add"
          color="primary"
          onClick={() => setNewVal("")}
        />
      )}
    </div>
  );
}
