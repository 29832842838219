import * as React from "react";
import {
  Avatar,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Cake";
import { Link } from "react-router-dom";
import FancyTime from "../../components/FancyTime";
import moment from "moment";
import CardWithIcon from "./CardWithIcon";

let DownCount = (props) => {
  return (
    <>
      Next pie @{" "}
      <FancyTime
        {...{ size: "large", showTime: false, timeFormat: "D MMMM", ...props }}
      />
    </>
  );
};

const BirthDays = ({ data = [] }) => {
  const classes = useStyles();

  return (
    <CardWithIcon
      value={<DownCount value={data[0].nextBirthday} />}
      icon={CommentIcon}
      title="Pie chart"
    >
      <CardContent>
        <List to="/reviews">
          {data.slice(0, 5).map((record, idx) => {
            let birthDay = new moment(record.nextBirthday);
            let age = `(${birthDay.diff(record.birthday, 'years')})`
            let showAge = birthDay.diff(new moment(), 'hour') < 24;
            return (
              <ListItem key={idx} button component={Link} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={`${record.avatar}`} />
                </ListItemAvatar>

                <ListItemText
                  primary={`${record.firstName} ${showAge ? age : ''}`}
                  secondary={
                    <FancyTime
                      {...{
                        showTime: false,
                        timeFormat: "D MMMM",
                        value: record.nextBirthday,
                      }}
                    />
                  }
                  className={classes.listItemText}
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            )
          }
          )}
        </List>
      </CardContent>
    </CardWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default BirthDays;
