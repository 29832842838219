import EuroIcon from "@material-ui/icons/Euro";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

import React from "react";
import { useMediaQuery } from "@material-ui/core";
import CardWithIcon from "./CardWithIcon";
import moment from "moment";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <div style={{ height: "1em" }} />;

const Turnover = ({ value, data }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  let { lastWeeksNumbers, thisWeeksNumbers } = data;
  let thisWeekTurnover = thisWeeksNumbers[0].salesExVat;
  let lastWeekTurnover = lastWeeksNumbers[0].salesExVat;
  let lastWeekOrderValue = lastWeekTurnover / lastWeeksNumbers[0].accounts;
  let thisWeekOrderValue = thisWeekTurnover / thisWeeksNumbers[0].accounts;

  let diff = (thisWeekTurnover - lastWeekTurnover) / lastWeekTurnover;

  let titlePrev = `Last week @${new moment().format("HH:mm")}`;
  let titleCurr = "This week ";
  let subTitleColor = diff > 0 ? "#090" : "#f00";
  let subTitle = `(${diff > 0 ? "+" : ""} ${new Intl.NumberFormat("nl-NL", {
    style: "percent",
  }).format(diff)})`;

  let filter = encodeURI(
    JSON.stringify({ week: 'lastWeek', tillNow: true })
  );
  let to = {
    pathname: `/topDowns`,
    search: `filter=${filter}`,
  };

  return isXSmall ? (
    <div style={styles.leftCol}>
      <div style={styles.flex}>
        <CardWithIcon
          to="/topDowns?filter={week:lastWeek}"
          icon={EuroIcon}
          title={titlePrev}
          value={new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(lastWeekTurnover)}
        />
      </div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <CardWithIcon
          to="/topDowns"
          icon={EuroIcon}
          title={titleCurr}
          subTitle={subTitle}
          subTitleColor={subTitleColor}
          value={new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(thisWeekTurnover)}
        />
      </div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <CardWithIcon
          to={to}
          icon={ShoppingBasket}
          title={titlePrev}
          value={new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(lastWeekOrderValue)}
        />
      </div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <CardWithIcon
          to="/topDowns"
          icon={ShoppingBasket}
          title={titleCurr}
          subTitle={subTitle}
          subTitleColor={subTitleColor}
          value={new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(thisWeekOrderValue)}
        />
      </div>
    </div>
  ) : (
      <div>
        <div style={styles.flex}>
          <CardWithIcon
            to={to}

            icon={EuroIcon}
            title={titlePrev}
            value={new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(lastWeekTurnover)}
          />
          <Spacer />
          <CardWithIcon
            to="/topDowns"
            icon={EuroIcon}
            title={titleCurr}
            subTitle={subTitle}
            subTitleColor={subTitleColor}
            value={new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(thisWeekTurnover)}
          />

        </div><VerticalSpacer />
        <div style={styles.flex}>
          <CardWithIcon
            to={to}
            icon={ShoppingBasket}
            title={titlePrev}
            value={new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(lastWeekOrderValue)}
          />
          <Spacer />
          <CardWithIcon
            to="/topDowns"
            icon={ShoppingBasket}
            title={titleCurr}
            subTitle={subTitle}
            subTitleColor={subTitleColor}
            value={new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(thisWeekOrderValue)}
          />

        </div>
      </div>
    );
};

export default Turnover;
