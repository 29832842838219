import icon from "@material-ui/icons/Euro";
import { guardListAccess, guardEditAccess } from "../../../util/Guard";
import OrderList from "./OrderList";
import OrderEdit from "./OrderEdit";
// import AccountCreate from "./AccountCreate";

export default ({ canRead, ...props }) => {
  return {
    name: "orders",
    list: guardListAccess(OrderList),
    edit: guardEditAccess(OrderEdit),
    // create: guardCreateAccess(UserCreate),
    icon,
    options: {
      group: "Account",
      label: "Orders",
      showMenu: canRead("orders"),
    },
    ...props,
  };
};
