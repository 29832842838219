import React from "react";
import icon from "@material-ui/icons/SwapHoriz";
import {
  List,
  NumberField,
  TextField,
  DateField,
  DateInput,
  Datagrid,
  Filter,
} from "react-admin";
import { guardListAccess } from "../../../util/Guard";

const DateFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput alwaysOn={true} source="after" />
      <DateInput alwaysOn={true} source="before" />
    </Filter>
  );
};

export const ReturnableList = (props) => (
  <List
    pagination={<></>}
    filters={<DateFilter />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="productId" />
      <NumberField source="quantity" />
      <NumberField source="orderId" />
      <DateField source="rideDate" />
      <DateField source="deliveryTime" />
      <TextField source="routeName" />
      <TextField source="productName" />
      <TextField source="accountName" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

const def = ({ is, canRead, ...props }) => {
  return {
    name: "returnables",
    list: guardListAccess(ReturnableList),
    icon,
    options: {
      group: "Delivery",
      label: "Returnables",
      showMenu: canRead("returnables"),
    },
    ...props,
  };
};
export default def;