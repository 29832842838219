import React from "react";
import { Avatar } from "@material-ui/core/";

let SenderAvatar = (message) => {
  let avtComp = <Avatar />;
  if (message.direction < 0 && message.User) {
    avtComp = <Avatar src={message.User.avatar} />;
  }
  if (message.direction > 0 && message.Ticket.Account) {
    let avatar = JSON.parse(message.Ticket.Account.avatar);
    avtComp = (
      <Avatar
        style={{
          backgroundColor: avatar ? avatar.color : '#fff',
        }}
      >
        {avatar ? avatar.abbr : ""}
      </Avatar>
    );
  }
  return avtComp;
};
export default SenderAvatar;
