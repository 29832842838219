import React, { cloneElement } from "react";

import { TopToolbar, sanitizeListRestProps, Button } from "react-admin";
import dataProvider from "../../../util/DataProvider";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useRefresh } from "react-admin";
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import BoxCopy from "./BoxSelector"

export const BoxContentsActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  setFilters,
  total,
  data,
  ids,
  updateList,
  ...props
}) => {
  let refresh = useRefresh();
  let onClick = (toCopy) => {
    dataProvider
      .create("boxContents", {
        data: {
          ...filterValues,
          name: "new product",
          unit: "stuk",
          quantity: 1,
          supplier: "-",
          toCopy
        },
      })
      .then(({ data: newRecord }) => {
        refresh();
      });
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filterValues.week && filterValues.productId && (
        <Form onSubmit={() => { }}
          render={formProps => {
            return <>
              <BoxCopy source="toCopy" onChange={({ id }) => {
                onClick(id)
              }} />
              <Button onClick={() => onClick()}>
                <AddCircleOutlineIcon />
              </Button>
            </>
          }} />
      )}
    </TopToolbar>
  );
};
export default BoxContentsActions;
