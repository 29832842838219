import React from "react";
import { Filter, WithPermissions } from "react-admin";
import TextFieldClearable from "../../../components/TextFieldClearable";
import AutoRefInput from "../../../components/AutoRefInput";
import SaveNotify from "./SaveNotify";
const PickupFilter = ({
  permissions: { authenticated, canRead } = {},
  routeMode,
  weekInput = false,
  onChange,
  ...props
}) => {
  return (
    <Filter {...props} autocomplete="off">
      <AutoRefInput
        alwaysOn={true}
        source="dropOffId"
        reference="dropOffs"
        label={routeMode ? "Route" : "Pickup point"}
        filter={{ days: 127, isDelivery: routeMode ? 1 : 0 }}
      />
      {weekInput ? (
        <AutoRefInput source="weekId" label={"Week"} reference="weeks" />
      ) : null}
      <TextFieldClearable label="Search" source="q" />
    </Filter>
  );
};
const MyPageWithPermissions = ({ location, match, ...props }) => (
  <WithPermissions
    render={({ permissions }) => (
      <PickupFilter permissions={permissions} {...props} />
    )}
  />
);
export default MyPageWithPermissions;
