import React, { useState } from "react";
import { connect } from "react-redux";
import { userLogin as userLoginAction } from "react-admin";

import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/icons/LockOpen";
import config from "../config";
import { TextField, List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import Yolo from "../components/Yolo";
import moment from "moment"


const styles = ({ spacing }) =>
  createStyles({
    button: {
      width: "100%",
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

const LinkMailer = () => {
  let [state, setState] = useState();
  let [email, setEmail] = useState();
  let [result, setResult] = useState();
  let mail = async (address) => {
    setState("mailing");
    fetch(`${config.API}/auth/sendLink`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    }).then((res) =>
      res.text().then((text) => {
        setState("ready");
        setResult(text);
      })
    );
  };
  if (!state)
    return (
      <ListItem>
        <ListItemText
          primary="..or, mail me a login-link"
          secondary={
            <>
              <TextField
                label="email"
                value={email}
                onChange={(t) => setEmail(t.target.value)}
              ></TextField>
              <Button
                color="primary"
                onClick={mail}
                endIcon={<Icon>send</Icon>}
              ></Button>
            </>
          }
        />
      </ListItem>
    );
  if (state === "mailing") {
    return (
      <ListItem>
        <ListItemText primary="please wait..sending you an email"></ListItemText>
      </ListItem>
    );
  }
  if (state === "ready") {
    return (
      <ListItem>
        <ListItemText primary={result}></ListItemText>
      </ListItem>
    );
  }
};

const CompanyPin = () => {
  let [pincode, setPincode] = useState();
  let [routes, setRoutes] = useState([]);
  let logon = async (address) => {
    fetch(`${config.API}/auth/companyPin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ pincode }),
    }).then((res) =>
      res.json().then((routes) => {
        console.log(routes);
        setRoutes(routes);
      })
    );
  };

  return (
    <> {!routes.length && <ListItem>
      <ListItemText
        primary={<TextField
          label="pincode"
          type="password"
          value={pincode}
          onChange={(t) => setPincode(t.target.value)}
        />}
      />
      <ListItemSecondaryAction>
        <Button
          color="primary"
          onClick={logon}
          endIcon={<Icon />}
        ></Button>
      </ListItemSecondaryAction>
    </ListItem>}
      {routes.length ? <ListItem>
        <ListItemText
          primary={<b>Kies je route:</b>} />
      </ListItem> : null}
      {
        routes.map((route) => {
          return <ListItem button onClick={() => {
            localStorage.setItem("token", route.token);
            window.location.href = `#/routes/${route.id}`
          }} >
            <ListItemText
              primary={route.name} />
          </ListItem>

        })
      }

    </>
  )
};

const LoginForm = ({ classes, userLogin }) => {
  return (
    <List>
      <ListItem>
        <Yolo></Yolo>
      </ListItem>
      <CompanyPin />
    </List>
  );
};

const mapDispatchToProps = {
  userLogin: userLoginAction,
};

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(LoginForm));
