import React, { useState } from "react";

import Input from "@material-ui/core/Input";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";

import { useInput } from "ra-core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, options, theme) {
  return {
    fontWeight:
      options.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const GroupBySelector = ({ options, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    id,
    input,

    meta: { error, touched },
  } = useInput({ ...rest });
  let [value, setValue] = useState(input.value || []);
  const handleChange = (event) => {
    setValue([...event.target.value]);
    input.onChange([...event.target.value]);
  };

  return (
    <Select
      labelId="demo-mutiple-chip-label"
      id={id}
      multiple
      {...rest}
      {...input}
      value={value}
      onChange={handleChange}
      error={!!(touched && error)}
      input={<Input id="select-multiple-chip" />}
      className={classes.formControl}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )}
      MenuProps={MenuProps}
    >
      {options.map((name) => (
        <MenuItem
          key={name}
          value={name}
          style={getStyles(name, options, theme)}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};
export default GroupBySelector;
