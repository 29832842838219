import * as React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
} from "react-admin";
import FancyTime from "../../../components/FancyTime";
import { useMediaQuery } from "@material-ui/core";

import SegmentsField from "./SegmentsField";
import SegmentInput from "./SegmenstInput";
import AccountLinkField from "./AccountLinkField";

import MobileGrid from "./MobileGrid";
import AccountListAside from "./AccountListAside";
import AccountActions from "./AccountActions";

const AccountFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <DateInput source="last_seen_gte" />
    <NullableBooleanInput source="has_ordered" />
    <NullableBooleanInput source="has_newsletter" defaultValue />
    <SegmentInput />
  </Filter>
);

const AccountList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      filters={isSmall ? <AccountFilter /> : null}
      sort={{ field: "endDate", order: "DESC" }}
      perPage={25}
      // actions={<AccountActions />}
      aside={<AccountListAside />}
      bulkActionButtons={false}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
          <Datagrid optimized rowClick="edit">
            <AccountLinkField label="name" />
            <FancyTime source="endDate" label="date" showTime />
            <NumberField
              source="total"
              options={{ style: "currency", currency: "EUR" }}
            />
            <NumberField source="weeks" label="Age in weeks" />
            <NumberField source="count" label="Order count" />
            <FancyTime source="reminder" label="reminder" showTime />
            <SegmentsField />
          </Datagrid>
        )}
    </List>
  );
};

export default AccountList;
