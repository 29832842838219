import React from "react";
import { Chart } from "react-google-charts";


const data = [
  ['From', 'To', 'Weight'],
  ['Abandoned soul', ' Incidental', 19],
  ['Abandoned soul', ' Regular', 12],
  ['Abandoned soul', ' Silent quitter', 3],
  ['Abandoned soul', ' Total loss', 39],
  ['Ambassador', ' Abandoned soul', 1],
  ['Ambassador', ' Regular', 7],
  ['Ambassador', ' Silent quitter', 1],
  ['Incidental', ' Abandoned soul', 25],
  ['Incidental', ' Regular', 4],
  ['Incidental', ' Silent quitter', 6],
  ['Incidental', ' Total loss', 43],
  ['Ordered once', ' Abandoned soul', 2],
  ['Ordered once', ' Incidental', 4],
  ['Ordered once', ' Regular', 6],
  ['Ordered once', ' Silent quitter', 3],
  ['Ordered once', ' Total loss', 32],
  ['Regular', ' Abandoned soul', 20],
  ['Regular', ' Ambassador', 31],
  ['Regular', ' Incidental', 24],
  ['Regular', ' Silent quitter', 8],
  ['Regular', ' Total loss', 16],
  ['Silent quitter', ' Abandoned soul', 6],
  ['Silent quitter', ' Ambassador', 1],
  ['Silent quitter', ' Incidental', 11],
  ['Silent quitter', ' Regular', 6],
  ['Silent quitter', ' Total loss', 18],
  ['Total loss', ' Abandoned soul', 21],
  ['Total loss', ' Incidental', 66],
  ['Total loss', ' Regular', 8],
  ['Total loss', ' Silent quitter', 10],

]
const colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
  '#cab2d6', '#ffff99', '#1f78b4', '#33a02c']

export default () => {

  return <Chart
    width={"100%"}
    height={'100%'}
    chartType="Sankey"
    loader={<div>Loading Chart</div>}
    options={{
      sankey: {
        link: {
          colorMode: 'gradient',
          colors: colors
        },
        node: {
          colors,

        },
      },
    }}


    data={data}
    rootProps={{ 'data-testid': '1' }}
  />
}