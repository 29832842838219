import React from "react";

import { Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FilterLiveSearch, useGetList, useListContext } from "react-admin";
import Ticket from "./Ticket";

const TicketList = ({ version, setVersion }) => {
  let { filterValues, setFilters } = useListContext();

  const { data: tickets, ids: ticketIds } = useGetList(
    "tickets",
    { page: 1, perPage: 100 },
    { field: "createdAt", order: "DESC" },
    {},
    { version }
  );
  if (!ticketIds) return null;

  let setTicket = (id) => {
    if (filterValues.ticketId === id) {
      setFilters({ ...filterValues, ticketId: null });
    } else {
      setFilters({ ...filterValues, ticketId: id });
    }
  };
  return ticketIds.map((e) => {
    let ticket = tickets[e];
    if (!ticket) return null;
    return (
      <Ticket
        key={ticket.id}
        {...ticket}
        active={filterValues.ticketId === ticket.id}
        onClick={() => setTicket(ticket.id)}
      />
    );
  });
};

const MuiCard = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "20em",
      minWidth: "20em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Card);

const Aside = () => {
  return (
    <>
      <MuiCard>
        <CardContent>
          <FilterLiveSearch />
          <TicketList />
        </CardContent>
      </MuiCard>
    </>
  );
};

export default Aside;
