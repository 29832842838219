import config from "../config";
import httpClient from "./HttpClient";
import qs from "query-string";
import { useUpdate } from "react-admin";

const cleanup = () => {
  // Remove the ?code&state from the URL
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin
  );
};

const createPermission = (authenticated, permissions = {}) => {
  const { grants = [], ...user } = permissions;
  const can = (resource, modifier, scope = "any") => {
    let found = grants.find(
      (grant) =>
        grant.resource === resource &&
        grant.scope &&
        grant.scope === scope &&
        (modifier & grant.modifier) === modifier
    );

    return found ? true : false;
  };
  const is = (roles) =>
    (Array.isArray(roles) ? roles : [roles]).find((role) => {
      return grants.find((grant) => grant.role === role) ? true : false;
    })
      ? true
      : false;
  const canFp = (modifier) => (resource, scope = "any") =>
    can(resource, modifier, scope);

  const setting = (key, value) => {
    if (typeof value === "undefined" && !user.settings) return null;
    if (typeof value === "undefined") return JSON.parse(user.settings)[key];

    let settings = JSON.parse(user.settings);
    let newSettings = { ...settings };
    newSettings[key] = value;
    user.settings = JSON.stringify(newSettings);

    httpClient(`users/${user.id}`, {
      method: "PUT",
      body: JSON.stringify({
        settings: user.settings,
      }),
    });
  };
  return {
    authenticated,
    is,
    can,
    setting,
    canRead: canFp(1),
    canCreate: canFp(2),
    canUpdate: canFp(4),
    canDelete: canFp(8),
    canCustom: canFp(16),
    canAccess: canFp(1),
    canExecute: canFp(16),
    user,
  };
};

let user = null;
export default () => ({
  login: async (params) => {
    return Promise.resolve();
  },
  logout: async () => {
    let thisUser = localStorage.getItem("token");
    let thisName = localStorage.getItem("name");
    localStorage.removeItem("token");
    if (localStorage.getItem("prevUser")) {
      localStorage.setItem("token", localStorage.getItem("prevUser"));
      localStorage.removeItem("prevUser");
      localStorage.setItem("swapToken", thisUser);
      localStorage.setItem("swapName", thisName);
    }
  },
  checkError: async (_error) => { },
  checkAuth: async (params) => {
    if ("dashboard" === params.route) {
      return Promise.resolve();
    }
    const token = localStorage.getItem("token");
    if (!token) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  },

  getPermissions: async () => {
    const searchParams = qs.parse(window.location.search);

    if (searchParams.boxuitdebuurt_id) {
      const { boxuitdebuurt_id, redirect } = searchParams;
      // validate if temp token is valid token
      try {
        const {
          json: { jwtToken },
        } = await httpClient(`auth/temp/${boxuitdebuurt_id}`);
        localStorage.setItem("token", jwtToken);

        if (redirect) {
          window.location.href = redirect;
        } else {
          cleanup();
        }
      } catch (err) {
        throw new Error("Link is no longer valid");
      }
    }
    if (searchParams.code && searchParams.state) {
      const { code, state } = searchParams;
      const stateKey = `oidc.${state}`;
      const { code_verifier } = JSON.parse(
        localStorage.getItem(stateKey) || "{}"
      );

      // Transform the code to a token via the API
      const response = await fetch(`${config.API}/auth/google`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code: code, code_verifier }),
      });

      if (!response.ok) {
        cleanup();
        return Promise.reject();
      }
      const token = await response.json();
      localStorage.setItem("token", token);

      cleanup();
    }

    if (!localStorage.getItem("token")) {
      return createPermission(false);
    }
    if (!user) {
      try {
        const { json: user } = await httpClient(`auth/roles`);
        if (user.newUser && window.location.href.indexOf("newUser") == -1)
          window.location.href = "/#/newUser"
        localStorage.setItem("name", user.firstName);
        return createPermission(true, user);

      } catch (err) {
        console.error("failed to fetch roles", err);
        return createPermission(false, { grants: [] });
      }
    } else {
      return createPermission(true, user);
    }
  },
});
