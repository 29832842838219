import React, { useState } from "react";
import httpClient from "../util/HttpClient";

import CallIcon from "@material-ui/icons/Call";

import { useInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import {
  Dialog,
  DialogContent,
  FilledInput,
  IconButton,
  InputAdornment,
  Avatar,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  avatarGreen: {
    backgroundColor: green[100],
    color: green[600],
  },
  avatarRed: {
    backgroundColor: red[100],
    color: red[600],
  },
  input: {
    margin: 10,
  },
  phone: {
    width: "100%",
  },
});

const CallDialog = ({ to, from, setFrom }) => {
  const classes = useStyles();
  let [dialing, setDialing] = useState(false);

  const call = (props) => {
    setDialing(true);
    httpClient(`click2Dials/dial`, {
      method: "PUT",
      body: JSON.stringify(props),
    }).then((res) => {
      setTimeout(() => setDialing(false), 2500);
    });
  };

  return (
    <Dialog
      onClose={() => setFrom(null)}
      aria-labelledby="simple-dialog-title"
      open={from ? true : false}
    >
      <DialogContent>
        <Typography>
          Nummer van <b>je eigen</b> toestel
        </Typography>
        <div className={classes.input}>
          <FilledInput
            id="filled-adornment-password"
            value={from}
            variant="outlined"
            className={classes.phone}
            onChange={(e) => setFrom(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <Avatar
                  className={dialing ? classes.avatarRed : classes.avatarGreen}
                >
                  <IconButton onClick={() => call({ from, to })}>
                    <CallIcon />
                  </IconButton>
                </Avatar>
              </InputAdornment>
            }
          />
          {dialing ? (
            <Typography>
              <b>Let op:</b> toestel opnemen! Daarna word je verbonden met de
              klant.
            </Typography>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const PhoneField = (props) => {
  const classes = useStyles();
  let [from, setFrom] = useState();

  const onOpen = () => {
    httpClient("Click2Dials").then(({ json }) => {
      setFrom(json);
    });
  };

  const { input } = useInput(props);

  return (
    <FilledInput
      id="filled-adornment-password"
      {...input}
      className={classes.phone}
      endAdornment={
        <InputAdornment position="end">
          <Avatar className={classes.avatarGreen}>
            <IconButton onClick={() => onOpen()}>
              <CallIcon />
            </IconButton>
          </Avatar>
          <CallDialog
            from={from}
            setFrom={setFrom}
            to={input.value}
          ></CallDialog>
        </InputAdornment>
      }
    />
  );
};
export default PhoneField;
