import React from "react";
import {
  Euro as OrderIcon,
  ThreeSixty,
  Forum as ReviewIcon,
  ShoppingCart,
} from "@material-ui/icons";

import moment from "moment";
import MessageButton from "../../Tickets/MessageButton";
import AvatarField from "./AvatarField";

import {
  NumberField,
  TextField,
  useTranslate,
  useGetList,
  usePermissions,
} from "react-admin";

import {
  Typography,
  Card,
  CardContent,
  Box,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/core/styles";
import AutoLogin from "./AutoLogin";
import FancyTime from "../../../components/FancyTime";

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Aside = ({ record, basePath, version, update }) => {
  const classes = useAsideStyles();

  return (
    <div className={classes.root}>
      {record && (
        <EventList
          record={record}
          basePath={basePath}
          {...{ version, update }}
        />
      )}
    </div>
  );
};

const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
  message: {
    "& .MuiAvatar-root, .MuiButton-sizeSmall": {
      float: "right",
    },
    "& p": {
      margin: 0,
      padding: 0,
    },
  },
});

const EventList = ({ record, basePath, version, update }) => {
  const translate = useTranslate();
  const classes = useEventStyles();

  const { data: orders, ids: orderIds } = useGetList(
    "orders",
    { page: 1, perPage: 100 },
    { field: "orderDate", order: "DESC" },
    { accountId: record && record.id },
    { version }
  );

  const { data: reviews, ids: reviewIds } = useGetList(
    "messages",
    { page: 1, perPage: 100 },
    { field: "createdAt", order: "DESC" },
    { "Ticket.accountId": record && record.id },
    { version }
  );

  const events = mixOrdersAndReviews(orders, orderIds, reviews, reviewIds);

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Info
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {translate("resources.customers.fields.first_seen")}
                    </Typography>
                    <FancyTime record={record.AccountStat} source="startDate" />
                  </Box>
                </Box>

                <Box display="flex" mb="1em">
                  <Box mr="1em" mb="1em">
                    <OrderIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Total</Typography>
                    <NumberField
                      source="total"
                      options={{
                        style: "currency",
                        currency: "EUR",
                      }}
                      record={record.AccountStat}
                      basePath={basePath}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box mr="1em">
                    <ShoppingCart fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <AutoLogin {...record} />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {translate("resources.customers.fields.last_seen")}
                    </Typography>
                    <FancyTime record={record.AccountStat} source="endDate" />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr="1em">
                    <ThreeSixty fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Count</Typography>
                    <NumberField
                      source="count"
                      record={record.AccountStat}
                      basePath={basePath}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
        {events.map((event, idx) => (
          <Step
            key={`${event.type}-${event.data.id}`}
            expanded
            active={idx === 1}
            completed
            className={classes.message}
          >
            <StepLabel
              StepIconComponent={() => {
                const Component =
                  event.type === "order" ? OrderIcon : ReviewIcon;
                return (
                  <Component
                    fontSize="small"
                    color="disabled"
                    style={{ paddingLeft: 3 }}
                  />
                );
              }}
            >
              <FancyTime value={event.date} />
            </StepLabel>
            <StepContent>
              {event.type === "order" ? (
                <Order
                  record={event.data}
                  key={`event_${event.data.id}`}
                  basePath={basePath}
                />
              ) : (
                  <Message
                    record={event.data}
                    key={`message_${event.data.id}`}
                    basePath={basePath}
                    update={update}
                  />
                )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const mixOrdersAndReviews = (orders, orderIds, reviews, reviewIds) => {
  if (!orderIds.length && !reviewIds.length) return [];
  const eventsFromOrders =
    orderIds && orders
      ? orderIds.map((id) => ({
        type: "order",
        date: orders[id].orderDate,
        data: orders[id],
      }))
      : [];

  const eventsFromReviews =
    reviewIds && reviews
      ? reviewIds
        .filter((e) => reviews[e]) //happens on delete
        .map((id) => {
          return reviews[id]
            ? {
              type: "review",
              date: reviews[id].createdAt,
              data: reviews[id],
            }
            : {};
        })
      : [];

  const events = eventsFromOrders.concat(eventsFromReviews);

  events.sort(
    (e1, e2) => new Date(e2.date).getTime() - new Date(e1.date).getTime()
  );
  return events;
};

const Order = ({ record, basePath }) => {
  return record ? (
    <>
      <Typography variant="body2" gutterBottom>
        <Link to={`/orders/${record.id}`} component={RouterLink}>
          crate:{" "}
          <TextField source="crates" record={record} basePath={basePath} />
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <NumberField
          source="totalAmount"
          options={{
            style: "currency",
            currency: "EUR",
          }}
          record={record}
          basePath={basePath}
        />
        &nbsp;-&nbsp;
        <TextField source="DropOff.name" record={record} basePath={basePath} />
      </Typography>
    </>
  ) : null;
};

const useReviewStyles = makeStyles({
  clamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
});

const Message = ({ record, update }) => {
  const classes = useReviewStyles();
  let { permissions } = usePermissions();

  return record ? (
    <>
      <Typography
        variant="body2"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: `${record.subject}<br/>${record.text}`,
        }}
      ></Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.clamp}
      >
        {record.comment}
      </Typography>
      <AvatarField record={record.User} />
      {permissions &&
        (permissions.user.id === record.userId ||
          record.userId === "7ad8a00a-7afe-11ea-8a7c-b65f94cf88ce") &&
        new moment().diff(record.createdAt, "days") === 0 ? (
          <MessageButton {...{ update, record }} />
        ) : null}
    </>
  ) : null;
};

export default Aside;
