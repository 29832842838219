import AutoRefInput from "../../components/AutoRefInput";
import icon from "@material-ui/icons/Category";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  NumberInput,
  Create,
} from "react-admin";

const create = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <AutoRefInput
        label="Parent category"
        reference="categories"
        fullWidth
        source="parentId"
      />
    </SimpleForm>
  </Create>
);
const edit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="index" />
      <AutoRefInput
        label="Parent category"
        reference="categories"
        // filter={{ type: "startReaso" }}
        fullWidth
        source="parentId"
      />
    </SimpleForm>
  </Edit>
);

const list = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

const def = ({ canRead, ...props }) => {
  return {
    name: "categories",
    list,
    create,
    edit,
    icon,
    options: {
      showMenu: canRead("categories"),
      group: "Product",
      label: "Category",
    },
    ...props,
  };
};
export default def;