import React from "react";
import moment from "moment"
import icon from "@material-ui/icons/DirectionsBus";
import { JsonInput } from "react-admin-json-view";
import {
  Edit,
  SimpleForm,
  FunctionField,
  Datagrid,
  List,
  TextField,
  TextInput,
  ReferenceField,
  NumberField,
  Filter,
  FilterLiveSearch
} from "react-admin";


const Filters = (props) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch label="User" alwaysOn={true} />
    </Filter>
  );
};



export const TaskForm = ({ record, ...props }) => {

  return <SimpleForm {...{ record, ...props }}>
    <TextInput source="lastName" />
    <TextInput source="email" />
    <TextInput source="phone" />

    <JsonInput
      jsonString={true} source="data" />
    <TextInput
      source="misc"

      options={{ multiline: true, rows: 10, columns: 25 }}
      fullWidth
    />

  </SimpleForm>
}

export const CrowdEdit = (props) => {
  return (
    <Edit {...props}>
      <TaskForm />
    </Edit>
  );
};

const DataField = ({ label }) => {

  return <FunctionField render={record => {
    let data = JSON.parse(record.data);
    return data[label];
  }
  } />
}

const list = (props) => {
  return (
    <List {...props} filters={<Filters />}>
      <Datagrid rowClick="edit">
        <NumberField options={{ style: 'currency', currency: 'EUR' }} source="amount" />
        <TextField source="lastName" />
        <TextField source="email" />
        <DataField label="firstName" />
        <DataField label="phone" />
        <FunctionField label="Date" source="createdAt" render={record => {
          return new moment(record.createdAt).format('D-M-YYYY HH:mm')

        }
        } />

        <ReferenceField label="Status" source="stateId" reference="states">
          <TextField source="name" />
        </ReferenceField>

      </Datagrid>
    </List>
  );
};
const Task = ({ is, ...props }) => {
  return {
    name: "crowdfunds",
    list,
    edit: CrowdEdit,
    icon,
    options: {
      showMenu: is(["admin", "manager"]),
      group: "Sales",
      label: "Crowdfund",
    },
    ...props,
  };
};

export default Task;