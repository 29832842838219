
import React, { useState } from "react";
import {
  Checkbox,
} from "@material-ui/core";
import { useInput } from "react-admin";


const CheckboxInput = ({ ...props }) => {
  const {
    input: { onChange, value = false },
  } = useInput(props);
  const [selected, setSelected] = useState(value);

  const handleChange = (event, daysVal) => {
    onChange(!selected);
    setSelected(!selected)
  };

  return (<Checkbox
    checked={selected}
    onChange={handleChange}
    inputProps={{ 'aria-label': 'primary checkbox' }}
  />)
}
export default CheckboxInput;