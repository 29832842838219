import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { MenuItem, Select } from "@material-ui/core";

import {
  useTranslate,
  Title,
  SimpleForm,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
});

const ImportProds = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Card>
      <Title title={translate("pos.configuration")} />

      <CardContent>
        <SimpleForm></SimpleForm>
      </CardContent>

      <CardContent>
        <div className={classes.label}>Sound notifications</div>
      </CardContent>

      <CardContent>
        <div className={classes.label}>Preferred menu</div>
        <Select>
          <MenuItem value="Kroket">Kroket</MenuItem>
          <MenuItem value="Frikandel">Frikandel</MenuItem>
          <MenuItem value="Groentekroket">Groentekroket</MenuItem>
        </Select>
      </CardContent>
    </Card>
  );
};

export default ImportProds;
