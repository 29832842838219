import React from "react";
import { useInput, SelectInput } from "react-admin";
import { DateTimePicker, DatePicker, TimePicker } from "@material-ui/pickers";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(
  {
    clearIcon: {
      height: 16,
      width: 0,
    },
    visibleClearIcon: {
      width: 16,
    },
    clearButton: {
      height: 24,
      width: 24,
      padding: 0,
    },
  },
  { name: "RaAutocompleteInput" }
);

export const DateTimeInput = ({ clearable, label, className, ...props }) => {
  const { input } = useInput(props);
  if (input.value === "") input.value = null;
  const classes = useStyles(props);

  function handleClr(e) {
    e.stopPropagation();
    input.onChange({ ...e, target: { value: null, ...e.target } });
  }

  var InputProps = clearable
    ? {
        endAdornment: (
          <IconButton
            className={classes.clearButton}
            onClick={(e) => handleClr(e)}
          >
            <ClearIcon
              className={classNames(
                classes.clearIcon,
                classes.visibleClearIcon
              )}
            />
          </IconButton>
        ),
      }
    : null;

  return (
    <DateTimePicker
      ampm={false}
      autoOk
      {...{ label, className, ...input }}
      inputVariant="filled"
      InputProps={InputProps}
    />
  );
};

export const DateInput = ({ label, className, ...props }) => {
  const { input } = useInput(props);

  return (
    <DatePicker
      format="d MMM"
      ampm={false}
      autoOk
      {...{ label, className, ...input }}
      inputVariant="filled"
    />
  );
};
export const TimeInput = ({ label, className, ...props }) => {
  const { input } = useInput(props);

  return (
    <TimePicker
      format="H:mm:ss"
      ampm={false}
      autoOk
      {...{ label, className, ...input }}
      inputVariant="filled"
    />
  );
};

export const FrequencyInput = (props) => {
  let choices = [
    {
      id: 0,
      name: "eenmalig",
    },
    {
      id: 7,
      name: "wekelijks",
    },
    {
      id: 14,
      name: "twee wekelijks",
    },
  ];

  const { input } = useInput(props);
  return (
    <SelectInput
      label={"Frequency"}
      choices={choices}
      source="frequncy"
      optionText={"name"}
      optionValue="id"
      defaultValue={0}
      {...input}
    />
  );
};
