import config from "../config";
import fetch from "node-fetch";

import jsonServerProvider from "ra-data-json-server";
import httpClient from "./HttpClient";

let dataProvider = jsonServerProvider(config.API, httpClient);

const makeMultiPart = (resource, params, method = "POST", id = "") => {
  const form = new FormData();
  let { files, ...data } = params.data;
  form.append("file", files.rawFile, files.rawFile.title);
  Object.keys(data).forEach((name) => {
    form.append(name, data[name]);
  });
  let headers = new Headers({ Accept: "application/json" });

  let token = localStorage.getItem("token");
  headers.set("Authorization", `Bearer ${token}`);

  const options = {
    method,
    body: form,
    headers,
  };

  return fetch(`${config.API}/${resource}${id}`, options)
    .then((res) => res.json())
    .then((json) => ({ data: json }));
};

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (
      !params.data.files ||
      !params.data.files.rawFile instanceof File
    ) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }
    return makeMultiPart(resource, params);
  },
  update: (resource, params) => {
    if (!params.data || !params.data.files || !params.data.files.rawFile instanceof File) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }
    return makeMultiPart(resource, params, "PUT", `/${params.data.id}`);
  },
};

export default myDataProvider;

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
