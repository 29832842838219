import React, { useState, useEffect } from "react";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import httpClient from "../../../util/HttpClient";

import {
  Card as MuiCard,
  CardContent,

} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FilterLiveSearch, useListContext } from "react-admin";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const buildTree = ({ elements = [], selected, setSelected }) => {
  [...elements].forEach((child) => {
    buildTree({ elements: child.children, selected, setSelected });
    child.title = () => (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setSelected(child.id)}
        dangerouslySetInnerHTML={{ __html: child.name }}
      />
    );
  });
  return elements;
};


const ProductListAside = () => {
  const [tree, setTree] = useState([]);
  const [selected] = useState();
  let { setFilters } = useListContext();


  return (
    <>
      <Card style={{ width: 400 }}>
        <CardContent>
          <span style={{ cursor: "pointer" }} onClick={() => setFilters({})}>
            clear filters
          </span>
          <br />
          <FilterLiveSearch />
        </CardContent>
      </Card>
    </>
  );
};

export default ProductListAside;
