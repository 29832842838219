import * as React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
  ReferenceField,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

import WithLove from "./WithLove";
import SegmentInput from "../Account/SegmenstInput";
import FullNameField from "../Account/FullNameField";
import FancyTime from "../../../components/FancyTime";
import ExportLink from "../../../components/ExportLink"
import MobileGrid from "./MobileGrid";
import OrderListAside from "./OrderListAside";
import OrderShow from "./OrderShow";

const AccountFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <DateInput source="last_seen_gte" />
    <NullableBooleanInput source="has_ordered" />
    <NullableBooleanInput source="has_newsletter" defaultValue />
    <SegmentInput />
  </Filter>
);


const OrderList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      filters={isSmall ? <AccountFilter /> : null}
      sort={{ field: "orderDate", order: "DESC" }}
      perPage={25}
      bulkActionButtons={false}
      // actions={<AccountActions />}
      aside={<OrderListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
          <Datagrid optimized rowClick="edit" expand={<OrderShow />}>
            <ReferenceField
              label="Account"
              source="accountId"
              reference="accounts"
            >
              <FullNameField />
            </ReferenceField>

            <FancyTime source="orderDate" label="Order date" showTime />
            <NumberField
              source="totalAmount"
              options={{ style: "currency", currency: "EUR" }}
            />
            <TextField source="crates" label="crate" />
            <TextField source="DropOff.name" label="Pickup point" />
            <ExportLink source="externalId" />
            <WithLove source="id" label="love" />
            <FancyTime source="deliveryDate" label="Delivey" showTime={false} />
          </Datagrid>
        )}
    </List>
  );
};

export default OrderList;
