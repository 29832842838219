import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { MenuItem, Select, Switch } from "@material-ui/core";

import { useTranslate, Title } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { subscribeUser } from "../subscription";
import useSound from "use-sound";
import tring from "../util/Tring.mp3";

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
});

const Configuration = ({ userId, permissions }) => {
  const translate = useTranslate();
  const [settings, setSettings] = useState(
    JSON.parse(permissions.user.settings || "{}")
  );
  const [play] = useSound(tring);
  const classes = useStyles();

  const allowCalls = permissions.is(["manager", "admin", "contact-support"]);
  const settingChanged = (key, value) => {
    let newSettings = { ...settings };
    newSettings[key] = value;
    setSettings(newSettings);

    permissions.setting(key, value);
  };

  return (
    <Card>
      <Title title={translate("pos.configuration")} />

      {allowCalls && (
        <CardContent>
          <div className={classes.label}>Push notifications</div>

          <Switch
            checked={settings.pushNotification}
            onChange={(event) => {
              settingChanged("pushNotification", event.target.checked);
              if (event.target.checked) subscribeUser({ force: true });
            }}
          ></Switch>
        </CardContent>
      )}
      {allowCalls && (
        <CardContent>
          <div className={classes.label}>Sound notifications</div>

          <Switch
            checked={settings.soundNotification}
            onChange={(event) => {
              settingChanged("soundNotification", event.target.checked);
              if (event.target.checked) play();
            }}
          ></Switch>
        </CardContent>
      )}
      <CardContent>
        <div className={classes.label}>Preferred menu</div>
        <Select
          value={settings.meat}
          onChange={(event) => {
            settingChanged("meat", event.target.value);
          }}
        >
          <MenuItem value="Kroket">Kroket</MenuItem>
          <MenuItem value="Frikandel">Frikandel</MenuItem>
          <MenuItem value="Groentekroket">Groentekroket</MenuItem>
        </Select>
      </CardContent>
    </Card>
  );
};

export default Configuration;
