import * as React from "react";
import {
  Avatar,
  CardContent,
  List,
  ListItem,
  Button,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Fastfood";
import { Link } from "react-router-dom";

import CardWithIcon from "./CardWithIcon";
const CallButton = ({ cnt }) => {
  return (
    <Button>
      {cnt} Confirmed burgers
    </Button>
  );
};

const Meals = ({ data = [] }) => {
  const classes = useStyles();
  let cnt = data.reduce((acc, item) => { return acc + item.burgerCount }, 0);
  if (cnt == 0) return null;
  return (
    <CardWithIcon
      value={<CallButton cnt={cnt} />}
      icon={CommentIcon}
      title={`To be flipped @April 23th`}
    >
      <CardContent>
        <List to="/reviews">
          {data.map((record, idx) => (
            <ListItem key={idx} button component={Link} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar src={`${record.avatar}`} />
              </ListItemAvatar>

              <ListItemText
                primary={record.firstName}
                secondary={`${record.burgerCount} burger${record.burgerCount > 1 ? 's' : ''}`}
                className={classes.listItemText}
                style={{ paddingRight: 0 }}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </CardWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default Meals;
