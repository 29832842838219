import React from "react";
import moment from "moment";
import { useInput } from "react-admin";
import { TextField } from "@material-ui/core";


const modes = {
  time: 'HH:mm',
  date: 'YYYY-MM-DD'
}


const timeStr2Time = (timeStr, mode) => {
  if (!timeStr) return null
  return new moment(timeStr).format(mode);
}

const TimeInput = ({ mode = 'time', label, ...props }) => {
  const { input } = useInput(props);
  let time = timeStr2Time(input.value, modes[mode]);
  let timeChanged = (e) => {
    let value = e.target.value;
    let time = null;

    if (value && value.length) {
      if (mode == 'time') {
        let hour = parseInt(value.substr(0, 2));
        let minute = parseInt(value.substr(3, 2));
        time = new moment().set({ hour, minute }).toDate();
      }
      else {
        let year = parseInt(value.substr(0, 4));
        let month = parseInt(value.substr(5, 2)) - 1;
        let date = parseInt(value.substr(8, 2));

        time = new moment().startOf('day').set({ hour: 12, minute: 0, year, month, date }).toDate();
      }
    }
    e.target = { ...e.target, value: time }
    input.onChange(e);
  }
  return (
    <TextField
      type={mode}
      variant="standard"
      label={label}
      {...input}
      onChange={timeChanged}
      value={time}
      InputLabelProps={{
        shrink: true,
      }}


    />
  );
};
export default TimeInput;
