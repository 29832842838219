import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import httpClient from "../../util/HttpClient";
import icon from "@material-ui/icons/Visibility";

import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  Create,
  useGetList,
  DatagridBody,
} from "react-admin";
import {
  guardListAccess,
  guardCreateAccess,
  guardEditAccess,
} from "../../util/Guard";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const updateGrant = (props) => {
  return httpClient(`grants/1`, {
    method: "PUT",
    body: JSON.stringify({
      ...props,
    }),
  });
};

const GrantField = ({ record, setRecord, modifier, ...rest }) => {
  let checked = (record.modifier & modifier) === modifier;

  let setGrant = (props) => {
    //default: set this bit to on
    let currVal = parseInt(record.modifier);
    let newVal = currVal | parseInt(modifier);
    //was on.. set of
    if (checked) newVal = currVal ^ parseInt(modifier);
    let { resourceId, roleId } = record;
    setRecord({ ...record, modifier: newVal });
    updateGrant({ modifier: newVal, resourceId, roleId });
  };

  return <Checkbox checked={checked} onChange={setGrant} />;
};

const GrantList = ({ id }) => {
  const { data, ids } = useGetList(
    "grants",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { roleId: id }
  );
  return (
    <Datagrid
      data={data}
      currentSort="id"
      selectedIds={[]}
      ids={ids}
      body={<MyDatagridBody />}
    >
      <TextField source="name" />
      <GrantField label="All" modifier={31} />
      <GrantField label="Read" modifier={1} />
      <GrantField label="Create" modifier={2} />
      <GrantField label="Update" modifier={4} />
      <GrantField label="Delete" modifier={8} />
      <GrantField label="Export" modifier={16} />
    </Datagrid>
  );
};

export const RoleEdit = (props) => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="name" />
        </SimpleForm>
      </Edit>

      <GrantList {...props} />
    </>
  );
};

const MyDatagridRow = ({
  record: orgRecord,
  resource,
  id,
  children,

  basePath,
}) => {
  let [record, setRecord] = useState(orgRecord);
  return (
    <TableRow key={id}>
      {React.Children.map(children, (field) => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
            setRecord,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};
const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

export const RoleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
export const RoleList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);

const Role = ({ is, ...props }) => {
  return {
    name: "roles",
    list: guardListAccess(RoleList),
    edit: guardEditAccess(RoleEdit),
    create: guardCreateAccess(RoleCreate),
    icon,
    options: {
      group: "Employee",
      label: "Roles",
      showMenu: is("admin"),
    },
    ...props,
  };
};
export default Role