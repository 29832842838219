import React from "react";

import { Edit } from "react-admin";

const TokenParser = ({ record, ...rest }) => {
  console.log(rest);
  if (record.jwtToken) {
    localStorage.setItem("token", record.jwtToken);
    window.location.href = "./";
  }
  return <>{record.message}</>;
};
export const ShowAuth = (props) => {
  console.log(props);
  return (
    <Edit {...props}>
      <TokenParser />
    </Edit>
  );
};
