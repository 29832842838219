import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SenderName from "./SenderName";
import SenderAvatar from "./SenderAvatar";
import ContactLookupButton from "../../util/ContactLookupContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Link,
  Button,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  grow: { flex: 1 },
  paper: {
    backgroundColor: "#fafafa",
  },
  message: {
    borderBottom: "2px solid #ebedf5",
    marginBottom: "2em",
    borderRadius: 10,
    "& .MuiCardHeader-root": {
      borderBottom: "2px solid #ebedf5",
    },
    "& .MuiCardHeader-title": {
      display: "flex",
    },
  },
}));
let EmailGrid = ({ data, ids }) => {
  let classes = useStyles();
  let msg0 = data[ids[0]];
  return (
    <>
      <AppBar position="sticky" className={classes.AppBar}>
        <Toolbar>
          <Typography variant="h6">
            <SenderName {...msg0} />
          </Typography>
          <div className={classes.grow} />
          <ContactLookupButton {...msg0.Ticket} />
        </Toolbar>
      </AppBar>
      {ids.map((id) => {
        let message = data[id];
        let attachments = [];
        if (message.attachments && message.attachments.length > 10)
          attachments = JSON.parse(message.attachments);

        return (
          <Card className={classes.message} key={id}>
            <CardHeader
              title={
                <>
                  <SenderAvatar {...message} />
                  <SenderName {...message} />
                </>
              }
            ></CardHeader>
            <CardContent
              dangerouslySetInnerHTML={{ __html: message.html }}
            ></CardContent>
            {attachments.length ? (
              <CardActions>
                {attachments.map((attachment) => (
                  <>
                    <Typography component="h6" variant="h6">
                      Bijlagen
                    </Typography>
                    <Link
                      href={`https://s3.eu-central-1.amazonaws.com/trengo/media/${attachment.fileName}`}
                      target="_blank"
                    >
                      <Button startIcon={<GetApp />} size="small">
                        {" "}
                        {attachment.client_name}
                      </Button>
                    </Link>
                  </>
                ))}
              </CardActions>
            ) : null}
          </Card>
        );
      })}
    </>
  );
};
export default EmailGrid;
