import {
  Typography,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { useUpdate, TextInput, useNotify } from "react-admin"
import TimeInput from '../../../components/TimeInput';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LiveForm from "../../../components/LiveForm";
import AutoRefInput from '../../../components/AutoRefInput';
import DaySelector, { dateToInt, daysRenderer } from "../../../components/DaySelector";

import CopyAll from "@material-ui/icons/FileCopy";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { download } from "../../../util/HttpClient";

import moment from "moment";
import { useState } from "react";

moment.locale('nl');
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const RouteDetail = ({ setReorderMode, reorderMode, record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [downloading, setDownloading] = useState(false);
  const [update, { loading }] = useUpdate("routes", record.id);

  let printButtn = async (id) => {
    setDownloading(true);
    let fileName = 'RouteList';
    fileName += ` ${record.name} ${new moment(record.date).format("D MMMM YYYY")}.pdf`
    await download(`routes/export?routeId=${id}`, {}, fileName).catch(notify)
    setDownloading(false);
  };

  return <><Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography className={classes.heading}>
        {`${record.name} 
        ${record.date ?
            new moment(record.date).format("ddd DD MMM")
            : daysRenderer(record)}`}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <LiveForm {...{ record, resourceName: "routes" }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
          <TextInput variant="standard" source="name" fullWidth />
          <div style={{ display: 'flex', width: '100%', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
            {record.date && <TimeInput source="date" mode="date" onChange={(val) => {
              let date = val.target.value;
              if (date != null) {
                update({
                  payload: {
                    data: { days: dateToInt(date), date },
                  },
                });
              }
            }} />}
            {!record.date && <DaySelector source="days" />}
            {<ToggleButton selected={record.date != null} size="small" aria-label="bold" onClick={() => {
              update({
                payload: {
                  data: { date: record.date == null ? new Date() : null },
                }
              })
            }} >
              <CopyAll />
            </ToggleButton>}
          </div>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
            <AutoRefInput
              reference="vans"
              optionValue="id"
              optionText="name"
              label="Vehicle"
              source="vanId"
              variant="standard"
              options={{ style: { margin: 0 } }}
            />
            <AutoRefInput
              reference="users"
              variant="standard"
              filter={{ role: "driver" }}
              optionValue="id"
              optionText="firstName"
              label="Driver"
              source="driverId"

            /></div>
        </div>
      </LiveForm>
    </AccordionDetails>
  </Accordion>
    <div style={{ display: 'flex', width: '100%', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
      <FormControlLabel
        control={
          <Switch
            value={reorderMode}
            onChange={() => setReorderMode(!reorderMode)}
            label={"Order"}
          />
        }
        label="Enable reordering"
      />{(record.vanId && record.driverId && record.date) && <Button
        variant="contained"
        color={downloading ? "secondary" : "primary"}
        size="small"
        onClick={() => printButtn(record.id)}
        style={{ margin: 5 }}
        startIcon={<CloudDownload />}
      > Print
    </Button>}</div>
  </>


}
export default RouteDetail;