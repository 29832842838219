import React from "react";
import { AutoRefInput } from "../../../components/AutoRefInput";
import icon from "@material-ui/icons/AccountTree";

import {
  TextInput,
  List,

} from "react-admin";
import {
  guardListAccess,

} from "../../../util/Guard";
import EditableGrid from "../../../components/EditableGrid";

import { Filter } from "react-admin";

const PropertiesFilter = (props) => {
  return (
    <Filter {...props}>
      <AutoRefInput
        alwaysOn={true}
        reference="propSets"
        source="propSetId"
        optionValue="id"
      />
    </Filter>
  );
};


let PropGrid = ({ data, ids, ...props }) => {
  let newData = { ...data };
  let newIds = [...ids, "newRow"];
  if (ids.length > 0) {
    let newRow = {
      id: "newRow",
      name: "Nieuw",
      price: 0,
    };
    newData.newRow = newRow;
  }

  return (
    <EditableGrid data={newData} ids={newIds} {...props}>
      <TextInput source="name" />
    </EditableGrid>
  );
};
export const PropsList = (props) => {
  return (
    <List
      {...props}
      filters={<PropertiesFilter />}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <PropGrid rowClick="edit" />
    </List>
  );
};

const def = ({ canRead, is, ...props }) => {
  return {
    name: "props",
    list: guardListAccess(PropsList),
    icon,
    options: {
      showMenu: is(["purchaser", "admin"]),
      group: "Product",
      label: "Propety Sets",
    },
    ...props,
  };
};
export default def;