import React, { PureComponent } from "react";

import moment from "moment";
import { List } from "react-admin";

import icon from "@material-ui/icons/Loyalty";

import {
  LineChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Line,
  Legend,
} from "recharts";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    let val = new moment(payload.value).format("YYYY/ww");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {val}
        </text>
      </g>
    );
  }
}
const formatXAxis = (tickItem) => {
  return new moment(tickItem).format("YYYY/ww");
};
let Chart = ({ loading, data, ids, filterValues }) => {
  if (loading) return null;

  let currWeek = null;
  let tableData = ids.reduce((acc, id) => {
    let row = data[id];

    if (!currWeek || currWeek.week !== row.week) {
      currWeek = {
        week: row.week,
      };
      acc.push(currWeek);
    }
    currWeek[row.name] = row.count;
    return acc;
  }, []);

  return (
    <LineChart
      width={1200}
      height={700}
      data={tableData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        height={70}
        dataKey="week"
        tick={<CustomizedAxisTick />}
        tickFormatter={formatXAxis}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Incidental" dot={false} stroke="#003f5c" />
      <Line type="monotone" dataKey="Regular" dot={false} stroke="#444e86" />
      <Line type="monotone" dataKey="Ambassador" dot={false} stroke="#955196" />
      <Line
        type="monotone"
        dataKey="Ordered once"
        dot={false}
        stroke="#dd5182"
      />
      <Line
        type="monotone"
        dataKey="Silent quitter"
        dot={false}
        stroke="#ff6e54"
      />
      <Line
        type="monotone"
        dataKey="Abandoned soul"
        dot={false}
        stroke="#ffa600"
      />
    </LineChart>
  );
};

let Trendy = (props) => {
  return (
    <List pagination={<></>} {...props}>
      <Chart />
    </List>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "engagements",
    list: Trendy,
    icon,
    options: {
      label: "Engagement",
      group: "Misc",
      showMenu: canRead("engagements"),
    },
  };
};
export default resource;
