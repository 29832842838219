
import { Form, FormSpy } from "react-final-form";
import { useRecordContext, RecordContextProvider, useUpdate } from "react-admin";
import { useState, useEffect } from "react";
import React from 'react'

const AutoSave = ({ values, save, debounce = 1000 }) => {
  let [record, setRecord] = useState(values);
  let [submitting, setSubmitting] = useState(false);
  let [timer, setTimer] = useState(null)

  let saveFunc = async (values) => {
    let difference = {}
    for (var key of Object.keys(record)) {
      if (key == 'updatedAt') continue;
      if (!difference[key] && record[key] != values[key])
        difference[key] = values[key];
    }

    for (var key of Object.keys(values)) {
      if (key == 'updatedAt') continue;
      if (!difference[key] && record[key] != values[key])
        difference[key] = values[key];
    }

    for (var key of Object.keys(difference)) {
      if (difference[key] == undefined) difference[key] = null
    }

    if (Object.keys(difference).length) {
      setRecord({ ...record, ...difference });
      if (timer) clearTimeout(timer);
      setSubmitting(true);
      let newTimer = setTimeout(async () => {
        await save(difference)
        setSubmitting(false);
      }, debounce)
      setTimer(newTimer);
    }
  }

  useEffect(() => {
    saveFunc(values)
  }, [values])
  return null;

}

const SaveSpy = props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)


const LiveForm = ({ children, record, resourceName, beforeSave, afterSave, debounce = 1000, ...props }) => {
  let recordContext = useRecordContext({ record });

  const [update, { loading }] = useUpdate(resourceName, record.id);
  let save = async props => {
    if (beforeSave)
      beforeSave(record, props);
    let newRec = await update({
      payload: {
        data: props
      },
    },
      {
        onSuccess: ({ data }) => {
          if (afterSave)
            afterSave(data, props);
        }
      });
  }
  return <RecordContextProvider value={recordContext}>
    <Form
      initialValues={recordContext}
      render={() => <>
        <SaveSpy save={save} />
        {children}</>} onSubmit={() => { return true }} {...props} />
  </RecordContextProvider>
}
export default LiveForm;
