import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import CategoryIcon from "@material-ui/icons/Category";
import { ControlPoint } from "@material-ui/icons";
import { useInput } from "react-admin";
import AutoRefInput from "./AutoRefInput";
import api from "../util/HttpClient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function IdentifierInput(props) {
  const classes = useStyles();
  let [newVal, setNewVal] = useState(null);
  let inputEl = useRef();
  const { input } = useInput(props);

  let value = [];
  if (typeof input.value == "string" && input.value.length) {
    value = JSON.parse(input.value);
  }
  if (typeof input.value == "object") {
    value = input.value;
  }

  // if (!Array.isArray(parsedValue)) parsedValue = [];

  const handleDelete = (d, idx) => {
    value.splice(idx, idx + 1);
    inputEl.current.value = JSON.stringify(value);
    input.onChange({ ...d, target: inputEl.current });
  };

  let onAdd = async (id) => {
    setNewVal(null);

    let cat = (await api(`categories?id=${id}`)).json[0];
    if (cat) value.push(cat);
    inputEl.current.value = JSON.stringify(value);
    input.onChange({ ...id, target: inputEl.current });
  };

  return (
    <div className={classes.root}>
      <input type="hidden" ref={inputEl} {...input} />
      {value.map((e, idx) => (
        <Chip
          key={idx}
          icon={<CategoryIcon />}
          label={e.name}
          onDelete={(d) => handleDelete(d, idx)}
          color="secondary"
        />
      ))}

      {newVal != null ? (
        <AutoRefInput
          label="Add Category"
          reference="categories"
          source="id"
          value={newVal}
          onChange={onAdd}
        />
      ) : (
        <Chip
          icon={<ControlPoint />}
          clickable
          label="add"
          color="primary"
          onClick={() => setNewVal("")}
        />
      )}
    </div>
  );
}
