import * as React from "react";

import Card from "@material-ui/core/Card";
import { CardContent, IconButton, } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import qs from "query-string"
import { makeStyles } from "@material-ui/core/styles";
import Repeat from "@material-ui/icons/Repeat";

import {
  useShowController,
  TextField,
  ListContextProvider,
  Datagrid,
  FunctionField,
  NumberField,
  useGetList,
  useUpdate,
  ReferenceField
} from "react-admin";

const SubscribeButton = ({ onClick, record, ...props }) => {

  return (<IconButton aria-label="delete" onClick={() => onClick(record.id)} size="small">
    <Repeat fontSize="inherit" />
  </IconButton>)
}

const OrderShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  const listProps = useGetList(
    'orderlines',
    { page: 1, perPage: 100 }, {}, { orderId: record.id }
  );
  const [update, { loading, error }] = useUpdate("subscriptions");


  let makeSubscription = (id) => {
    update("subscriptions", id, { makeSubscription: true })
  }

  if (!record) return null;
  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.spacer}>&nbsp;</div>
        <Grid container spacing={2}></Grid>
        <div className={classes.invoices}>
          <ListContextProvider
            value={{
              ...listProps,
              currentSort: { field: "createdAt", order: "ASC" },
              basePath: "/orderlines",
              resource: "orderlines",
              selectedIds: [],
              setSort: () => { },
            }}
          >
            <Datagrid {...listProps}>
              <ReferenceField label="Product" source="productId" reference="products" link={(record) => {
                let query = qs.stringify({
                  displayedFilters: JSON.stringify(['productId']),
                  filter: JSON.stringify({ productId: record.productId })
                })

                return `/orderlines/?${query}`
              }} >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" label="quantity" />
              <NumberField source="price" label="price" options={{ style: 'currency', currency: 'EUR' }} />
              <NumberField source="total" label="total" options={{ style: 'currency', currency: 'EUR' }} />
              <SubscribeButton onClick={makeSubscription} />
            </Datagrid>
          </ListContextProvider>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrderShow;

const useStyles = makeStyles({
  root: { width: 600, margin: "auto" },
  spacer: { height: 20 },
  invoices: { margin: "10px 0" },
});
