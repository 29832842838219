import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

const TextFieldClearable = (props) => {
  const classes = useStyles();
  const { input } = useInput(props);

  let ref = React.createRef();
  const handleClear = (props) => {
    ref.current.children[0].value = "";
    input.onChange(props);
    ref.current.children[0].focus();
  };

  return (
    <FormControl className={clsx(classes.margin, classes.textField)}>
      <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
      <Input
        id="standard-adornment-password"
        {...input}
        ref={ref}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="clear" onClick={handleClear}>
              {input.value.length ? <Clear /> : null}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
export default TextFieldClearable;
