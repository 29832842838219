import * as React from "react";
// import { Link } from "@material-ui/core";
// import { Link as RouterLink } from "react-router-dom";
import FullNameField from "./FullNameField";
import { Link, useGetOne } from "react-admin";


const AccountLinkField = ({ field = "record", ...props }) => {
  let record = props;
  field.split(".").forEach((e) => {
    if (record[e]) record = record[e];
  });
  return (
    <Link to={`/accounts/${record.id}`} >
      <FullNameField record={record} />
    </Link>
  );
};

export default AccountLinkField;
