import React from "react";

import icon from "@material-ui/icons/Rowing";

import {
  Filter,
  Datagrid,
  List,
  DateField,
  NumberField,
  TextField,
  WithPermissions,
  SearchInput,
} from "react-admin";

const UserFilter = ({ permissions: { authenticated, is } = {}, ...props }) => {
  return (
    <Filter {...props}>
      {authenticated && is(["admin", "manager"]) ? (
        <SearchInput source="q" alwaysOn />
      ) : null}
    </Filter>
  );
};

const FilterWithPermissions = ({ location, match, ...props }) => (
  <WithPermissions
    render={({ permissions }) => {
      return <UserFilter permissions={permissions} {...props} />;
    }}
  />
);
const list = (props) => {
  return (
    <List
      {...props}
      filters={<FilterWithPermissions />}
      sort={{ field: "periodEnd", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <DateField label="Month" source="periodEnd" />

        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="middleName" />

        <NumberField locales="nl" source="hoursEntered" />
        <NumberField locales="nl" source="milesEntered" />
        <NumberField locales="nl" source="hoursWorked" />
        <NumberField locales="nl" source="milesRidden" />
      </Datagrid>
    </List>
  );
};
const TimeSheetOverview = ({ canRead, ...props }) => {
  return {
    name: "timeSheetOverviews",
    list,
    icon,
    options: {
      showMenu: canRead("timeSheets"),
      label: "Hour totals",
      group: "Employee",
    },
    ...props,
  };
};
export default TimeSheetOverview