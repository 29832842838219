import * as React from "react";
import { SelectArrayInput } from "react-admin";
import segments from "./segments";

const SegmentsInput = ({ addField, ...rest }) => {
  return (
    <SelectArrayInput
      source="groups"
      {...rest}
      choices={segments.map((segment) => ({
        id: segment.id,
        name: segment.id,
      }))}
    />
  );
};

export default SegmentsInput;
