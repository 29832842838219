import * as React from "react";
import { useHistory } from "react-router-dom";

import FancyPie from "./FancyPie";

const Engagement = ({ data, ...props }) => {
  let router = useHistory();
  if (!data) return null;
  let { retention, ...rest } = data;

  let pieData = Object.keys(rest).map((name) => {
    return {
      name: name.charAt(0).toUpperCase() + name.slice(1),
      value: parseInt(data[name]),
    };
  });

  return (
    <FancyPie
      title={"Engagement past 4 weeks"}
      data={pieData}
      onClick={(props) => {
        let filter = JSON.stringify({
          segment: props.payload.name.toLowerCase(),
          lastOrder: "lastWeeks",
        });
        router.push(`/accounts?filter=${encodeURI(filter)}`);
      }}
      label={`Retention ${(retention.retention * 100).toFixed(0)}%`}
      subTitle={`${retention.accounts} accounts placed ${retention.orders} orders \n (100%=${retention.accounts * 4} orders)`}
    />
  );
};

export default Engagement;
