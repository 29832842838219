import React, { useState, memo, useRef, useEffect } from "react";
import {
  Drawer, Button, ListItem, Box,
  ListItemSecondaryAction, IconButton, ListItemText, List
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles"
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Edit from "@material-ui/icons/Edit";

import {
  SearchInput,
  useGetList,
  Datagrid,
  ListContextProvider,
  useInput,
  useGetOne,
  useRecordContext,
  Button as CloseButton
} from "react-admin";

import { Form } from "react-final-form"

const useStyles = makeStyles({
  disabled: {
    '&.Mui-disabled': {
      color: '#222'
    }
  }
});

const SelectItem = ({ record, onClick, renderFunc, setRecord, editable }) => {
  return <ListItem
    style={{ cursor: 'pointer' }}
    onClick={() => { onClick(record.id, record) }} >
    <ListItemText>{renderFunc(record)}</ListItemText>
    <ListItemSecondaryAction >
      <IconButton onClick={() => setRecord(record)} edge="end" aria-label="delete">
        {editable && <Edit />}
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem >
}


const ResultList = ({ resource, select, filter, renderFunc, setRecord, editable }) => {
  let [q, setQuery] = useState("");
  let [maxCount, setMaxCount] = useState(0);
  const { ids, data, refetch, total, ...listProps } = useGetList(
    resource,
    { page: 1, perPage: 10 }, {}, { q, ...filter }
  );
  useEffect(() => {
    setMaxCount(Math.max(maxCount, total || 0))
  }, [total])
  return <>{maxCount > 10 && <Form
    onSubmit={() => { }}
    render={() => {
      return <form onSubmit={(e) => {
        if (ids.length) {
          select(ids[0], data[ids[0]]);
        }
        e.preventDefault();
        return false;
      }} ><SearchInput
          InputProps={{
            inputRef: (input) => { input && input.focus() },
            autoComplete: 'off'
          }}
          value={q} source="q" onChange={(q) => {
            setQuery(q.target.value);
            refetch();
          }} /></form>
    }}
  />}

    <ListContextProvider
      value={{
        ids,
        data,
        resource,
        total,
        ...listProps,
        currentSort: { field: "createdAt", order: "ASC" },
        basePath: `/${resource}`,
        filterValues: {},
        selectedIds: [],
        setSort: () => { },
      }}
    >
      <List>
        <Datagrid >
          <SelectItem onClick={select} {...{ renderFunc, setRecord, editable }} />
        </Datagrid>
      </List>
    </ListContextProvider>
    {editable && <Box display="flex" justifyContent="center" >
      <Fab color="secondary" aria-label="clone" onClick={() => setRecord({})} >
        <AddIcon />
      </Fab>
    </Box>}
  </>
}
const SelectorDrawer = ({
  open,
  setOpen,
  onChange,
  filter,
  Editor,
  ...props }) => {
  let [record, setRecord] = useState(null)

  const select = (id, record) => {
    if (onChange)
      onChange(id, record)
    setOpen(false);
  }
  const onSave = (record) => {
    setRecord(null)
  }
  const onClose = () => {
    if (record) setRecord(null)
    else setOpen(false)
  }

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <div>
        <CloseButton label="Close" onClick={onClose}>
          <IconKeyboardArrowRight />
        </CloseButton>
      </div>
      {record == null && <ResultList {...{ ...props, select, setRecord, filter, editable: (Editor != null) }} />}
      {record != null && <Editor {...{ record, onSave, defaults: filter }} />}
    </Drawer >
  );
}

const SelectField = ({ data, onClick, disabled = false, renderFunc, startIcon, btnText }) => {
  const classes = useStyles();
  return <Button className={classes.disabled}  {...{ onClick, disabled, startIcon }}>
    {btnText ? btnText : renderFunc(data)}
  </Button >
}
const PureSelectField = memo(SelectField)

export const SideSelectorInput = ({
  resource,
  showSelector,
  btnText,
  ...props
}) => {
  const { input } = useInput({ ...props });
  const [open, setOpen] = useState(false);

  const { data } = useGetOne(resource, input.value);
  let inputEl = useRef();

  useEffect(() => {
    if (showSelector) setOpen(true);
  }, [showSelector]);

  let onChange = (id, record) => {
    inputEl.current.value = id
    input.onChange({ id, target: inputEl.current });
    // if (props.onChange) props.onChange(id, record);
    setOpen(false);
  }
  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <>
      {open && <SelectorDrawer {...{ ...props, ...input, open, setOpen, onChange, resource }} />}
      <input type="hidden" ref={inputEl} value={input.value} />
      <PureSelectField onClick={handleOpen} {...{ data, ...props, btnText }} />
    </>
  );
};


export default SideSelectorInput;
