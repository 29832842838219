import React from "react";
import { List } from "react-admin";
import GoogleMapReact from "google-map-react";

import { guardListAccess } from "../../util/Guard";
import FullView from "../../components/FullView";
import icon from "@material-ui/icons/HotTub";
let markerUrl = "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 10,
};
let markers = [];

const loadData = ({ map, rows, filterValues, ...props }) => {
  let google = window.google;
  //clear old
  while (markers.length) markers.pop().setMap(null);

  for (var idx = 0; idx < rows.length; idx++) {
    let { id, position } = rows[idx];
    let marker = new google.maps.Marker({
      position,
      map: map,
      data: { id },
      icon: {
        url: markerUrl,
      },
    });
    // marker.dataId = currStop.id;
    marker.addListener("click", () => {
      // handleMarkerClick(setSliderValue, marker.data.id, filterValues.routeId);
    });
    markers.push(marker);
  }
};

let SimpleMap = ({ loading, data, ids, filterValues, ...props }) => {
  //let [heatMap] = useState([]);
  if (loading) return "";
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        heatmapLibrary={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(maps) => {
          let rows = Object.keys(data).map((e) => {
            let { lat, lng, avgTurnOver, ...rest } = data[e];
            let position = { lat, lng };
            return {
              location: new window.google.maps.LatLng(lat, lng),
              weight: avgTurnOver,
              ...rest,
              position,
              avgTurnOver,
            };
          });
          var heatmap = new window.google.maps.visualization.HeatmapLayer({
            data: rows,
            radius: 40,
          });
          heatmap.setMap(maps.map);

          loadData({ ...props, ...maps, rows, filterValues });
        }}
      ></GoogleMapReact>
    </div>
  );
};

export const HeatMaps = (props) => {
  return (
    <FullView>
      <List pagination={<></>} {...props}>
        <SimpleMap />
      </List>
    </FullView>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "heatMaps",
    list: guardListAccess(HeatMaps),
    icon,
    options: {
      label: "Heatmap",
      group: "Misc",
      showMenu: canRead("heatMaps"),
    },
  };
};
export default resource;
