import React from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import HighlightOff from "@material-ui/icons/HighlightOff";

let WrapInput = ({ input, ...props }) => {
  let onChange = (val) => {
    input.onChange(val);
  };
  let newInput = { ...input, onChange: onChange };
  return (
    <>
      <AutocompleteInput {...props} input={newInput} />
      <IconButton
        color="primary"
        aria-label="clear field"
        onClick={() => onChange(null)}
      >
        <HighlightOff />
      </IconButton>
    </>
  );
};
export default ({ optionText = "name", ...props }) => {
  return (
    <>
      <ReferenceInput {...props}>
        <WrapInput optionText={optionText} />
      </ReferenceInput>
    </>
  );
};
