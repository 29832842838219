import React, { useState, useRef, useEffect } from "react";
import returnables from "./Returnables.json"
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  Card,
  Avatar,
  CardContent,
  DialogActions,
  CardMedia,
  TextField
} from "@material-ui/core";

import Carousel from "react-material-ui-carousel"
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useGetOne, useInput } from "react-admin"
import { blue, pink } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      marginTop: 5,
      width: '100%'
    },
    card: {
      margin: 10
    },
    cardMedia: {
      height: 200,
      backgroundSize: 'contain'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

    divider: {
      flex: 'auto'
    },
    avatar: {
      backgroundColor: pink[500],
      margin: '5px auto'
    },
    avatarWrap: {
      position: 'relative',
      display: 'inline-block',
      width: 25,
      left: -15,
      top: -2
    },
    noIosZoom: {
      touchAction: 'manipulation'
    }
  }
});

const Item = ({ name, value, misc, miscChange, ...props }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>

      <CardMedia
        className={classes.cardMedia}
        alt="Contemplative Reptile"
        height="140"
        {...props}
      />
      <CardContent>

        <Typography gutterBottom variant="h5" component="h2">
          {value > 0 && <div className={classes.avatarWrap} ><Avatar className={classes.avatar}>{value}</Avatar></div>} {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <TextField fullWidth value={misc} onChange={(e) => miscChange(e.target.value)} />
        </Typography>
      </CardContent>
    </Card>
  )
}
const QuantBtn = ({ amount, onClick }) => {
  const classes = useStyles();
  let variant = Math.abs(amount) > 1 ? 'outlined' : 'contained'
  let endIcon = amount > 0 ? <AddIcon /> : null;
  let startIcon = amount < 0 ? <RemoveIcon /> : null;
  return <Button
    className={classes.noIosZoom}
    onClick={() => onClick(amount)}
    color="primary"
    {...{ variant, endIcon, startIcon }}
  >
    {Math.abs(amount)}
  </Button>
}

const PalletBtn = ({ onClick, load, value = 1 }) => {
  const classes = useStyles();
  return <Button className={classes.button} variant="outlined" color="secondary" {...{ onClick }}
    startIcon={load ? < ArrowBack /> : null}
    endIcon={load ? null : < ArrowForward />}
  >
    {value}
  </Button >
}


function SimpleDialog({ onChange, open, onClose, value: storedValue, ...props }) {
  const classes = useStyles();
  const [timer, setTimer] = useState(null)
  let [values, setValues] = useState(storedValue || []);
  let [selected, setSelected] = useState(0);
  let [misc, setMisc] = useState(null);

  const carouselChange = (index) => {
    setSelected(index);
    let [savedItem] = getSelected();
    setMisc(savedItem.misc)
  };
  const miscChange = (value) => {
    setMisc(value)
    addValue(0);
  }

  const getSelected = () => {
    let changedItem = returnables[selected];
    let savedItem = values.find(savedItem => savedItem.id == changedItem.id);
    let created = false;
    if (!savedItem) {
      savedItem = { id: changedItem.id, count: 0, misc };
      created = true
    }
    return [savedItem, created];
  }

  const onValueChange = () => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => onChange(values.filter(e => e.count)), 1000);
    setTimer(timer);
  }

  const addValue = (value) => {
    let [savedItem, created] = getSelected();
    if (created) values.push(savedItem);
    savedItem.count += value;
    savedItem.count = Math.max(0, savedItem.count)
    savedItem.misc = misc;
    setValues([...values]);
    onValueChange();
  };



  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Retouren
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <Typography></Typography>
      </DialogContent>
      <Carousel
        navButtonsAlwaysVisible
        autoPlay={false}
        animation="slide"
        fullHeightHover={false}
        changeOnFirstRender={true}
        onChange={carouselChange}
      >
        {
          returnables.map(
            (item, i) => {
              let savedItem = values.find(savedItem => savedItem.id == item.id);
              return <Item key={i} value={savedItem ? savedItem.count : 0} {...{ misc, miscChange, ...item }} />
            })
        }
      </Carousel>
      <DialogActions>
        <QuantBtn amount={-1} onClick={addValue} />
        <QuantBtn amount={-10} onClick={addValue} />
        <div className={classes.divider} />
        <QuantBtn amount={10} onClick={addValue} />
        <QuantBtn amount={1} onClick={addValue} />
      </DialogActions>

    </Dialog >
  );
}
const getTotal = (value) => {
  let total = value.reduce((total, e) => {
    let item = returnables.find(ret => ret.id == e.id);
    return total + e.count * item.space
  }, 0)
  return Math.floor(total);
}

export const R9sInput = ({ load, onChange, ...props }) => {
  const { input } = useInput(props);
  const [open, setOpen] = useState(false);
  let inputEl = useRef();
  let change = (value) => {
    inputEl.current.value = JSON.stringify(value);
    input.onChange(inputEl.current.value);
    if (onChange) onChange(value);
  }
  const onClose = () => { setOpen(false) }
  let value = input.value ? JSON.parse(input.value) : [];
  return (
    <>
      {open && <SimpleDialog {...{ ...input, value, onChange: change, onClose, open }} />}
      <div><input type="hidden" ref={inputEl} {...input} />
        <PalletBtn value={getTotal(value)} load={load} onClick={() => { setOpen(true) }} /></div>
    </>
  );
};


export default R9sInput;