import React, { useState } from "react";
import BarcodeReader from "react-barcode-reader";
import { List } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import icon from "@material-ui/icons/FormatAlignCenter";
import { ListItem, ListItemText } from "@material-ui/core";
import { guardListAccess } from "../../util/Guard";
import { fetchJSON } from "../../util/HttpClient";

const Barcode = ({ row }) => {
  return (
    <ListItem>
      <ListItemText
        primary={row.title || row.barcode}
        secondary={<>{row.barcode}</>}
      />
    </ListItem>
  );
};
let timerId = 0;
const queue = [];

function tick(toc, setTimer) {
  let newToc = { ...toc, now: Math.max(toc.now - 1000, 0) };
  clearTimeout(timerId);
  if (newToc.now > 0) timerId = setTimeout(() => tick(newToc, setTimer), 1000);
  setTimer(newToc);
}

const fetchNext = (rows, setRows, setTimer) => {
  if (queue.length === 0) return;
  let barcode = queue.shift();
  fetchJSON(`barcodeReads`, {
    method: "POST",
    body: JSON.stringify({
      barcode,
    }),
  }).then(({ timer, ...row }) => {
    if (timer) {
      tick({ now: timer * 1000, max: timer * 1000 }, setTimer);
    } else tick({ now: 0, max: 0 }, setTimer);

    setRows([row, ...rows]);
    fetchNext(rows, setRows, setTimer);
  });
};

const queueBarcode = (barcode, rows, setRows, setTimer) => {
  queue.push(barcode);
  console.log(queue.length);
  if (queue.length === 1) fetchNext(rows, setRows, setTimer);
};

export const Barcodes = (props) => {
  let [rows, setRows] = useState([]);
  let [timer, setTimer] = useState({ now: -1, max: 15 });

  let onScan = (barcode) => {
    setRows([{ barcode, id: new Date().getTime() }, ...rows]);
    queueBarcode(barcode, rows, setRows, setTimer);
  };

  return (
    <>
      {timer.now > 0 ? (
        <LinearProgress
          variant="determinate"
          value={100 - (timer.now / timer.max) * 100}
        />
      ) : null}
      {!rows.length
        ? "Please use a barcode scanner to scan boxes or boxtickets"
        : null}
      <BarcodeReader onError={console.log} onScan={onScan} />

      <List>
        {rows.map((row) => {
          return <Barcode key={row.id} row={row} />;
        })}
      </List>
    </>
  );
};
const def = ({ canRead, ...props }) => {
  return {
    name: "barcodeReads",
    list: guardListAccess(Barcodes),
    icon,
    options: {
      group: "Delivery",
      label: "Barcodes",
      showMenu: canRead("barcodeReads"),
    },
    ...props,
  };
};
export default def;