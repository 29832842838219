import React, { useState, useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";

import {
  AppBar,
  UserMenu,
  MenuItemLink,
  WithPermissions,
  Layout,
  usePermissions
} from "react-admin";
import Snowflakes from 'magic-snowflakes';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { TimeNotationProvider } from "../components/FancyTime";
import { WSContextProvider } from "../util/WSContext";
import { Settings, People } from "@material-ui/icons";
import CallNotify from "../util/CallNotify";

import {
  LookupInfoButton,
  ContactLookupProvider,
} from "../util/ContactLookupContext";

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
  grow: {
    flex: 1,
  },
});

const MyCustomIcon = ({ user = {} }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={classes.avatar}
      src={user.avatar ? user.avatar : "JohnDoe.png"}
    />
  );
};

const MyUserMenu = ({ permissions: { user } = {}, children, ...props }) => {
  let swapName = null;
  if (localStorage && localStorage.getItem("swapName")) {
    swapName = localStorage.getItem("swapName");
  }
  return <UserMenu {...props} icon={<MyCustomIcon user={user} />}>
    <MenuItemLink
      to="/configuration"
      primaryText="Configuration"
      leftIcon={<Settings />}
    />
    {swapName && <div>
      <MenuItemLink onClick={(e) => {
        localStorage.setItem("prevUser", localStorage.getItem("token"))
        localStorage.setItem("token", localStorage.getItem("swapToken"));
        localStorage.removeItem("swapToken");
        localStorage.removeItem("swapName");
      }}
        to="/#"
        primaryText={swapName}
        leftIcon={<People />}
      />
    </div>
    }
  </UserMenu>
}
const MyUserMenuWithPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <MyUserMenu permissions={permissions} {...props} />;
    }}
  />
);

const MyAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props} userMenu={<MyUserMenuWithPerms />}>
      <div className={classes.grow} />
      <LookupInfoButton />
      <WithPermissions
        render={({ permissions, ...rest }) => {
          return <CallNotify permissions={permissions} {...rest} />;
        }}
      />
    </AppBar>
  );
};
const Snow = ({ children, ...props }) => {
  const matches = useMediaQuery('(min-width:600px)');
  let { permissions } = usePermissions();

  let startYear = moment().startOf('year');
  let now = new moment();
  let snowEnd = startYear.clone().add(5, 'days')
  let snowStart = snowEnd.clone().add(11, 'months');
  let shouldSnow = now < snowEnd || now > snowStart;
  shouldSnow = true;

  let replaceRecursively = (element, from, to) => {
    if (element.childNodes.length) {
      element.childNodes.forEach(child => replaceRecursively(child, from, to));
    } else {
      const cont = element.textContent;
      if (cont) {

        let text = element.textContent;

        element.textContent = text.replaceAll(from, to)

        // element.textContent = cont.replace(from, to);
      }
    }
  };
  let upsideDown = (idx) => {
    document.querySelector('.app main').style.transform = ` rotate(${idx}deg)`;
    if (idx < 180) setTimeout(() => {
      upsideDown(idx + 1);
    }, 500)
  }

  let replacer = (idx) => {

    let replacements = '1234567890';
    let hourT = new moment("2024/11/11 12:00")
    let hourX = new moment("2024/11/12 12:00");
    let totalHours = hourX.diff(hourT, 'hours');
    let progress = Math.floor((1 - hourX.diff(new moment().add(7, 'hours'), 'hours') / totalHours * 1.0) * 26);

    replaceRecursively(document.body, String.fromCharCode(idx + 65), replacements[idx % replacements.length]);
    replaceRecursively(document.body, String.fromCharCode(idx + 97), replacements[idx % replacements.length]);

    if (idx < progress) setTimeout(() => {
      replacer(idx + 1);
    }, 5000)
  }

  let [flake, setFlake] = useState();
  useEffect(() => {
    if (!permissions || !permissions.user) return;
    if (permissions.user.companyId != '3209616f-6c37-47af-ae3c-57a17bd6c149') return;
    upsideDown(0);
    // replacer(0);
    // if (!window.flakes)
    //   window.flakes = [
    //     '#FF69B4',
    //     '#DC143C',
    //     '#9ACD32',
    //     '#FF8C00',
    //     '#7FFFD4',
    //     '#9370DB',
    //     '#FFD700'
    //   ].map(function (item) {
    //     new Snowflakes({
    //       // count: 5,
    //       maxSize: 60,
    //       color: item
    //     });
    //   });
    // return () => {
    //   window.flakes.map(e => {
    //     e.stop();
    //     e.destroy();
    //   })
    // }

    // if (matches && shouldSnow)
    //   setFlake(new Snowflakes())
    // else if (flake) {
    //   flake.stop();
    //   flake.destroy();
    // }

    replacer(0);
  }, [matches, permissions]);
  return children;
};

const MyLayout = (props) => (
  <Snow>
    <WSContextProvider>
      <TimeNotationProvider>
        <ContactLookupProvider>
          <Layout className="app" {...props} appBar={MyAppBar} />
        </ContactLookupProvider>
      </TimeNotationProvider>
    </WSContextProvider>
  </Snow>
);

export default MyLayout;
