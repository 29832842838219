import React, { useContext, useState, useEffect } from "react";
import { WSContext } from "./WSContext";
import useSound from "use-sound";
import tring from "./Tring.mp3";
import Snackbar from "@material-ui/core/Snackbar";

import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";
import { PhoneInTalk } from "@material-ui/icons";

const CallNotify = ({ permissions }) => {
  const { listen } = useContext(WSContext);
  const [notify, setNotify] = useState(null);
  let router = useHistory();
  const [play] = useSound(tring);

  useEffect(() => {
    if (!permissions) return;
    listen("inboundCall", (data) => {
      let sound = permissions && permissions.setting("soundNotification");

      setNotify(data);
      if (sound) play();
    });
  }, [listen, play, permissions]);

  if (!notify) return "";
  let onClick = () => {
    if (notify.account) router.push(`/accounts/${notify.account.id || ""}`);
    setNotify(null);
  };

  if (notify)
    return (
      <Snackbar
        open={true}
        autoHideDuration={15 * 1000}
        onClose={() => setNotify(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          startIcon={<PhoneInTalk />}
        >
          {notify.account
            ? `${notify.account.firstName} ${
                notify.account.middleName || ""
              }`.trim() + ` ${notify.account.lastName}`
            : notify.callerid}
        </Button>
      </Snackbar>
    );
  else return "";
};

export default CallNotify;
