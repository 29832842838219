import React from "react";

import { Card as MuiCard, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AccessTime as AccessTimeIcon,
  ThreeSixtyOutlined,
} from "@material-ui/icons";

import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const Aside = () => {
  return (
    <>
      <Card>
        <CardContent>
          <FilterLiveSearch />

          <FilterList label="Last ordered" icon={<AccessTimeIcon />}>
            <FilterListItem
              label="resources.customers.filters.today"
              value={{
                lastOrder: "today",
              }}
            />
            <FilterListItem
              label="Yesterday"
              value={{
                lastOrder: "yesterday",
              }}
            ></FilterListItem>

            <FilterListItem
              label="resources.customers.filters.this_week"
              value={{
                lastOrder: "thisWeek",
              }}
            />
            <FilterListItem
              label="resources.customers.filters.last_week"
              value={{
                lastOrder: "lastWeek",
              }}
            />

            <FilterListItem
              label="Last 4 weeks"
              value={{
                lastOrder: "lastWeeks",
              }}
            />
            <FilterListItem
              label="resources.customers.filters.earlier"
              value={{
                lastOrder: "earlier",
              }}
            />
          </FilterList>

          <FilterList label="Age in orders" icon={<ThreeSixtyOutlined />}>
            <FilterListItem
              label="First order ever.."
              value={{
                count: 1,
              }}
            />
            <FilterListItem
              label="Second order"
              value={{
                count: 2,
              }}
            />

            <FilterListItem
              label="Third order"
              value={{
                count: 3,
              }}
            />
            <FilterListItem
              label="4th order"
              value={{
                count: 4,
              }}
            />
          </FilterList>
        </CardContent>
      </Card>
    </>
  );
};

export default Aside;
