import React from "react";

import { AutoRefInput } from "../../../components/AutoRefInput";
import icon from "@material-ui/icons/LiveTv";
import EcoIcon from "@material-ui/icons/Eco";
import GroupBySelector from "../../../components/GroupBySelector";
import VolumeField from "../VolumeField";
import moment from "moment";
import {
  TextInput,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  NumberField,
  Filter,
} from "react-admin";
import NavLink from "../NavLink";

const FilterLink = ({ basePath, currFilter, source, record, ...props }) => {
  let filter = encodeURI(
    JSON.stringify({ ...currFilter, [source]: record[source] })
  );
  let to = {
    pathname: `${basePath}`,
    search: `filter=${filter}`,
  };
  return (
    <NavLink to={to} replace={true}>
      <TextField {...{ source, record, ...props }} />
    </NavLink>
  );
};

const Filters = (props) => {
  return (
    <Filter {...props}>
      <AutoRefInput
        alwaysOn={true}
        optionText="weekName"
        optionValue="id"
        reference="weeks"
        source="week"
      />

      <BooleanInput
        label={`Till ${new moment().format("ddd HH:mm")}`}
        alwaysOn={true}
        source="tillNow"
      />
      <TextInput label="Zone" source="zoneName" alwaysOn={false} />
      <TextInput label="Farmer" source="farmer" alwaysOn={false} />
      <TextInput label="Search" source="search" alwaysOn={true} />
      <GroupBySelector
        label="Group by"
        source="groupBy"
        alwaysOn={true}
        options={[
          "Farmer",
          "Product",
          "Zone",
          "Role",
          "Pickup point",
          "Variant",
        ]}
      />
    </Filter>
  );
};
export const EkoField = ({ record, source, ...props }) => {
  if (record[source] > 0) return <EcoIcon />;
  else return null;
};

const postRowClick = (id, basePath, record) => `products/${id}`;
const showByFilter = (filter = [], elem, name) => {
  for (var i = 0; i < filter.length; i++)
    if (filter[i] === (name || elem.props.label)) return elem;
  return null;
};
const SomeGrid = ({ permissions, ...props }) => {
  if (!permissions) return null;
  let alowFinance = permissions.is(["manager", "purchaser", "admin"]);

  return (
    <Datagrid {...props} rowClick={null}>
      <NumberField locales="nl" source="num" label="#" />
      {showByFilter(
        props.filterValues.groupBy,
        <TextField locales="nl" source="productName" label="Product" />
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <TextField locales="nl" source="variantName" label="Variant" />
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <FilterLink
          label="Farmer"
          currFilter={props.filterValues}
          source="farmer"
        ></FilterLink>
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <FilterLink
          currFilter={props.filterValues}
          source="zoneName"
        ></FilterLink>,
        "Zone"
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <TextField source="role" label="Role" />
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <TextField source="dropOffName" label="Pickup point" />
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <TextField source="city" label="City" />,
        "Pickup point"
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <NumberField locales="nl" source="quantity" />,
        "Product"
      )}
      <NumberField locales="nl" source="accounts" />
      {alowFinance ? <NumberField locales="nl" source="margin" /> : null}
      {showByFilter(
        props.filterValues.groupBy,
        <EkoField source="bio" />,
        "Product"
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <VolumeField source="volume" />,
        "Variant"
      )}
      {showByFilter(
        props.filterValues.groupBy,
        <TextField source="variant" label="Variant" />,
        "Variant"
      )}
      {alowFinance ? (
        <NumberField
          locales="nl"
          source="salesExVat"
          options={{ style: "currency", currency: "EUR" }}
        />
      ) : null}
      {alowFinance ? (
        <NumberField
          locales="nl"
          source="revenue"
          options={{ style: "currency", currency: "EUR" }}
        />
      ) : null}
      {permissions.is(["manager", "purchaser", "admin"]) ? (
        <NumberField
          locales="nl"
          source="turnoverPercent"
          options={{ style: "percent" }}
        />
      ) : null}
      />
    </Datagrid>
  );
};
export const list = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<Filters />}
      filterDefaultValues={{
        groupBy: ["Product"],
      }}
      bulkActionButtons={false}
    >
      <SomeGrid rowClick={postRowClick} permissions={permissions}></SomeGrid>
    </List>
  );
};

const def = ({ canRead, ...props }) => {
  return {
    name: "liveTopDowns",
    list,
    icon,
    options: {
      showMenu: canRead("liveTopDowns"),
      group: "Product",
      label: "Live sales",
    },
    ...props,
  };
};

export default def;