import React, { useState } from "react";

import {
  Card as MuiCard,
  CardContent,
  IconButton,
  CardActions,
  Collapse,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AccessTime as AccessTimeIcon,
  LocalOfferOutlined as LocalOfferIcon,
  Loyalty as LoyaltyIcon,
  StarOutlined as StarIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";

import segments from "./segments";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const Aside = () => {
  let [expanded, setExpanded] = useState(false);
  let h5Style = { marginBottom: 2 };
  return (
    <>
      <Card>
        <CardContent>
          <FilterLiveSearch />

          <FilterList label="Last ordered" icon={<AccessTimeIcon />}>
            <FilterListItem
              label="resources.customers.filters.today"
              value={{
                lastOrder: "today",
              }}
            />
            <FilterListItem
              label="Yesterday"
              value={{
                lastOrder: "yesterday",
              }}
            ></FilterListItem>

            <FilterListItem
              label="resources.customers.filters.this_week"
              value={{
                lastOrder: "thisWeek",
              }}
            />
            <FilterListItem
              label="resources.customers.filters.last_week"
              value={{
                lastOrder: "lastWeek",
              }}
            />

            <FilterListItem
              label="Last 4 weeks"
              value={{
                lastOrder: "lastWeeks",
              }}
            />
            <FilterListItem
              label="resources.customers.filters.earlier"
              value={{
                lastOrder: "earlier",
              }}
            />
          </FilterList>

          <FilterList label="Is new" icon={<StarIcon />}>
            <FilterListItem
              label="ra.boolean.true"
              value={{
                isNew: true,
              }}
            />
            <FilterListItem
              label="ra.boolean.false"
              value={{
                isNew: false,
              }}
            />
          </FilterList>

          <FilterList label="Is Member" icon={<LoyaltyIcon />}>
            <FilterListItem
              label="ra.boolean.true"
              value={{
                isMember: true,
              }}
            />
            <FilterListItem
              label="ra.boolean.false"
              value={{
                isMember: false,
              }}
            />
          </FilterList>

          <FilterList label="Engagement" icon={<LocalOfferIcon />}>
            {segments.map((segment) => (
              <FilterListItem
                label={segment.name}
                key={segment.id}
                value={{ segment: segment.id }}
              />
            ))}
          </FilterList>
          <CardActions>
            Explanation
            <IconButton
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <h5 style={h5Style}>Ambassador</h5> at least: 35 orders, 35 weeks,
              > 0.6 retention.
              <h5 style={h5Style}>Regular</h5> at least: 2 orders, 2 weeks, >=
              0.4 retention.
              <h5 style={h5Style}>Incidental</h5> at least: 2 orders, 2 weeks,
              &lt; 0.4 retention.
              <h5 style={h5Style}>Once</h5> 1 order. ever.
              <h5 style={h5Style}>Silent quitter</h5>at least: 2 orders, last
              order 6 weeks ago.
              <h5 style={h5Style}>Abandoned soul</h5>at least: 2 orders, last
              one more then 7 weeks ago.
              <h5 style={h5Style}>Total loss</h5>Gone. Just gone.
            </CardContent>
          </Collapse>
        </CardContent>
      </Card>
    </>
  );
};

export default Aside;
