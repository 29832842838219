import React from "react";
import AutoRefInput from "../../../components/AutoRefInput";
import { Filter, WithPermissions, FilterLiveSearch } from "react-admin";

const OrderlineFilter = ({
  permissions: { authenticated, canRead } = {},
  routeMode,
  weekInput = false,
  ...props
}) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch alwaysOn={true} />
      <AutoRefInput
        label="Product"
        source="productId"
        optionValue="id"
        filter={
          props.filterValues.producerId
            ? { producerId: props.filterValues.producerId }
            : null
        }
        reference="products"
        alwaysOn={false}
        allowEmpty
      />

      {weekInput && authenticated && canRead("accounts") ? (
        <AutoRefInput
          label="Week"
          source="weekId"
          optionValue="id"
          optionText="weekName"
          reference="weeks"
          alwaysOn={false}
          allowEmpty
        />
      ) : null}
    </Filter>
  );
};
const MyPageWithPermissions = ({ location, match, ...props }) => (
  <WithPermissions
    render={({ permissions }) => (
      <OrderlineFilter permissions={permissions} {...props} />
    )}
  />
);
export default MyPageWithPermissions;
