import React, { useState, useEffect, useRef } from "react";
import { List } from "react-admin";
import GoogleMapReact from "google-map-react";
import httpClient from "../../../util/HttpClient";
import icon from "@material-ui/icons/Gesture";
import { guardListAccess } from "../../../util/Guard";
import PickupFilter from "./PickupFilter";
import MapSlider from "../../../components/MapSlider";
import SaveNotify from "./SaveNotify";


let markerUrl = "https://maps.google.com/mapfiles/ms/icons/";

const mapProps = {
  center: {
    lat: 53.2649283,
    lng: 6.3197641,
  },
  zoom: 10,
};
let markers = [];
let otherRoutes = [];
let gMap = null;
let currMax = 0;
let currMin = 0;

const saveRouteOrder = (dropOffId) => {
  httpClient(`routeEditors/${dropOffId}`, {
    method: "PUT",
    body: JSON.stringify(orders()),
  }).then(() => {
    console.log(orders());
  });
};

const updateMarkers = ([min, max]) => {
  try {
    while (max > currMax) {
      let marker = markers[currMax++];
      // marker.setIcon({ url: markerBlue });
      marker.setMap(gMap);
    }
    while (max < currMax) {
      let marker = markers[currMax--];
      if (marker) marker.setMap(null);
    }

    while (min < currMin) {
      let marker = markers[currMin--];
      marker.setIcon({ url: markerUrl + "blue-dot.png" });
      marker.setOptions({ opacity: 1 });
    }
    while (min > currMin) {
      let marker = markers[currMin++];
      if (marker) {
        marker.setIcon({ url: markerUrl + "red-dot.png" });
        marker.setOptions({ opacity: 0.5 });
      }
    }
    markers[currMin].setIcon({ url: markerUrl + "green-dot.png" });
    if (markers[currMax]) markers[currMax].setMap(gMap);
  } catch (e) {
    console.log(e);
  }
};
const getIdxById = (id, markers) => {
  for (var i = 0; i < markers.length; i++)
    if (markers[i].data.id === id) return i;
  return -1;
};
const orders = () => {
  return markers.map((e) => {
    return e.data;
  });
};
const handleMarkerClick = (setSliderValue, id, dropOffId) => {
  let idx = getIdxById(id, markers);

  if (idx === -1) {
    //not on current route
    idx = getIdxById(id, otherRoutes);
    let [toMove] = otherRoutes.splice(idx, 1);
    markers.push(toMove);
    idx = markers.length - 1;

    console.log(toMove.data);
  }

  if (currMin > idx)
    markers[currMin].setIcon({ url: markerUrl + "blue-dot.png" });
  else markers[currMin].setIcon({ url: markerUrl + "red-dot.png" });

  let m = markers.splice(idx, 1)[0];
  markers.splice(currMin, 0, m);

  idx = Math.min(currMin + (idx < currMin ? 0 : 1), currMax);
  if (idx === currMax) currMax = Math.min(markers.length - 1, currMax + 1);
  updateMarkers([idx, currMax]);
  setSliderValue([idx, currMax]);
  saveRouteOrder(dropOffId);
};
const loadData = ({ setSliderValue, map, rows, filterValues, ...props }) => {
  let google = window.google;
  gMap = map;
  currMax = 0;
  while (markers.length) markers.pop().setMap(null);

  for (var idx = 0; idx < rows.length; idx++) {
    let currStop = rows[idx];

    currMax++;
    let lat = parseFloat(currStop.lat);
    let lng = parseFloat(currStop.lng);
    let isActiveRoute = currStop.dropOffId === filterValues.dropOffId;
    let marker = new google.maps.Marker({
      position: { lat, lng },
      map: map,
      data: {
        ...currStop,
        orgRouteId: currStop.routeId,
        routeId: filterValues.routeId,
      },

      icon: {
        url:
          markerUrl +
          (isActiveRoute
            ? "blue-dot.png"
            : currStop.routeId
              ? "orange-dot.png"
              : "purple-dot.png"),
      },
    });
    // marker.dataId = currStop.id;
    marker.addListener("click", () => {
      handleMarkerClick(setSliderValue, marker.data.id, filterValues.dropOffId);
    });
    if (isActiveRoute) markers.push(marker);
    else otherRoutes.push(marker);
  }
  return markers
};

let SimpleMap = ({ loading, data, ids, filterValues, ...props }) => {
  let [sliderValue, setSliderValue] = useState([0, 0]);

  let ref = useRef();
  let rows = [];
  let max = 0;
  for (var i = 0; i < ids.length; i++) {
    let row = data[ids[i]];
    row.weekId = filterValues.weekId;
    rows.push(row);
    max += row.routeId === filterValues.routeId ? 1 : 0;
  }

  useEffect(() => { setSliderValue([0, max]) }, [ids])
  if (max > 0 && sliderValue[1] === 0) setSliderValue([0, max]);
  if (loading) return "";
  let cMark = markers[sliderValue[0]];

  let title = cMark ? `${sliderValue[0]}-${cMark.data.lastName} -- ${cMark.data.address}` : ''

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>

      <div ref={ref} style={{
        width: '100%',
        height: '50px',
        padding: '0px 55px',
        boxSizing: 'border-box',
      }}><SaveNotify />
        <MapSlider
          max={max}
          min={0}
          title={title}
          defaultValue={sliderValue}
          value={sliderValue}
          onChange={(props, newValue) => {
            setSliderValue(newValue);
            updateMarkers(newValue);
          }}
        />

      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDKzVON9dMEWaJqWw8ARIa9wM2gU465btk",
          libraries: "visualization,geometry,places",
        }}
        data={data}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        // heatmapLibrary={true}
        yesIWantToUseGoogleMapApiInternals

        onGoogleApiLoaded={({ map, maps }) => {
          loadData({ ...props, map, maps, filterValues, rows, setSliderValue });
          map.controls[maps.ControlPosition.BOTTOM_CENTER].push(ref.current);
        }}
      // heatmap={heatMapData}
      ></GoogleMapReact>

    </div>
  );
};

export const RouteEditor = (props) => {
  return (
    <>
      <List
        pagination={<></>}
        {...props}
        filters={<PickupFilter weekInput routeMode />}
      >
        <SimpleMap />
      </List>
    </>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "routeEditors",
    list: guardListAccess(RouteEditor),
    icon,
    options: {
      label: "Edit route",
      group: "Logistic",
      showMenu: canRead("routeEditors"),
    },
  };
};
export default resource;
