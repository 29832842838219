import React from "react";

export const guardAccess = (
  Component,
  {
    modifier = 1,
    scope = "any",
    UnauthorizedComponent = () => <div>Unauthorized</div>,
  } = {}
) => (props) => {
  const { permissions, resource } = props;

  if (permissions === undefined) {
    return null;
  }
  const authorized = permissions && permissions.can(resource, modifier, scope);

  if (authorized) {
    return <Component {...props} permissions={permissions} />;
  } else {
    return <UnauthorizedComponent {...props} />;
  }
};
export const guardListAccess = (Component, props) =>
  guardAccess(Component, {
    ...props,
    modifier: 1,
  });

export const guardShowAccess = guardListAccess;

export const guardCreateAccess = (Component, props) =>
  guardAccess(Component, {
    ...props,
    modifier: 2,
  });

export const guardEditAccess = (Component, props) =>
  guardAccess(Component, {
    ...props,
    modifier: 4,
  });
