import React, { useState, useEffect } from "react";
import httpClient from "../../../util/HttpClient";
import Repeat from "@material-ui/icons/Repeat";
import FullNameField from "../Account/FullNameField";
import FancyTime from "../../../components/FancyTime";
import ExportLink from "../../../components/ExportLink";
import SubscriptionActions from "./SubscriptionActions"
import AddOrderBtn from "../../../components/AddOrderBtn";

import { IconButton, } from "@material-ui/core";
import { DateInput } from "react-admin";

import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  TextField,
  useUpdate,
  Create,
  ReferenceField,
  Filter,
  FilterLiveSearch
} from "react-admin";

import { guardListAccess } from "../../../util/Guard";


const Filters = (props) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch label="User" alwaysOn={true} />
    </Filter>
  );
};

const ProlongateButton = ({ onClick, record, ...props }) => {
  return (<IconButton aria-label="delete" onClick={(e) => {
    onClick(record.id)
    e.preventDefault();
    e.stopPropagation();
  }} size="small">
    <Repeat fontSize="inherit" />
  </IconButton>)
}


const CreateForm = (props) => {
  let [defaults, setDefaults] = useState();
  useEffect(() => {
    if (!defaults)
      httpClient(`rides/defaults/get`).then(({ json }) => setDefaults(json));
  }, [defaults]);
  return defaults ? (
    <Create {...props}>
      <Form {...{ defaults }} />
    </Create>
  ) : null;
};

const MyGrid = (props) => {

  const [update, { loading, error }] = useUpdate("subscriptions");

  let doProlongate = (id) => {
    update("subscriptions", id, { doProlongate: true })
  }
  let createOrder = (id) => {
    update("subscriptions", id, { createOrder: true })
  }


  return (
    <Datagrid rowClick="edit" {...props}>
      <ReferenceField
        label="Account"
        source="Order.accountId"
        reference="accounts"
      >
        <FullNameField />
      </ReferenceField>

      <ReferenceField label="Product" source="variantId" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="quantity" />
      <FancyTime showTime={false} source="nextDate" />
      <ProlongateButton label="Prolongeer" onClick={doProlongate} />
      <ExportLink source="futureOrder" {...{ createOrder }} />
      <AddOrderBtn source="add" {...{ createOrder }} />
    </Datagrid>
  );
};

const Form = ({ defaults, ...props }) => {
  return (
    <SimpleForm {...props}>

      <DateInput label="date" source="nextDate" />
    </SimpleForm>
  );
};
const EditForm = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

const SubscriptionList = ({ permissions: { canRead }, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<Filters />}
      actions={<SubscriptionActions />}
    >
      <MyGrid />
    </List>
  );
};

let Subscription = ({ canRead, ...props }) => {
  return {
    name: "subscriptions",
    list: guardListAccess(SubscriptionList),
    create: CreateForm,
    edit: EditForm,
    icon: Repeat,
    options: {
      showMenu: canRead("subscriptions"),
      label: "Subscriptions",
    },
    ...props,
  };
};

export default Subscription;