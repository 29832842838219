import React from "react";
import icon from "@material-ui/icons/Store";
import { daysRenderer } from "../../components/DaySelector";

import AutoRefInput from "../../components/AutoRefInput";
import {
  guardListAccess,
  guardCreateAccess,
  guardEditAccess,
} from "../../util/Guard";
import AddressInput from "../Sales/Address/AddressSelector";
import {
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  List,
  NumberField,
  Create,
  BooleanInput,
  Filter,
  FilterLiveSearch,
  NumberInput,
} from "react-admin";

//custom textField that doesnst render the word "null"
const TextField = ({ record, source }) => {
  return <>{record[source]}</>;
};

const DropOffForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <NumberInput source="externalId" />

      <AddressInput
        source="addressId"
        filter={{ accountId: props.record.id }}
      />
      <BooleanInput label="delivery" source="isDelivery" />
      <BooleanInput label="enabled" source="enabled" />
      <AutoRefInput
        reference="programs"
        optionText={(props) => { return props ? `${daysRenderer(props)} - ${props.misc}` : '' }}
        source="programId"
      />
    </SimpleForm>
  );
};

export const DropOffEdit = ({
  permissions: { authenticated, is },
  ...props
}) => {
  return (
    <Edit {...props}>
      <DropOffForm {...props} />
    </Edit>
  );
};

export const DropOffCreate = (props) => (
  <Create {...props}>
    <DropOffForm {...props} />
  </Create>
);

const Filters = (props) => {
  return (
    <Filter {...props}>
      <FilterLiveSearch label="Name" alwaysOn={true} />
    </Filter>
  );
};

export const DropOffList = ({
  permissions: { authenticated, is },
  ...props
}) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<Filters />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <NumberField source="externalId" />

      </Datagrid>
    </List>
  );
};

const def = ({ canUpdate, ...props }) => {
  return {
    name: "dropOffs",
    list: guardListAccess(DropOffList),
    edit: guardEditAccess(DropOffEdit),
    create: guardCreateAccess(DropOffCreate),
    icon,
    options: {
      group: "Point of sale",
      label: "Drop off",
      showMenu: canUpdate("dropOffs"),
    },
    ...props,
  };
};
export default def;