import React, { useCallback } from 'react';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import {
  linkToRecord,
  TextField,
  useListContext,
  FileInput,
  ImageField,
  Create,
  SimpleForm,
  useRefresh,
  useDelete
} from 'react-admin';
import { IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import { Link } from 'react-router-dom';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(theme => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
  deleteButton: {
    position: "absolute",
    left: 0
  }
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 3;
  if (width === 'lg') return 5;
  return 6;
};

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = (props) => {
  const { width, nbItems = 20 } = props;
  const classes = useStyles();
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const TileContent = ({ id, data, basePath, resource, classes, selectable = true, onToggleItem = () => { }, ...props }) => {

  const [deleteOne, { loading, error }] = useDelete();
  const refresh = useRefresh()
  return <GridListTile
    component={Link}
    key={id}
    {...props}
    to={linkToRecord(basePath, data[id].id)}
  > <img src={`https://${data[id].cdn}/${data[id].slug}`} alt={data[id].name} />
    <IconButton variant="outlined" color="secondary"
      className={classes.deleteButton}
      onClick={(e) => {

        deleteOne('media', id, { id }, { onSuccess: refresh })
        e.preventDefault();
        e.stopPropagation();
      }}><DeleteIcon /></IconButton>
    <GridListTileBar
      className={classes.tileBar}
      title={data[id].reference}
      subtitle={
        <span>

          <TextField
            className={classes.price}
            source="name"
            record={data[id]}
            color="inherit"
          />
        </span>
      }
    /></GridListTile >
}

const LoadedGridList = ({ parentId, ...props }) => {
  const { width } = props;
  const { ids, data, basePath, resource, ...rest } = useListContext();
  const classes = useStyles();
  if (!ids || !data) return null;
  return (<div>
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids.map((id) => <TileContent {...{ id, data, basePath, resource, classes, }} />

      )}


    </MuiGridList>
    <Create {...{ ...props, basePath, resource }}>
      <SimpleForm initialValues={{ parentId }} redirect="/products/005b0585-f526-4233-b8d0-2e06ae16bed5">
        <FileInput
          source="files"
          label="Related files"
          onChange={console.log}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create></div >

  );
};

const GridList = (props) => {
  const { width } = props;
  const { loaded } = useListContext();

  return loaded ? (
    <LoadedGridList width={width} {...props} />
  ) : (
      <LoadingGridList width={width} />
    );
};

export default withWidth()(GridList);