import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SenderName from "./SenderName";
import SenderAvatar from "./SenderAvatar";

import { Card, CardContent, CardHeader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grow: { flex: 1 },
  paper: {
    backgroundColor: "#fafafa",
  },
  message: {
    borderBottom: "2px solid #ebedf5",
    marginBottom: "2em",
    borderRadius: 10,
    "& .MuiCardHeader-root": {
      borderBottom: "2px solid #ebedf5",
    },
    "& .MuiCardHeader-title": {
      display: "flex",
    },
  },
}));
let PhoneGrid = ({ data, ids }) => {
  let classes = useStyles();

  return (
    <>
      {ids.map((id) => {
        let message = data[id];
        return (
          <Card className={classes.message} key={id}>
            <CardHeader
              title={
                <>
                  <SenderAvatar {...message} />
                  {message.subject || <SenderName {...message} />}
                </>
              }
            ></CardHeader>
            <CardContent
              dangerouslySetInnerHTML={{ __html: message.text }}
            ></CardContent>
          </Card>
        );
      })}
    </>
  );
};
export default PhoneGrid;
