import React from "react";

import { Box, Card, CardContent, Typography, InputAdornment } from "@material-ui/core";
import {
  Edit,

  TextInput,
  FormWithRedirect,
  Toolbar,
  BooleanInput,
  NumberInput,
  SelectInput,
  DateInput,

  SaveButton,
} from "react-admin";

import { Title, usePermissions } from "react-admin";

export const UserEditForm = (props) => {
  const { permissions } = usePermissions();
  let godMode = permissions && permissions.is('hr-manager');

  return (

    <FormWithRedirect
      {...props}

      render={(formProps) => (
        < Card >
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    Gegevens
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="firstName"
                        label="Voornaam"
                        // validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="middleName"
                        label="tussenvoegsel"
                        fullWidth
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="lastName"
                        label="Achternaam"
                        // validate={requiredValidate}
                        fullWidth
                      />
                    </Box>

                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="initials"
                        label="initialen"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <TextInput
                    type="email"
                    source="email"
                    // validate={[email(), required()]}
                    fullWidth
                  />

                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput fullWidth source="address" label="Straat en huisnr" />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput fullWidth source="zipcode" label="Postcode" />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput fullWidth source="city" label="Woonplaats" />
                    </Box>
                  </Box>
                  {godMode && (<>
                    <Typography variant="h6" gutterBottom>
                      Contract
                  </Typography>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <DateInput fullWidth source="employDateStart" label="Startdatum" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <DateInput fullWidth source="employDateEnd" label="Einddatum" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <SelectInput source="contractType" fullWidth label="Contract" choices={[
                          { id: 'bepaalde', name: 'Bepaalde tijd' },
                          { id: 'onbepaald', name: 'Onbepaalde tijd' },
                          { id: 'uitdienst', name: 'Uit dienst' },
                          { id: 'flex', name: 'Flexibel uren' }
                        ]} />
                      </Box>
                    </Box>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <NumberInput fullWidth source="salary"

                          InputProps={{

                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          label="Salaris" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <NumberInput fullWidth source="hoursPerWeek" label="Uur per week" />
                      </Box>

                    </Box>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <NumberInput fullWidth source="salaryId" label="SalaryId" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <NumberInput fullWidth source="employeeId" label="Personeelsnummer" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <BooleanInput source="milagePrice" label="Reiskostenvergoeding" />
                      </Box>
                    </Box>
                    <Box display={{ xs: "block", sm: "flex" }}>

                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <BooleanInput source="newUser" label="Moet aanmeldformulier invullen" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <BooleanInput source="allowOvertime" label="Betaal uren als overuren" />
                      </Box>

                    </Box>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <BooleanInput source="resetMail" label="Stuur inlogmail" />
                      </Box>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <BooleanInput source="fillNewEmployeeForm" label="Vul nieuwe medewerker formulier" />
                      </Box>
                    </Box>

                  </>)}

                </Box>

                <Box
                  flex={1}
                  ml={{ xs: 0, lg: "1em" }}
                  mt={{ xs: "1em", lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Nummers
                  </Typography>
                  <div>
                    <SelectInput source="gender" fullWidth label="Geslacht" choices={[
                      { id: 'm', name: 'Man' },
                      { id: 'v', name: 'Vrouw' }
                    ]} />

                    <br />
                    <TextInput fullWidth source="phone" label="Mobiel" />

                    <br />
                    <TextInput fullWidth source="bankNo" label="IBAN bankrekening" />
                    <br />
                    <TextInput fullWidth source="country" label="Nationaliteit" defaultValue="Nederlandse" />
                    <br />
                    <SelectInput fullWidth source="lifeState" defaultValue="n" label="Burgelijke staat" choices={[
                      { id: 'm', name: 'gehuwd' },
                      { id: 'r', name: 'geregistreerd partnerschap' },
                      { id: 'n', name: 'ongehuwd' },
                      { id: 'w', name: 'verweduwd' },
                      { id: 'd', name: 'gescheiden' }
                    ]} />
                    <br />
                    <TextInput fullWidth source="bsn" label="BSN nummer" />

                    <div>Voor de taart en je contract:</div>
                    <DateInput fullWidth source="birthday" label="Geboortedatum" />


                  </div>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="users"
            ><SaveButton handleSubmitWithRedirect={formProps.handleSubmit} /></Toolbar>
          </form>
        </Card >

      )} />
  );
};



const Configuration = () => {
  let { permissions } = usePermissions();

  return (

    <Card>
      <Title title={"Welkom"} />
      <CardContent>
        {permissions && (<Edit basePath="/users" resource="users" id={permissions.user.id} >
          < UserEditForm redirect={"/"} id={permissions.user.id} />
        </Edit>)}
      </CardContent>

    </Card>

  );
};

export default Configuration;
