import React, { useState, useEffect } from "react";
import {
  useCreate,
  useNotify,
  useDelete,
  useGetList,
  useDataProvider,
  useRefresh
} from "react-admin";
import CopyAll from "@material-ui/icons/FileCopy";
import StopMap from "./StopMap";
import httpClient from "../../../util/HttpClient";
import RouteDetail from "./RouteDetail"
import StopList from "./StopList";
import {
  useMediaQuery,
} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column", alignItems: 'center', rowGap: 10 },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em", maxWidth: 400 },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

export const RouteEditor = ({ record }) => {
  const { data, ...listProps } = useGetList(
    'stops',
    { page: 1, perPage: 100 }, {}, { routeId: record.id }
  );

  return <StopListWithMap {...{ record, data, ...listProps, }} />
}
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  result.forEach((item, idx) => {
    item.index = idx;
  });
  return result;
};



export const StopListWithMap = ({ data, ids, record, loading, ...props }) => {
  let [selected, setSelected] = useState(0)
  let [items, setItems] = useState([]);
  let [reorderMode, setReorderMode] = useState(false);
  let refresh = useRefresh();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [create] = useCreate("stops");
  const [deleteStop] = useDelete();
  const notify = useNotify();

  useEffect(() => {
    setItems(ids.map(e => data[e]))
  }, [loading])

  let dp = useDataProvider();
  const changeOrder = (from, to, moveNext = 0) => {
    const newItems = reorder(
      items,
      from,
      to
    );
    setSelected(Math.min(items.length - 1, to + moveNext));
    setItems([...newItems]);
    httpClient(`stops/newOrder`, {
      method: "PUT",
      body: JSON.stringify(newItems.map((item) => item.id)),
    })
  }

  const handleAdd = async (clone = false) => {
    create(
      {
        payload: {
          data: {
            addressId: '1ecec84c-cbb3-4955-ae41-ac03a21cdfeb',
            accountId: '61793bb9-b686-4011-a835-2270465de7f3',
            routeId: record.id,
            clone
          }
        }
      },
      {
        onSuccess: async ({ data }) => {
          if (clone) refresh(); //to much has changed
          else {
            let newItem = await dp.getOne('stops', { id: data.id })
            items.push({ ...newItem.data, showSelector: true });
            changeOrder(items.length - 1, selected + 1)//
          }
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  let onDelete = async () => {

    await deleteStop('stops', items[selected].id);
    let stop = items.splice(selected, 1)[0]
    if (stop && stop.marker)
      stop.marker.setMap(null)
    setItems([...items])
    if (selected > items.length - 1)
      setSelected(items.length - 1)
  }


  return (
    <div style={{ padding: 5 }}>
      {isSmall ? ( //MOBILE!
        <div style={styles.flexColumn}>
          <StopList {...{ items, selected, setSelected, changeOrder, onDelete }} />
          <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
            <Fab onClick={handleAdd} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      ) : (
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <StopMap {...{ items, selected, setSelected, reorderMode, changeOrder }} />
            </div>
            <div style={styles.rightCol}>
              <RouteDetail {...{ reorderMode, setReorderMode, record }} />
              <StopList {...{ items, selected, setSelected, onDelete, reorderMode, changeOrder }} />
              <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                {items.length || !record.parentId ?
                  <Fab onClick={() => { handleAdd(false) }} color="primary" aria-label="add">
                    <AddIcon />
                  </Fab> : <div style={styles.flexColumn}>
                    <div style={{ display: 'block' }}> This route has no stops, click to clone from template</div>
                    <Fab onClick={() => { handleAdd(true) }} color="secondary" aria-label="clone">
                      <CopyAll />
                    </Fab> </div>}
              </div>
            </div>

          </div>
        )
      }</div>
  )
}